import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { testResponse } from "../../app/util";
import Table from "../../component/Table";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import {
  getRoles,
  selectPermissionState,
  getPermissions,
  downloadPermissions,
  uploadPermissions,
} from "./permissionSlice";
import { SearchCondition } from "./permissionValues";
import { Container, Row, Col, Button, Alert, Form, Accordion } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function PermissionList() {
  const dispatch = useAppDispatch();
  const { roles, permissions, permissionHasMore, permissionTotalCount, fetchedPermissionPage } =
    useAppSelector(selectPermissionState);
  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);
  useEffect(() => {
    if (roles.length > 0) dispatch(getPermissions({ conditions: {}, page: 1 }));
  }, [roles]);
  useEffect(() => {
    $state({ ...state, isUploading: false });
  }, [permissions]);
  const [state, $state] = useState({
    isUploading: false,
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
    searchRole: false,
    checkRoles: [] as string[],
  });
  useEffect(() => {
    updatePermission({});
  }, [state.searchKeyword, state.checkRoles]);
  const download = async () => {
    await dispatch(downloadPermissions());
  };
  const onFileLoad = async (decodedFileData: DecodedFileData) => {
    $state({ ...state, isUploading: true });
    testResponse(
      await dispatch(
        uploadPermissions({
          file: decodedFileData.dataURI,
          name: decodedFileData.name,
        })
      )
    );
  };

  const updatePermission = async ({ page = 1 }: { page?: number }) => {
    const conditions = {} as SearchCondition;
    if (state.searchKeyword) {
      conditions["keyword"] = state.searchKeyword;
    }
    if (state.checkRoles && state.checkRoles.length > 0) {
      conditions["checkRoles"] = state.checkRoles;
    }
    dispatch(getPermissions({ conditions, page: page }));
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row className="mb-2">
            <Col>
              <div className="--bold pt-mb-2">権限を追加</div>
              <div className="my-2">
                {state.isUploading ? (
                  <Alert variant={"info"}>リクエストを受け付けました。処理完了までしばらくお待ちください。</Alert>
                ) : (
                  <Uploader
                    onFileLoad={onFileLoad}
                    accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md="2">
              <div className="--bold pt-md-3">絞込条件</div>
            </Col>
            <Col md="10">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {state.searchKeyword || state.checkRoles.length > 0 ? "（選択中）" : "（未選択）"}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form.Control
                      type="text"
                      id="search"
                      value={state.inputKeyword}
                      className="mb-2"
                      placeholder="絞り込みキーワードを入力"
                      onChange={(e) => {
                        const keyword = e.target.value;
                        // 打ち終わって500ms後に検索のリクエストをする
                        if (state.timeoutId) {
                          window.clearTimeout(state.timeoutId);
                        }
                        const timeoutId = window.setTimeout(() => {
                          $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                        }, 500);
                        $state({ ...state, timeoutId, inputKeyword: keyword });
                      }}
                    />
                    <div className="--bold mb-2">ロールで絞り込み</div>
                    {roles.map((r, i) => (
                      <Form.Check
                        inline
                        key={`role-check-${i}`}
                        id={`role-check-${i}`}
                        checked={state.checkRoles?.includes(`,${r.id},`) === true}
                        className="mx-2"
                        type="checkbox"
                        label={r.label}
                        onChange={() => {
                          let next = state.checkRoles;
                          if (next.includes(`,${r.id},`)) {
                            next = next.filter((c) => c !== `,${r.id},`);
                          } else {
                            next = [...state.checkRoles, `,${r.id},`];
                          }
                          $state({ ...state, checkRoles: next });
                        }}
                      />
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className="my-2">
            <Col>
              <Button className="mx-2" variant="outline-primary" onClick={download}>
                ダウンロード
              </Button>
              <Button
                className="mx-2"
                variant="outline-primary"
                onClick={() => {
                  if (state.timeoutId) {
                    window.clearTimeout(state.timeoutId);
                  }
                  $state({
                    ...state,
                    timeoutId: null,
                    inputKeyword: "",
                    searchKeyword: "",
                    checkRoles: [],
                  });
                }}
              >
                絞込条件をクリア
              </Button>
            </Col>
          </Row>
          {permissions.length > 0 ? (
            <Table
              col={[
                {
                  name: "ログインID",
                  filterable: true,
                },
                {
                  name: "氏名",
                  filterable: true,
                },
                {
                  name: "ロール",
                  width: "60%",
                },
              ]}
              row={permissions.map((p) => {
                const labels =
                  p.roles !== null
                    ? roles.filter((r: any) => p.roles.indexOf(`,${r.id},`) !== -1).map((r) => `${r.label} (${r.id})`)
                    : [];
                return {
                  key: p.account_id,
                  data: [p.login_code, p.name, labels.length ? labels.join(", ") : "--"],
                  // link: `/_/permission/account/${p.account_id}/`, 詳細画面実装までコメントアウト
                };
              })}
              usePagenation={false}
              useKeywordFilter={false}
            />
          ) : (
            <Alert variant={"info"}>該当するレコードはありません。</Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {permissions.length > 0 ? (
            <Button
              variant="outline-secondary"
              className="mt-2 float-end"
              disabled={!permissionHasMore}
              onClick={() => {
                updatePermission({ page: fetchedPermissionPage + 1 });
              }}
            >
              さらに表示（全 {permissionTotalCount} 件中 {permissions.length} 件表示中）
            </Button>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default PermissionList;
