import { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Button, Dropdown, Alert, Form, Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { selectUserState } from "../../features/login/userSlice";
import {
  getProfileListView,
  downloadProfileListView,
  selectProfileState,
  unselectMember,
  unselectEditingProfile,
  clearPreviewData,
} from "../../features/profile/profileSlice";
import {
  ProfileFieldStatus,
  fieldViewData,
  USER_TABLE_PREFIX,
  SearchItem,
  SearchCondition,
  tableCategoryMapping,
} from "../../features/profile/profileValues";
import { userColumnChoices } from "./profileFieldValues";
import FieldListSelector from "../../features/profile/FieldListSelector";
import MemberListSelector from "../../features/profile/MemberListSelector";
import SearchItemSelector from "../../features/profile/SearchItemSelector";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import {
  getSectors,
  getRegularColumns,
  selectClientState,
  selectActiveSectors,
} from "../../features/client/clientSlice";
import Table from "../../component/Table";
import dayjs from "dayjs";
import "react-calendar/dist/Calendar.css";
import { RegularColumn } from "../client/clientValues";
import {
  getMasterCodeChoices,
  selectMasterDataState,
  clearMasterCodeChoices,
} from "../../features/masterData/masterDataSlice";
import { MASTER_TABLE_PREFIX } from "../../features/masterData/masterDataValues";
import { isPermitted } from "../permission/permissionValues";

function ProfileList() {
  const dispatch = useAppDispatch();
  const { previewData, previewColumns, previewMetaData, previewTotalCount, previewHasMore, fetchedProfilePage } =
    useAppSelector(selectProfileState);
  const { policies } = useAppSelector(selectUserState);
  const { sectorRegularColumns } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const { masterCodeChoices } = useAppSelector(selectMasterDataState);
  const [state, $state] = useState({
    tableStyles: [
      {
        id: "united",
        label: "1つのシートに全員分表示する",
        selected: true,
      },
      {
        id: "separated",
        label: "対象者ごとに別のシートに表示する",
        selected: false,
      },
    ],
    enrollmentTypeOptions: userColumnChoices["profile_u_personal"]["enrollment_type"].map((e, i) => {
      return {
        id: `enrollment_type${i + 1}`,
        label: e,
        checked: ["在籍", "休職"].includes(e),
      };
    }),
    checkedMemberIds: [] as number[],
    excludedMemberKeyword: "",
    excludedMemberIds: [] as number[],
    selectedPointDate: dayjs(),
    fieldStatus: {} as ProfileFieldStatus,
    fieldViewSectors: [] as fieldViewData[],
    searchItems: [] as SearchItem[],
    checkedsectionCodes: [] as string[],
  });

  const onSelectedMemberChange = async (next1: number[], next2: number[], next3: string[]) => {
    $state({ ...state, checkedMemberIds: next1, excludedMemberIds: next2, checkedsectionCodes: next3 });
  };

  const onExcludedMemberKeywordChange = (next: string) => {
    $state({ ...state, excludedMemberKeyword: next });
  };

  const selectField = async (id: string) => {
    const sectorId = `${USER_TABLE_PREFIX}${id}`;
    const isSector = activeSectors.some((s) => s.sector_id === sectorId);
    let newSubFields: { id: string; label: string }[];
    if (isSector && !sectorRegularColumns[sectorId]) {
      const { payload } = await dispatch(getRegularColumns({ sectorId }));
      const { regularColumns } = payload as { [key: string]: any };
      newSubFields = regularColumns.map((col: RegularColumn) => {
        const { id, label } = col;
        return { id, label };
      });
    }
    const willSubFieldChecked = (
      subFieldId: string,
      subFieldCheckedCurrent: boolean,
      fieldCheckedNext: boolean,
      fieldCheckedCurrent: boolean
    ) => {
      if (fieldCheckedCurrent && !fieldCheckedNext) {
        return false;
      } else if (!fieldCheckedCurrent && fieldCheckedNext) {
        return true;
      } else {
        return subFieldId === id ? !subFieldCheckedCurrent : subFieldCheckedCurrent;
      }
    };
    const next = state.fieldViewSectors.map((field) => {
      const fieldCheckedNext = field.id === id ? !field.checked : field.checked;
      const subFields = field.id === id ? newSubFields || field.subFields : field.subFields;
      return {
        ...field,
        // まずは1つしか選べない
        // checked: fieldCheckedNext
        checked: isSector ? (field.id === id ? !field.checked : false) : fieldCheckedNext,
        subFields: subFields.map((sub: any) => ({
          ...sub,
          checked: willSubFieldChecked(sub.id, sub.checked, fieldCheckedNext, field.checked),
        })),
      };
    });
    const searchItems = getsearchItems(next);
    $state({ ...state, fieldViewSectors: next, searchItems });
  };

  const selectAllSubFields = (fieldId: string, isSelectAll: boolean) => {
    const next = state.fieldViewSectors.map((field) => {
      return {
        ...field,
        checked: field.checked,
        subFields: field.subFields.map((sub: any) => ({
          ...sub,
          checked: field.id === fieldId ? isSelectAll : sub.checked,
        })),
      };
    });
    const searchItems = getsearchItems(next);
    $state({ ...state, fieldViewSectors: next, searchItems });
  };

  const _getFieldIds = () => {
    const fieldIds = [] as string[];
    const exceptionSubFieldIds = [] as string[];
    state.fieldViewSectors.forEach((field) => {
      if (!field.checked) return;
      fieldIds.push(field.id);
      field.subFields.forEach((sub) => {
        if (!sub.checked) exceptionSubFieldIds.push(sub.id);
      });
    });
    return { fieldIds, exceptionSubFieldIds };
  };

  const _getConditions = () => {
    let conditions = {} as SearchCondition;
    state.searchItems.forEach((item) => {
      const choices = item.choices?.filter((choice) => choice.checked) || [];
      if (choices.length > 0) {
        const { column } = item;
        conditions = {
          ...conditions,
          [`${column}__in`]: choices.map((c) => c.value),
        };
      }
    });
    return conditions;
  };

  const updatePreview = async ({ page = 1 }: { page?: number }) => {
    const { fieldIds, exceptionSubFieldIds } = _getFieldIds();
    if (!fieldIds[0]) return;
    const conditions = _getConditions();
    const enrollmentTypeOptions = state.enrollmentTypeOptions.filter((o) => o.checked).map((o) => o.label);
    await dispatch(
      getProfileListView({
        memberIds: state.checkedMemberIds,
        excludedMemberIds: state.excludedMemberIds,
        table: fieldIds[0],
        exceptionSubFieldIds,
        baseDate: state.selectedPointDate,
        sectorRegularColumns,
        conditions,
        checkedsectionCodes: state.checkedsectionCodes,
        enrollmentTypeOptions,
        page,
      })
    );
  };
  const downloadProfileList = async () => {
    const { fieldIds } = _getFieldIds();
    const conditions = _getConditions();
    const enrollmentTypeOptions = state.enrollmentTypeOptions.filter((o) => o.checked).map((o) => o.label);
    await dispatch(
      downloadProfileListView({
        memberIds: state.checkedMemberIds,
        excludedMemberIds: state.excludedMemberIds,
        table: fieldIds[0],
        baseDate: state.selectedPointDate,
        conditions,
        enrollmentTypeOptions,
      })
    );
  };

  const onSearchItemChange = (columnName: string, selected: string[]) => {
    const searchItems = state.searchItems.map((search) => {
      const { column, choices } = search;
      const newChoices =
        columnName === column
          ? choices?.map((choice) => {
              const { value } = choice;
              const newCheck = selected.includes(value);
              return {
                value,
                checked: newCheck,
              };
            })
          : choices;
      return { ...search, choices: newChoices };
    });
    $state({
      ...state,
      searchItems,
    });
  };

  const getsearchItems = (fieldViewSectors: fieldViewData[]) => {
    let searchItems = [] as SearchItem[];
    fieldViewSectors.forEach((field) => {
      const { id, subFields, checked } = field;
      if (checked) {
        // type定義ファイルから選択肢を取得
        const sectorId = `${USER_TABLE_PREFIX}${id}`;
        const columnChoices = userColumnChoices[sectorId];
        const regularColumns = sectorRegularColumns[sectorId];
        for (const column in columnChoices) {
          const regularColumn = regularColumns?.find((col) => col.id === column);
          const label = subFields?.find((sub) => sub.id === column)?.label || column;
          const choices = (regularColumn?.options ?? columnChoices[column])?.map((value) => {
            return {
              value,
              checked: false,
            };
          });
          searchItems = [
            ...searchItems,
            {
              column,
              label,
              choices,
            },
          ];
        }

        // login_code 以外の xx_code がある場合は、masterデータから取得するため、まずxx_code を含む sector を取得
        const includedCodeIds: string[] = [];
        subFields.forEach((field) => {
          const sector = field.id.split("_code")[0];
          if (activeSectors.find((s) => s.sector_id === `${MASTER_TABLE_PREFIX}${sector}`)) {
            includedCodeIds.push(sector);
          }
        });
        // labelデータを作成  e.g.) {position: { code: "役職コード", name: "役職名" }, section: { code: "部署コード", name: "部署名" }}
        if (includedCodeIds.length > 0) {
          const labelData = includedCodeIds.reduce((prev, id) => {
            const codeLabel = subFields.filter((sub) => sub.id.indexOf(`${id}_code`) !== -1)[0].label;
            const nameLabel = subFields.filter((sub) => sub.id.indexOf(`${id}_name`) !== -1)[0].label;
            return {
              ...prev,
              [id]: {
                code: codeLabel,
                name: nameLabel,
              },
            };
          }, {} as { [key: string]: { code: string; name: string } });
          // 基準日に即したmasterデータのxxコード、xx名の選択肢を取得
          dispatch(
            getMasterCodeChoices({
              baseDate: state.selectedPointDate,
              labelData,
            })
          );
        }
      }
    });
    return searchItems;
  };
  useEffect(() => {
    if (activeSectors.length === 0) {
      dispatch(getSectors());
    }
    // クリーンアップ
    return () => {
      dispatch(unselectMember());
      dispatch(unselectEditingProfile());
      dispatch(clearMasterCodeChoices());
      dispatch(clearPreviewData());
    };
  }, [dispatch]);

  // 表示項目を設定
  useEffect(() => {
    if (activeSectors.length > 0 && state.fieldViewSectors.length === 0) {
      // 表示項目データ作成
      let fieldViewSectors = [] as fieldViewData[];
      const userDataSectors = activeSectors
        .filter((s) => s.sector_id.startsWith(USER_TABLE_PREFIX))
        .filter((_) => isPermitted(policies, _.sector_id.replace(USER_TABLE_PREFIX, "user_data_manager/"), "GET"));
      for (const i in userDataSectors) {
        const sector = userDataSectors[i];
        const sectorId = sector.sector_id;
        const regularColumns = sectorRegularColumns[sectorId];
        const subFields = regularColumns
          ? regularColumns.map((column: RegularColumn) => {
              const { id, label } = column;
              return {
                id,
                label,
                checked: false,
              };
            })
          : [];
        const field = {
          id: sectorId.replace(USER_TABLE_PREFIX, ""),
          label: sector.logical_name,
          checked: false,
          subFields,
        };
        fieldViewSectors = [...fieldViewSectors, field];
      }
      $state({ ...state, fieldViewSectors });
    }
  }, [activeSectors, state.fieldViewSectors]);

  // 絞込条件の選択肢に xx_code を含む場合、取得したmasterデータを searchItemsと同じデータ構造に整形してから state.searchItems を更新する
  useEffect(() => {
    let searchItems = [] as SearchItem[];
    const types = ["code", "name"];
    if (masterCodeChoices.length > 0) {
      masterCodeChoices.forEach((codeData) => {
        types.forEach((type) => {
          const column = `${codeData.id}_${type}`;
          const label = codeData.label ? codeData.label[type] : column;
          const choices = type === "code" ? Object.keys(codeData.codesNames) : Object.values(codeData.codesNames);
          const newChoices = choices.map((key: string) => {
            return {
              value: key,
              checked: false,
            };
          });
          searchItems = [
            ...searchItems,
            {
              column,
              label,
              choices: newChoices,
            },
          ];
          return searchItems;
        });
      });
      $state({
        ...state,
        searchItems,
      });
    }
  }, [masterCodeChoices]);

  // 基準日が変更されたら絞り込み条件を自動更新する
  useEffect(() => {
    if (state.fieldViewSectors.length > 0) {
      getsearchItems(state.fieldViewSectors);
    }
  }, [state.selectedPointDate]);

  const selectedFieldId = useMemo(() => {
    const checked = state.fieldViewSectors.find((_) => _.checked);
    return checked ? checked.id : "";
  }, [state.fieldViewSectors]);

  // ユニークキー取得
  const uniqueKeys = useMemo(() => {
    if (selectedFieldId) {
      return sectorRegularColumns[`${USER_TABLE_PREFIX}${selectedFieldId}`]
        ?.filter((_) => _.isKey === true && _.id !== "valid_from" && _.id !== "login_code")
        ?.map((_) => _.id);
    }
  }, [selectedFieldId]);

  // 横スクロール時の固定カラム
  const stickyColumns = useMemo(() => {
    if (selectedFieldId) {
      return sectorRegularColumns[`${USER_TABLE_PREFIX}${selectedFieldId}`]
        ?.filter((_) => ["valid_from", "valid_to", "login_code", "name"].includes(_.id))
        ?.map((_) => _.id);
    } else {
      return [];
    }
  }, [selectedFieldId]);

  // 表示条件変更でプレビューを自動更新
  useEffect(() => {
    if (state.fieldViewSectors.length > 0) {
      updatePreview({ page: 1 });
    }
  }, [
    state.fieldViewSectors,
    state.selectedPointDate,
    state.searchItems,
    state.checkedMemberIds,
    state.excludedMemberIds,
    state.enrollmentTypeOptions,
  ]);

  const validFromIndex = useMemo(() => {
    if (previewColumns && previewColumns.length > 0) {
      return previewColumns?.findIndex((_) => _ === "valid_from");
    }
  }, [previewColumns]);

  const searchItemsCheckedFields = state.searchItems.filter((search) =>
    search.choices.some((choice) => choice.checked)
  );

  const isEditPermitted = useMemo(() => {
    const sector = state.fieldViewSectors.find((_) => _.checked)?.id;
    if (!sector) return false;
    return isPermitted(policies, `user_data_manager/${sector}`, "PUT");
  }, [policies, state.fieldViewSectors]);

  useEffect(() => {
    // 編集権限がない、かつ基準日が未来の場合は基準日を本日へ変更する
    if (!isEditPermitted && state.selectedPointDate.isAfter(dayjs())) $state({ ...state, selectedPointDate: dayjs() });
  }, [isEditPermitted]);

  return (
    <Container className="pt-4">
      <Row>
        <Col md="2">
          <div className="--bold pt-md-3">表示テーブル</div>
        </Col>
        <Col md="10">
          <FieldListSelector
            fields={state.fieldViewSectors}
            onFieldSelected={selectField}
            onSelectAllSubFields={selectAllSubFields}
            useSelectAllSubFields={true}
          />
        </Col>
      </Row>
      {selectedFieldId.indexOf(`_summary`) !== -1 ? (
        ""
      ) : (
        <Row className="mt-2">
          <Col md="2">
            <div className="--bold pt-md-3">基準日</div>
          </Col>
          <Col md="10">
            <SnapshotTimeSelector
              selectedPointDate={state.selectedPointDate}
              onChange={({ selectedPointDate }: onChangeOption) => {
                if (!selectedPointDate) return;
                $state({
                  ...state,
                  selectedPointDate,
                });
              }}
              useFarthestDaySelector={isEditPermitted}
              maxDate={isEditPermitted ? undefined : dayjs().toDate()}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md="2">
          <div className="--bold pt-md-3">絞込条件</div>
        </Col>
        <Col md="10">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {searchItemsCheckedFields.length > 0 ||
                state.checkedsectionCodes.length > 0 ||
                state.enrollmentTypeOptions.some((o) => o.checked)
                  ? (() => {
                      let checkedFieldLabels = [] as string[];
                      if (searchItemsCheckedFields.length > 0) {
                        checkedFieldLabels = searchItemsCheckedFields.map((_) => _.label);
                      }
                      if (state.checkedsectionCodes.length > 0) {
                        checkedFieldLabels = [...checkedFieldLabels, "対象者"];
                      }
                      if (state.enrollmentTypeOptions.some((o) => o.checked)) {
                        checkedFieldLabels = [...checkedFieldLabels, "在籍区分"];
                      }
                      return checkedFieldLabels.join(", ");
                    })()
                  : "（未選択）"}
              </Accordion.Header>
              <Accordion.Body>
                <SearchItemSelector searchItems={state.searchItems} onChange={onSearchItemChange} />
                <Row>
                  <Col md="4">
                    <MemberListSelector
                      excludedMemberKeyword={state.excludedMemberKeyword}
                      excludedMemberIds={state.excludedMemberIds}
                      checkedsectionCodes={state.checkedsectionCodes}
                      onExcludedMemberKeywordChange={onExcludedMemberKeywordChange}
                      onSelectedMemberChange={onSelectedMemberChange}
                    />
                  </Col>
                  <Col md="4" className="mb-2 --align-items-center">
                    <div className="--bold mb-2">在籍区分</div>
                    {state.enrollmentTypeOptions.map((option) => {
                      return (
                        <Form.Check
                          type="checkbox"
                          label={option.label}
                          key={`option_h_${option.id}`}
                          id={`option_h_${option.id}`}
                          checked={option.checked}
                          value={option.id}
                          onChange={() => {
                            $state({
                              ...state,
                              enrollmentTypeOptions: state.enrollmentTypeOptions.map((o) => {
                                if (o.id !== option.id) return o;
                                return { ...o, checked: !o.checked };
                              }),
                            });
                          }}
                        />
                      );
                    })}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      {/* SHRPA-6972　表示形式のオプションはバックエンドが未実装のため非表示 */}
      {/* <Row className="mt-2">
        <Col md="2">
          <div className="--bold pt-md-2">表示形式</div>
        </Col>
        <Col md="10">
          <Dropdown>
            <Dropdown.Toggle variant={"outline-primary"}>
              {state.tableStyles.find((p) => p.selected)?.label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {state.tableStyles.map((o, ii) => (
                <Dropdown.Item
                  key={`operation-${ii}`}
                  onClick={() =>
                    $state({
                      ...state,
                      tableStyles: state.tableStyles.map((style) => {
                        style.selected = style.id === o.id;
                        return style;
                      }),
                    })
                  }
                >
                  {o.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row> */}
      <Row className="mt-4">
        <Col>
          <Button
            className="mx-2"
            variant="outline-secondary"
            disabled={previewData.length === 0}
            onClick={downloadProfileList}
          >
            ダウンロード
          </Button>
          <Button
            className="mx-2"
            variant="outline-secondary"
            onClick={() => {
              $state({
                ...state,
                checkedMemberIds: [],
                excludedMemberKeyword: "",
                excludedMemberIds: [],
                searchItems: state.searchItems.map((search) => {
                  return {
                    ...search,
                    choices: search.choices.map((choice) => {
                      return {
                        ...choice,
                        checked: false,
                      };
                    }),
                  };
                }),
                checkedsectionCodes: [],
                enrollmentTypeOptions: state.enrollmentTypeOptions.map((option) => {
                  return {
                    ...option,
                    checked: false,
                  };
                }),
              });
            }}
          >
            絞込条件をクリア
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          {previewData.length > 0 ? (
            <Table
              col={previewData[0]?.map((col, i) => {
                return {
                  name: col,
                  className: classNames({ "--sticky": i < stickyColumns.length }),
                  left: 120 + (previewData[0].length > 6 ? 0 : 6 - previewData[0].length) * 50,
                  width: 120 + (previewData[0].length > 6 ? 0 : 6 - previewData[0].length) * 50,
                };
              })}
              row={previewData
                .filter((_, i) => i > 0)
                .map((row, i) => {
                  const baseDate =
                    validFromIndex !== undefined && validFromIndex !== -1
                      ? row[validFromIndex]
                      : state.selectedPointDate.format("YYYY-MM-DD");
                  const fieldType = selectedFieldId.indexOf(`_summary`) === -1 ? "detail" : "history";
                  let urlQuery = "";
                  if (fieldType === "detail") {
                    uniqueKeys?.forEach((key) => {
                      const index = previewColumns?.findIndex((c) => c === key);
                      if (index !== undefined && index !== -1) {
                        urlQuery += `&${key}=${row[index]}`;
                      }
                    });
                  }
                  return {
                    data: row,
                    externalLink: `/_/profile/${previewMetaData[i].account_id}/?field=${tableCategoryMapping[selectedFieldId]}&base_date=${baseDate}&type=${fieldType}${urlQuery}`,
                  };
                })}
            />
          ) : (
            <Alert variant={"info"}>該当するレコードはありません。</Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {previewData.length > 1 ? (
            <Button
              variant="outline-secondary"
              className="mt-2 float-end"
              disabled={!previewHasMore}
              onClick={() => {
                updatePreview({ page: fetchedProfilePage + 1 });
              }}
            >
              さらに表示（全 {previewTotalCount} 件中 {previewData.length - 1} 件表示中）
            </Button>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default ProfileList;
