import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { getMails, copyMails, deleteMails, selectMailState } from "./mailSlice";
import { SearchCondition } from "./mailValues";
import { selectCurrentCompany } from "../login/userSlice";
import Table from "../../component/Table";
import { Alert, Row, Col, Button, Form } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import ModalDialog from "../../component/ModalDialog";

function App() {
  const dispatch = useAppDispatch();
  const { mails } = useAppSelector(selectMailState);
  const current_company = useAppSelector(selectCurrentCompany);
  const [state, $state] = useState({
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
    checkedIds: [] as string[],
    activeModal: "",
  });

  useEffect(() => {
    if (current_company.id) {
      dispatch(getMails({ conditions: {} }));
    }
  }, [current_company, dispatch]);

  /* 検索フォーム非表示のため一旦コメントアウト
  useEffect(() => {
    updateMails();
  }, [state.searchKeyword]);
  */

  const updateMails = async () => {
    const conditions = {} as SearchCondition;
    if (state.searchKeyword) {
      conditions["keyword"] = state.searchKeyword;
    }
    dispatch(getMails({ conditions }));
  };

  const copy = async () => {
    if (!state.checkedIds) return $state({ ...state, activeModal: "" });
    await dispatch(copyMails({ ids: state.checkedIds }));
    $state({ ...state, activeModal: "" });
    updateMails();
  };

  const discard = async () => {
    if (!state.checkedIds) return $state({ ...state, activeModal: "" });
    await dispatch(deleteMails({ ids: state.checkedIds }));
    $state({ ...state, checkedIds: [], activeModal: "" });
    updateMails();
  };

  return (
    <>
      <Row>
        <Col>
          {/* バックエンド実装後対応予定(部分一致検索未対応)のため一旦非表示にする */}
          {/* <Row className="mb-2">
            <Col md="2">
              <div className="--bold pt-md-2">絞込条件</div>
            </Col>
            <Col md="10">
              <Form.Control
                type="text"
                id="search"
                value={state.inputKeyword}
                className="mb-2"
                placeholder="絞り込みキーワードを入力"
                onChange={(e) => {
                  const keyword = e.target.value;
                  // 打ち終わって500ms後に検索のリクエストをする
                  if (state.timeoutId) {
                    window.clearTimeout(state.timeoutId);
                  }
                  const timeoutId = window.setTimeout(() => {
                    $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                  }, 500);
                  $state({ ...state, timeoutId, inputKeyword: keyword });
                }}
              />
            </Col>
          </Row> */}
          <Row className="mb-2">
            <Col>
              <div className="float-end">
                <Button
                  variant="outline-primary"
                  className="mx-2"
                  disabled={state.checkedIds.length === 0}
                  onClick={() => {
                    $state({ ...state, activeModal: "before_copy" });
                  }}
                >
                  選択したメール文面を複製する
                </Button>
                <Button
                  variant="outline-danger"
                  className="mx-2"
                  disabled={state.checkedIds.length === 0}
                  onClick={() => {
                    $state({ ...state, activeModal: "before_delete" });
                  }}
                >
                  選択したメール文面を削除する
                </Button>
              </div>
            </Col>
          </Row>
          {mails.length > 0 ? (
            <>
              <Table
                col={[
                  {
                    name: "名前",
                    width: 300,
                  },
                  {
                    name: "件名",
                    width: 500,
                  },
                  {
                    name: "詳細",
                    width: 100,
                  },
                ]}
                row={mails.map((m) => {
                  return {
                    id: m.id,
                    key: m.id,
                    data: [m.name, m.subject, <Link to={`/_/mail/detail/${m.id}`}>確認する</Link>],
                  };
                })}
                checkedIds={state.checkedIds}
                onCheck={(next) => {
                  const checkedIds = next as string[];
                  $state({ ...state, checkedIds });
                }}
              />
              {/* バックエンド実装後対応予定のため一旦非表示にする */}
              {/* {
                <Button variant="outline-secondary" className="mt-2 float-end" disabled={true}>
                  さらに表示（全 　 件中 　 件表示中）
                </Button>
              } */}
            </>
          ) : (
            <Alert variant={"info"}>該当するメール文面はありません。</Alert>
          )}
        </Col>
      </Row>
      <ModalDialog
        show={state.activeModal === "before_copy"}
        message="選択したメール文面を複製します。よろしいですか？"
        onConfirm={copy}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
      />
      <ModalDialog
        show={state.activeModal === "before_delete"}
        message="選択したメール文面を削除します。よろしいですか？"
        onConfirm={discard}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
        type="destructiveConfirm"
        confirmButtonName="削除"
      />
    </>
  );
}

export default App;
