import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { testResponse } from "../../app/util";
import { useAppSelector, useAppDispatch } from "../../app/store";
import { showToast } from "../../features/notification/notificationSlice";
import { userColumnChoices } from "../../features/profile/profileFieldValues";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import {
  selectClient,
  unselectClient,
  unselectLoadData,
  selectClientState,
  convertLoadDataToCells,
  setAdhocColumns,
  setAdhocCells,
  putAdhocCell,
  correctAdhocCells,
  getAdhocCells,
  downloadAdhocCells,
  getLoadData,
  postLoadData,
  deleteLoadData,
  deleteAdhocCell,
  getRegularColumns,
  getSectors,
  initSectorSettingStatus,
  validate,
  finalize,
  putSectorSettingStatus,
  selectActiveSectors,
  selectMasterActiveSectors,
  getAddingProfileFile,
  extract,
  clearAdhocColumns,
} from "./clientSlice";
import Sidebar from "../../component/Sidebar";
import DataCell from "../../component/DataCell";
import Uploader, { DecodedFileData } from "../../component/Uploader";
import Icon from "../../component/Icon";
import {
  Modifier,
  VALUE_MODIFIERS,
  RegularColumn,
  METADATA_COLUMN,
  IMPORT_STATUSES,
  Sector,
  IMPORT_TYPES,
  OptionType,
  AdhocCell,
} from "./clientValues";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ListGroup,
  Dropdown,
  Tabs,
  Tab,
  Modal,
  Alert,
  OverlayTrigger,
  Tooltip,
  Card,
  Popover,
} from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { getAllTerms } from "../../app/translate";
import ModalDialog from "../../component/ModalDialog";
import { USER_TABLE_PREFIX } from "../profile/profileValues";
import { masterColumnChoices, MASTER_TABLE_PREFIX } from "../masterData/masterDataValues";
import Select from "react-select";

const PREVIEW_RECORDS_UPTO = 20;

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    sectorSettingStatusId,
    sectorSettingStatus,
    adhocCells,
    adhocColumns,
    adhocRecords,
    adhocRecordsCount,
    has_more,
    loadId,
    mapping,
    sectorRegularColumns,
    addingProfileFile,
    loadError,
  } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const masterActiveSectors = useAppSelector(selectMasterActiveSectors);
  const TERMS = getAllTerms();
  const { clientId, sectorId } = useParams();

  const allActiveSectors = useMemo(() => {
    return [...masterActiveSectors, ...activeSectors];
  }, [activeSectors, masterActiveSectors]);

  const SECTOR_NAME = useMemo(() => {
    if (!sectorId) return "";
    const thisSector = allActiveSectors.find(({ sector_id }) => sector_id === sectorId);
    if (!thisSector) return "";
    return thisSector.logical_name;
  }, [sectorId, allActiveSectors]);
  const SECTOR_DESCRIPTION = useMemo(() => {
    if (!sectorId) return "";
    const thisSector = allActiveSectors.find(({ sector_id }) => sector_id === sectorId);
    if (!thisSector) return "";
    return thisSector.description;
  }, [sectorId, allActiveSectors]);
  const header1Ref = useRef<HTMLDivElement | null>(null);
  const [state, $state] = useState({
    activeTabKey: "upload",
    selectedRecords: [] as string[],
    selectAllRecords: false,
    importType: "creating",
    isProcessing:
      !!(sectorId && sectorSettingStatus[sectorId]?.import_process_status === "processing") ||
      !!(addingProfileFile?.id && addingProfileFile?.files.length === 0),
    isValidationRequired: (sectorId && sectorSettingStatus[sectorId]?.validation_required) ?? false,
    zipUploadStatus: addingProfileFile.id ? "uploaded" : "uploadable",
  });
  const [header2TopPosition, setheader2TopPosition] = useState("0px");
  const [activeModal, $activeModal] = useState("");
  const [columnValueModifierModal, $columnValueModifierModal] = useState({
    selectedColumn: null as RegularColumn | null,
    selectedColumnModifier: VALUE_MODIFIERS[0] as Modifier,
    isOpen: false,
    currentValue: "",
    updateValue: "",
    selectedPointDate: null as dayjs.Dayjs | null,
  });
  const regularColumns = useMemo(() => {
    const regularColumns =
      (sectorId &&
        sectorRegularColumns[sectorId]?.filter(
          (c) =>
            !c.virtual_field &&
            (!c.reference_field ||
              (c.reference_field &&
                (c.id.endsWith("_code") || c.id.endsWith("name") || c.id.endsWith("_kana") || c.id.endsWith("_en"))))
        )) ||
      [];
    const _regularColumns = [METADATA_COLUMN, ...regularColumns];
    return _regularColumns;
  }, [sectorRegularColumns, sectorId]);

  const hasFileItem = useMemo(() => {
    return regularColumns.some((c) => c.input_type === "file");
  }, [regularColumns]);

  const availableImportType = useMemo(() => {
    const hasValidTo = sectorId && sectorRegularColumns[sectorId]?.some((s: any) => s.id === "valid_to");
    return hasValidTo ? IMPORT_TYPES : IMPORT_TYPES.filter((t) => t.id !== "setting_valid_to");
  }, [sectorRegularColumns, sectorId]);

  // 横スクロール時の固定カラム
  const stickyColumns = useMemo(() => {
    let columns = ["metadata"]; // レコードの妥当性列は常に固定
    if (!sectorId) return columns;

    let additionalColumns: string[] = [];
    if (sectorId.indexOf(USER_TABLE_PREFIX) === 0) {
      additionalColumns =
        sectorRegularColumns[sectorId]
          ?.filter((_) => ["valid_from", "valid_to", "login_code", "name"].includes(_.id))
          ?.map((_) => _.id) || [];
    } else if (sectorId.indexOf(MASTER_TABLE_PREFIX) === 0) {
      const sectorName = sectorId.replace(MASTER_TABLE_PREFIX, "");
      additionalColumns =
        sectorRegularColumns[sectorId]
          ?.filter((_) => ["valid_from", "valid_to", `${sectorName}_code`, `${sectorName}_name`].includes(_.id))
          ?.map((_) => _.id) || [];
    }
    return [...columns, ...additionalColumns];
  }, [sectorRegularColumns, sectorId]);
  useEffect(() => {
    if (sectorId && hasFileItem && (!addingProfileFile.id || state.activeTabKey === "upload")) {
      dispatch(getAddingProfileFile({ sectorId }));
    }
  }, [hasFileItem, sectorId, state.activeTabKey]);

  useEffect(() => {
    $state({ ...state, zipUploadStatus: addingProfileFile.id ? "uploaded" : "uploadable" });
    if (addingProfileFile.id) {
      dispatch(getAdhocCells({ sectorId: sectorId ?? "", regularColumns }));
    }
  }, [addingProfileFile]);

  const statusKey = useMemo(() => {
    return (sectorId && sectorSettingStatus[sectorId]?.status) ?? IMPORT_STATUSES[0];
  }, [sectorSettingStatus, sectorId]);
  const activeImportType = useMemo(() => {
    if (sectorId && sectorSettingStatus[sectorId]?.status) {
      return ["creating", "setting_valid_to", "deleting"].includes(sectorSettingStatus[sectorId]?.status)
        ? sectorSettingStatus[sectorId]?.status
        : "";
    }
    return "";
  }, [sectorSettingStatus, sectorId]);
  const columnTypeChoices = useMemo(() => {
    if (!sectorId || !regularColumns) return {};
    const choices = sectorId.startsWith(USER_TABLE_PREFIX)
      ? userColumnChoices[sectorId]
      : masterColumnChoices[sectorId];
    return regularColumns.reduce((prev, current) => {
      if (current.input_type !== "options") return prev;
      return {
        ...prev,
        [current.id]: current.options ?? choices[current.id] ?? [],
      };
    }, {}) as { [key: string]: string[] };
  }, [sectorId, regularColumns]);

  useEffect(() => {
    $state({
      ...state,
      importType: activeImportType === "" ? "creating" : activeImportType,
    });
  }, [activeImportType]);

  useEffect(() => {
    return () => {
      // 直前にアップロードしたデータが残ってしまうためここでクリア
      dispatch(clearAdhocColumns());
    };
  }, []);

  /*
    アップロードファイルを選択したら
    仮のデータとして登録し、カラムの設定を行う。
  */
  const onFileLoad = (decodedFileData: DecodedFileData) => {
    dispatch(
      postLoadData({
        sectorId: sectorId ?? "",
        importType: state.importType,
        file: decodedFileData.dataURI,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          showToast({
            id: `toast-${Date.now()}`,
            content: "ファイルを読み込みました。",
          })
        );
      })
      .catch(() => {
        return $activeModal("invalid_file_uploaded");
      });
  };

  useEffect(() => {
    if (sectorId && regularColumns) {
      dispatch(getLoadData({ sectorId })).then(() => {
        dispatch(getAdhocCells({ sectorId, regularColumns }));
      });
    }
  }, [regularColumns]);

  useEffect(() => {
    if (sectorId && !sectorRegularColumns[sectorId]) {
      dispatch(getRegularColumns({ sectorId }));
    }
    if (sectorId) {
      $state({
        ...state,
        importType: ["todo", "done"].includes(sectorSettingStatus[sectorId]?.status)
          ? "creating"
          : sectorSettingStatus[sectorId]?.status,
      });
    }
    // コメント反映させるため必ずinitSectorSettingStatusを呼ぶ
    dispatch(getSectors()).then((result) => {
      const sectors = result.payload as Sector[];
      if (sectors?.length > 0) dispatch(initSectorSettingStatus({ sectors: sectors }));
    });
    return () => {
      dispatch(unselectLoadData());
    };
  }, [sectorId, dispatch]);

  /*
    アドホックカラムに対応する正規化カラムを選択する。
    全ての正規化カラムに対応させられたらセルデータが作成可能になる
  */
  const selectRegularColumn = (columnName: string, regularColumnId: string) => {
    const _adhocColumns = adhocColumns.map((c) => {
      if (c.label === columnName) return { ...c, regularColumnId };
      return c;
    });
    dispatch(
      setAdhocColumns({
        adhocColumns: _adhocColumns,
      })
    );
  };

  /*
    カラムの編集内容を保存する
  */
  const submitColumns = async () => {
    $state({ ...state, isProcessing: true });
    // セルデータの作成リクエスト
    testResponse(
      await dispatch(
        convertLoadDataToCells({
          loadId,
          sectorId: sectorId ?? "",
          mapping,
        })
      )
    );
    dispatch(
      showToast({
        id: `toast-${Date.now()}`,
        content: `${SECTOR_NAME}データのインポート開始処理を開始しました。処理中です…`,
      })
    );
  };

  /*
    セルを個別に編集開始する
  */
  const startCorrectingCell = (cellId: string) => {
    if (adhocCells.some((cell) => cell.correcting)) return;
    dispatch(
      setAdhocCells({
        adhocCells: adhocCells.map((cell) => {
          return { ...cell, correcting: cell.id === cellId };
        }),
        regularColumns,
      })
    );
  };

  /*
    セルの編集中　変更された値を受け取る
  */
  const updateCell = (cellId: string, value: string | number | boolean) => {
    if (adhocCells.every((cell) => !cell.correcting)) return;
    dispatch(
      setAdhocCells({
        adhocCells: adhocCells.map((cell) => {
          const _value = cell.id !== cellId ? cell.value : value;
          return {
            ...cell,
            value: _value,
          };
        }),
        regularColumns,
      })
    );
  };

  /*
    セルを編集終了する
  */
  const endCorrectingCell = async (cellId: string, value: string | number | boolean, cancelled: boolean) => {
    const cellToPut = adhocCells.find((cell) => cell.id === cellId);
    if (!cellToPut) return;
    const next = adhocCells.map((cell) => {
      const _cell = {
        ...cell,
        correcting: false,
        value: cellToPut.id === cell.id ? value : cell.value,
      };
      return _cell;
    });
    // ここで再度 put するセルデータを取得
    const updateCell = next.find((cell) => cell.id === cellId) as AdhocCell;
    dispatch(
      setAdhocCells({
        adhocCells: next,
        regularColumns,
      })
    );
    if (cancelled) return;
    if (
      !testResponse(
        await dispatch(
          putAdhocCell({
            sectorId: sectorId ?? "",
            cell: updateCell,
          })
        )
      )
    ) {
      return;
    }
    dispatch(
      setAdhocCells({
        adhocCells: next,
        regularColumns,
      })
    );
  };

  /*
    カラムごとに一括操作のプリセットを実行する
  */
  const executePreset = async () => {
    if (columnValueModifierModal.selectedColumnModifier.id !== "_blank") {
      dispatch(
        correctAdhocCells({
          sectorId: sectorId ?? "",
          column: columnValueModifierModal.selectedColumn?.id ?? "",
          columnModifier: columnValueModifierModal.selectedColumnModifier,
          currentValue: columnValueModifierModal.currentValue,
          updateValue: columnValueModifierModal.updateValue,
        })
      );
      $columnValueModifierModal({
        ...columnValueModifierModal,
        selectedColumnModifier: VALUE_MODIFIERS[0],
        currentValue: "",
        updateValue: "",
        selectedPointDate: null,
        isOpen: false,
      });
      dispatch(
        showToast({
          id: `toast-${Date.now()}`,
          content: `${columnValueModifierModal.selectedColumn?.label} の一括操作を開始しました。処理中です…`,
        })
      );
      $state({ ...state, isProcessing: true });
    } else {
      $columnValueModifierModal({
        ...columnValueModifierModal,
        selectedColumnModifier: VALUE_MODIFIERS[0],
        currentValue: "",
        updateValue: "",
        selectedPointDate: null,
        isOpen: false,
      });
    }
  };

  const deleteSelectedRecords = () => {
    try {
      if (state.selectedRecords.length > 0) {
        dispatch(deleteAdhocCell({ sectorId: sectorId ?? "", recordIds: state.selectedRecords }));
      }
      dispatch(
        showToast({
          id: `toast-${Date.now()}`,
          content: `選択されたインポート中の${SECTOR_NAME}データの破棄を開始しました。処理中です…`,
        })
      );
      $state({ ...state, isProcessing: true, selectedRecords: [] });
    } catch (e) {
      console.error(e);
    }
  };
  const destroyRecords = () => {
    try {
      $state({ ...state, isProcessing: true });
      if (loadId) {
        dispatch(deleteLoadData({ loadId }));
      }
      // セルデータ削除内でセクター設定ステータスの更新を行っているため、セルデータが空の場合も削除処理を行う
      dispatch(deleteAdhocCell({ sectorId: sectorId ?? "" }));
    } catch (e) {
      console.error(e);
    }
  };

  const validateRecords = async () => {
    try {
      await dispatch(
        validate({
          sectorId: sectorId ?? "",
        })
      );
      dispatch(
        showToast({
          id: `toast-${Date.now()}`,
          content: `${SECTOR_NAME}データのバリデーションを開始しました。処理中です…`,
        })
      );
      $state({ ...state, isProcessing: true });
    } catch (e) {
      console.error(e);
    }
  };

  const finalizeRecords = async () => {
    try {
      await dispatch(
        finalize({
          sectorId: sectorId ?? "",
          loadId,
          sectorSettingStatusId,
        })
      );
      dispatch(
        showToast({
          id: `toast-${Date.now()}`,
          content: `${SECTOR_NAME}データのインポート完了処理を開始しました。処理中です…`,
        })
      );
      $state({ ...state, isProcessing: true });
    } catch (e) {
      console.error(e);
    }
  };

  const downloadCells = async () => {
    await dispatch(
      downloadAdhocCells({
        sectorId: sectorId ?? "",
      })
    );
  };

  const selectableRegularColumns = useMemo(() => {
    return regularColumns.filter((r) => {
      return r.id !== "metadata" && !r.edit_only && !adhocColumns.some((a) => a.regularColumnId === r.id);
    });
  }, [adhocColumns, regularColumns]);
  useEffect(() => {
    clientId && dispatch(selectClient({ id: clientId }));
    return () => {
      dispatch(unselectClient());
    };
  }, [clientId, dispatch]);

  useEffect(() => {
    $state({
      ...state,
      isProcessing: sectorId && sectorSettingStatus[sectorId]?.import_process_status === "processing" ? true : false,
      isValidationRequired: (sectorId && sectorSettingStatus[sectorId]?.validation_required) ?? false,
      // ここでタブ遷移が決まる
      activeTabKey: loadId ? "column" : adhocRecordsCount === 0 ? "upload" : "value",
    });
  }, [adhocRecordsCount, sectorSettingStatus]);

  useEffect(() => {
    if (state.activeTabKey !== "value") {
      if (state.selectedRecords.length !== 0 || state.selectAllRecords) {
        $state({ ...state, selectedRecords: [], selectAllRecords: false });
      }
    }
  }, [state.activeTabKey]);

  useEffect(() => {
    const updateHeader2TopPosition = () => {
      if (header1Ref.current) {
        const firstRowHeight = header1Ref.current.offsetHeight;
        setheader2TopPosition(`${firstRowHeight}px`);
      }
    };

    const timer = setTimeout(updateHeader2TopPosition, 0);
    window.addEventListener("resize", updateHeader2TopPosition);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", updateHeader2TopPosition);
    };
  }, [state.isProcessing, adhocRecords.length]);

  const selectSettingStatus = (status: string) => {
    if (sectorId) {
      const itemName: string = `${sectorId}.status`;
      const updateData: { [key: string]: string } = { [itemName]: status };
      dispatch(
        putSectorSettingStatus({
          sectorSettingStatusId,
          updateData,
        })
      );
    }
  };
  const onSelectRecord = (recordId: string) => {
    let selectedRecords = state.selectedRecords.map((_) => _);
    const index: number = selectedRecords.indexOf(recordId);
    if (index === -1) {
      selectedRecords.push(recordId);
    } else {
      selectedRecords.splice(index, 1);
    }
    $state({ ...state, selectedRecords });
  };

  const onSelectAllRecords = (recordIds: string[]) => {
    const selectedRecords = state.selectAllRecords ? [] : recordIds;
    $state({ ...state, selectedRecords, selectAllRecords: !state.selectAllRecords });
  };

  const onZipfileLoaded = (decodedFile: DecodedFileData) => {
    $state({ ...state, isProcessing: true });
    dispatch(
      extract({
        sector: sectorId ?? "",
        data: decodedFile.dataURI,
        filename: decodedFile.name,
      })
    );
  };

  const zipUploadArea = useMemo(() => {
    if (!hasFileItem || state.importType !== "creating" || state.isProcessing) return null;
    return (
      <div className="mb-1">
        <div className="--bold">添付ファイル</div>
        {state.zipUploadStatus === "uploaded" ? (
          <>
            {`${addingProfileFile.zipFilenames.join(",")}（`}
            <OverlayTrigger
              placement="bottom"
              trigger="click"
              rootClose
              overlay={(props) => (
                <Popover id="popover" {...props} className="--pre-wrap">
                  <Popover.Body className="--overflow-auto">
                    {addingProfileFile.files.map((file, i) => {
                      return <div key={`file_${i}`}>{file}</div>;
                    })}
                  </Popover.Body>
                </Popover>
              )}
            >
              <span className="--text-link --cursor-pointer">{`${addingProfileFile.files.length}ファイル`}</span>
            </OverlayTrigger>
            ）アップロード済
          </>
        ) : (
          <>
            <Uploader onFileLoad={onZipfileLoaded} accepts={["application/zip", "application/x-zip-compressed"]} />
            <div>
              <Form.Text className="text-muted">
                ※ 添付ファイル項目に記載した名前のファイルをまとめてzip形式に圧縮してアップロードしてください。
              </Form.Text>
            </div>
            <div>
              <Form.Text className="text-muted">※ アップロードできるファイルは .zip 形式に限ります。</Form.Text>
            </div>
          </>
        )}
      </div>
    );
  }, [state, addingProfileFile, hasFileItem, sectorId]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"import"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">
          {SECTOR_NAME}のインポート
          {sectorId && sectorSettingStatus[sectorId]?.status ? (
            <span
              className={classNames({
                "Badge--waiting": "todo" === sectorSettingStatus[sectorId]?.status,
                "Badge--running": ["creating", "setting_valid_to", "deleting"].includes(
                  sectorSettingStatus[sectorId]?.status
                ),
                "Badge--ok": "done" === sectorSettingStatus[sectorId]?.status,
                "mx-1": true,
              })}
            >
              {TERMS[`IMPORT_STATUS_${sectorSettingStatus[sectorId]?.status}`]}
            </span>
          ) : (
            ""
          )}
        </h1>
        <Form
          className="Grouping bg-white"
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <Container>
            {/* セクター設定ステータスの更新は一旦コメントアウト*/}
            {/* <Row className="mb-3">
              <Col>
                <Dropdown>
                  <Dropdown.Toggle
                    variant={(() => {
                      switch (statusKey) {
                        case "creating":
                        case "setting_valid_to":
                          return "outline-primary";
                        case "done":
                          return "outline-success";
                        default:
                          return "outline-secondary";
                      }
                    })()}
                  >
                    {TERMS[`IMPORT_STATUS_${statusKey}`]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {IMPORT_STATUSES.map((o, i) => (
                      <Dropdown.Item key={`status-${i}`} onClick={() => selectSettingStatus(o)}>
                        {TERMS[`IMPORT_STATUS_${o}`]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Tabs
                  className="mb-3"
                  onSelect={(key) => key && $state({ ...state, activeTabKey: key })}
                  activeKey={state.activeTabKey}
                >
                  <Tab
                    eventKey="upload"
                    title="1. ファイル選択"
                    disabled={!!loadId || state.isProcessing || adhocRecordsCount > 0}
                  >
                    <Row className="mb-3">
                      <h2 className="Headline--section mb-2">インポートの種類</h2>
                      <Row className="mb-3">
                        <Col>
                          {availableImportType.map((t) => {
                            return (
                              <Form.Check
                                inline
                                type="radio"
                                label={t.label}
                                key={`existing_check_${t.id}`}
                                id={`existing_check_${t.id}`}
                                checked={state.importType === t.id}
                                value={t.id}
                                disabled={activeImportType !== "" && activeImportType !== t.id}
                                onChange={(e) => {
                                  $state({ ...state, importType: e.target.value });
                                }}
                              />
                            );
                          })}
                          {state.importType === "deleting" && (
                            <Col>
                              <Alert variant={"warning"}>
                                アップロードしたデータを削除します。操作はもとに戻せません。
                              </Alert>
                            </Col>
                          )}
                        </Col>
                        {activeImportType && sectorId && sectorSettingStatus[sectorId]?.status ? (
                          <Form.Text className="text-muted">
                            ※ {TERMS[`IMPORT_STATUS_${sectorSettingStatus[sectorId]?.status}`]}
                            のため、他のインポートは選択できません。
                          </Form.Text>
                        ) : (
                          ""
                        )}
                      </Row>
                      <Col>
                        <Uploader
                          onFileLoad={onFileLoad}
                          accepts={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        />
                        <div>
                          <div>
                            <Form.Text className="text-muted">
                              ※ 完了処理されていないデータは全て削除されます。
                            </Form.Text>
                          </div>
                          <div>
                            <Form.Text className="text-muted">
                              ※ アップロードできるファイルは .xlsx 形式に限ります。
                            </Form.Text>
                          </div>
                          <div>
                            <Form.Text className="text-muted">
                              ※ 1回のアップロードで処理可能なレコード件数の上限は3000件です。
                            </Form.Text>
                          </div>
                          {hasFileItem && (
                            <div>
                              <Form.Text className="text-muted">
                                ※ 添付ファイル項目のアップロードは「2. インポート開始」で行います。
                              </Form.Text>
                            </div>
                          )}
                          {state.importType === "creating" && sectorId === "profile_u_dependent" && (
                            <div>
                              <Form.Text className="text-muted">
                                ※ シリアル(no)がブランクの場合は、自動で採番されます。
                              </Form.Text>
                            </div>
                          )}
                          <div className="mt-3">
                            <h2 className="Headline--section mb-2">{SECTOR_NAME}の入力項目</h2>
                            <Row className="mb-2">
                              <Col>{SECTOR_DESCRIPTION}</Col>
                            </Row>
                            <Card>
                              <Card.Body>
                                <ListGroup className="my-1">
                                  {regularColumns
                                    .filter((r) => {
                                      return (
                                        (r.id !== "metadata" && state.importType === "creating" && !r.edit_only) ||
                                        (r.id !== "metadata" &&
                                          (state.importType === "setting_valid_to" || state.importType === "deleting"))
                                      );
                                    })
                                    .map((c, i) => {
                                      const annotation = TERMS[`EDITING_ANNOTATION_${sectorId}__${c.id}`];
                                      return (
                                        <div
                                          className={classNames({
                                            "--bold": true,
                                            "--required-label": c.required || c.edit_only,
                                          })}
                                          key={c.id}
                                        >
                                          {c.label}
                                          {annotation && (
                                            <OverlayTrigger
                                              placement="right"
                                              delay={{ show: 50, hide: 50 }}
                                              overlay={(props) => (
                                                <Tooltip id={`tooltip_${c.id}`} {...props}>
                                                  {annotation}
                                                </Tooltip>
                                              )}
                                            >
                                              <span className="ms-1">
                                                <Icon width={15} height={15} type="info-circle-fill" />
                                              </span>
                                            </OverlayTrigger>
                                          )}
                                        </div>
                                      );
                                    })}
                                </ListGroup>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="column" title="2. インポート開始" disabled={!loadId}>
                    <p>選択したファイルのインポート処理を開始します。この処理には少し時間がかかる場合があります。</p>
                    <Button
                      className="mb-3"
                      disabled={state.isProcessing || !adhocColumns.some((a) => a.regularColumnId)}
                      onClick={submitColumns}
                    >
                      インポートを開始する
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        $activeModal("before_destroy");
                      }}
                      className="float-end"
                      disabled={state.isProcessing || (!loadId && adhocRecordsCount === 0)}
                    >
                      インポート中の内容を破棄する
                    </Button>
                    {state.isProcessing ? (
                      <Alert variant={"info"}>処理中です。</Alert>
                    ) : (
                      <>
                        {zipUploadArea}
                        <div className="Table">
                          <div className="Table__header">
                            <div className="Table__header-inner">
                              <div className="Table__header-col">
                                <p className="Text__label">ファイル中の項目名</p>
                              </div>
                              <div className="Table__header-col">
                                <p className="Text__label">対応先の項目名</p>
                              </div>
                            </div>
                          </div>
                          {adhocColumns.map((c, i) => {
                            const selectedRegularColumn = regularColumns.find(
                              (_) => _.id === (c.regularColumnId || "_blank")
                            );
                            const selectedOption = {
                              label: selectedRegularColumn?.label || "--",
                              value: selectedRegularColumn?.id || "",
                            };
                            let options = [selectedOption];
                            if (selectedRegularColumn) options = [{ label: "--", value: "" }, ...options];
                            options = [
                              ...options,
                              ...selectableRegularColumns.map((r) => ({
                                label: r.label,
                                value: r.id,
                              })),
                            ] as OptionType[];

                            return (
                              <div className="Table__row" key={`uploadingColumn-${i}`}>
                                <div className="Table__row-inner">
                                  <div className="Table__col">
                                    <p className="Text__label">{c.label}</p>
                                  </div>
                                  <div className="Table__col">
                                    <Select
                                      defaultValue={selectedOption}
                                      onChange={(selected: OptionType | null) => {
                                        selectRegularColumn(c.label, selected?.value ?? "");
                                      }}
                                      options={options}
                                      menuPlacement="auto"
                                      menuPortalTarget={document.body}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}

                    <div></div>
                  </Tab>
                  <Tab eventKey="value" title="3. インポート処理" disabled={adhocRecordsCount === 0}>
                    <div className="mb-3">
                      <Button variant="outline-secondary" onClick={downloadCells} disabled={state.isProcessing}>
                        インポート中のデータをダウンロードする
                      </Button>
                      <Button
                        onClick={() => {
                          $activeModal("before_validate");
                        }}
                        className="mx-2"
                        disabled={state.isProcessing || !state.isValidationRequired}
                      >
                        バリデーションを実行する
                      </Button>
                      <Button
                        onClick={() => {
                          $activeModal("before_finalize");
                        }}
                        className="mx-2"
                        disabled={
                          state.isProcessing ||
                          (adhocRecordsCount > 0 && adhocRecords.length > 0) ||
                          !!state.isValidationRequired
                        }
                      >
                        完了処理を開始する
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          $activeModal("before_destroy");
                        }}
                        className="float-end"
                        disabled={state.isProcessing || (!loadId && adhocRecordsCount === 0)}
                      >
                        インポート中の内容を破棄する
                      </Button>
                    </div>
                    {!state.isProcessing && state.isValidationRequired ? (
                      <Alert variant={"warning"}>
                        修正の後にバリデーションが実行されていません。完了処理を開始するには、バリデーションを実行してください。
                      </Alert>
                    ) : (
                      ""
                    )}
                    {state.isProcessing ? (
                      <Alert variant={"info"}>処理中です。</Alert>
                    ) : adhocRecords.length === 0 ? (
                      <Alert variant={"info"}>
                        インポート中のデータ（{adhocRecordsCount} 件）は全て完了処理が可能です。
                      </Alert>
                    ) : (
                      <div>
                        <div className="mb-3">
                          {has_more ? (
                            <Alert variant={"warning"}>
                              エラーがあります。最初の {PREVIEW_RECORDS_UPTO} 件を表示しています。
                            </Alert>
                          ) : (
                            <Alert variant={"warning"}>
                              エラーが {adhocRecords.length} 件（{adhocRecordsCount} 件中）あります。
                            </Alert>
                          )}
                        </div>
                        <div className="mb-3 mb-md-4">
                          <p className="--font-s text-muted">
                            ※エラーをすべて解消すると完了処理が開始できます。エラーが解消し画面に反映されるまで少し時間がかかる場合があります。
                          </p>
                        </div>
                        {zipUploadArea}
                        <div className="mb-2">
                          <span className="--required-label"></span>
                          は必須項目です。
                        </div>
                        <div className="mb-2">
                          <span className="--px-2">
                            <Icon type="external-tab" width={16} height={16} />
                          </span>
                          はコード情報との整合性をチェックのための項目です。この画面では編集できません。別タブで登録済みのコード情報を確認できます。
                        </div>
                      </div>
                    )}

                    {!state.isProcessing && adhocRecords.length > 0 && (
                      <div>
                        <div className="Table__col">
                          <Form.Check
                            checked={state.selectAllRecords}
                            type="checkbox"
                            id={"checkbox_all"}
                            label={"すべて選択"}
                            onChange={() => onSelectAllRecords(adhocRecords.map((r) => r[0].record_id))}
                          />
                        </div>
                        <div className="Table">
                          <div ref={header1Ref} style={{ top: "0px" }} className="Table__header">
                            <div className="Table__header-inner">
                              <div
                                className={classNames({
                                  "Table__header-col--sticky": true,
                                  "Table__col--form-check": true,
                                  Text__label: true,
                                })}
                                style={{ width: 40, left: 0 }}
                              ></div>
                              {regularColumns.map((c, i) => {
                                const annotation = TERMS[`EDITING_ANNOTATION_${sectorId}__${c.id}`];
                                return (
                                  <div
                                    className={classNames({
                                      "Table__header-col--sticky": i < stickyColumns.length,
                                      "Table__header-col": stickyColumns.length <= i,
                                      Text__label: true,
                                      "Table__header--metadata": c.type === "metadata",
                                      "Table__header--required": c.required,
                                    })}
                                    style={{
                                      width: 140,
                                      left: i < stickyColumns.length ? (i === 0 ? 40 : 40 + 140 * i) : undefined,
                                    }}
                                    key={`preview-${c.id}`}
                                  >
                                    {c.reference_field ? (
                                      c.id === "name" ? (
                                        <a
                                          className="Table__header--external-tab --block-link"
                                          href={`/_/account/`}
                                          target="_blank"
                                          key={c.id}
                                        >
                                          {c.label}
                                          <span className="--px-2">
                                            <Icon type="external-tab" width={16} height={16} />
                                          </span>
                                        </a>
                                      ) : (
                                        <a
                                          className="Table__header--external-tab --block-link"
                                          href={`/_/property/codes/?field=${c.id.split("_")[0]}`}
                                          target="_blank"
                                          key={c.id}
                                        >
                                          {c.label}
                                          <span className="--px-2">
                                            <Icon type="external-tab" width={16} height={16} />
                                          </span>
                                        </a>
                                      )
                                    ) : (
                                      `${c.label}`
                                    )}
                                    {annotation && (
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 50, hide: 50 }}
                                        overlay={(props) => (
                                          <Tooltip id={`tooltip_${c.id}`} {...props}>
                                            {annotation}
                                          </Tooltip>
                                        )}
                                      >
                                        <span className="ms-1">
                                          <Icon width={15} height={15} type="info-circle-fill" />
                                        </span>
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div style={{ top: header2TopPosition }} className="Table__header">
                            <div className="Table__header-inner">
                              <div
                                className={classNames({
                                  "Table__header-col--sticky": true,
                                  "Table__col--form-check": true,
                                })}
                                style={{ width: 40, left: 0 }}
                              ></div>
                              {regularColumns.map((c, i) => {
                                return (
                                  <div
                                    className={classNames({
                                      "Table__header-col--sticky": i < stickyColumns.length,
                                      "Table__header-col": stickyColumns.length <= i,
                                    })}
                                    style={{
                                      width: 140,
                                      left: i < stickyColumns.length ? (i === 0 ? 40 : 40 + 140 * i) : undefined,
                                    }}
                                    key={`preview-modifier-${c.label}`}
                                  >
                                    <Button
                                      variant="outline-secondary"
                                      onClick={() =>
                                        $columnValueModifierModal({
                                          ...columnValueModifierModal,
                                          selectedColumn: c,
                                          isOpen: true,
                                        })
                                      }
                                    >
                                      一括操作
                                    </Button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {adhocRecords
                            .map((r) => {
                              return r.filter((cell) => {
                                return regularColumns.find((RegularColumn) => cell.column === RegularColumn.id);
                              });
                            })
                            .map((r, i) => {
                              return (
                                <div className="Table__row" key={`adhoc-${i}`}>
                                  <div className="Table__row-inner">
                                    <div
                                      className={classNames({
                                        "Table__col--sticky": true,
                                        "Table__col--form-check": true,
                                      })}
                                      style={{ width: 40, left: 0 }}
                                    >
                                      <Form.Check
                                        checked={state.selectedRecords.includes(r[0].record_id)}
                                        type="checkbox"
                                        id={`checkbox_${i}`}
                                        onChange={() => onSelectRecord(r[0].record_id)}
                                      />
                                    </div>
                                    {r.map((cell, ri) => {
                                      return (
                                        <div
                                          key={`adhoc-${i}-cell-${ri}`}
                                          className={classNames({
                                            "Table__col--sticky": ri < stickyColumns.length,
                                            Table__col: stickyColumns.length <= ri,
                                            "Table__col--editable": !cell.correcting && cell.type === "content",
                                            "Table__col--danger": cell.errors.length > 0,
                                            "Table__col--metadata": cell.type === "metadata",
                                          })}
                                          style={{
                                            width: 140,
                                            left:
                                              ri < stickyColumns.length ? (ri === 0 ? 40 : 40 + 140 * ri) : undefined,
                                          }}
                                          onClick={() => {
                                            !cell.correcting && cell.type === "content" && startCorrectingCell(cell.id);
                                          }}
                                        >
                                          <DataCell
                                            cell={cell}
                                            columnChoices={columnTypeChoices}
                                            onChange={updateCell}
                                            onClose={endCorrectingCell}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div>
                          <div className="Table__col">
                            <Form.Check
                              checked={state.selectAllRecords}
                              type="checkbox"
                              id={"checkbox_all"}
                              label={"すべて選択"}
                              onChange={() => onSelectAllRecords(adhocRecords.map((r) => r[0].record_id))}
                            />
                          </div>
                          <Button
                            className="mt-3"
                            variant="outline-danger"
                            disabled={state.selectedRecords.length === 0}
                            onClick={() => {
                              state.selectedRecords.length === adhocRecordsCount
                                ? $activeModal("before_destroy") // 全件削除
                                : $activeModal("before_delete"); // 一部削除
                            }}
                          >
                            選択したレコードを削除する
                          </Button>
                        </div>
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
      <Modal
        show={columnValueModifierModal.isOpen}
        centered
        onHide={() =>
          $columnValueModifierModal({
            ...columnValueModifierModal,
            selectedColumn: null,
            selectedColumnModifier: VALUE_MODIFIERS[0],
            currentValue: "",
            updateValue: "",
            selectedPointDate: null,
            isOpen: false,
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{columnValueModifierModal.selectedColumn?.label} を一括操作</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle variant="outline-primary">
              {columnValueModifierModal.selectedColumnModifier?.label}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {VALUE_MODIFIERS.filter((o) => {
                const importType = o.importType ?? IMPORT_TYPES.map((_) => _.id);
                const targetType = o.targetType ?? "content";
                const targetIdRegExp = o.targetIdRegExp ?? /.+/;
                const targetKeyIdRegExp = o.targetKeyIdRegExp ?? /.+/;
                const { selectedColumn } = columnValueModifierModal;
                if (!selectedColumn) return false;
                const { id, type, isKey } = selectedColumn;
                return (
                  importType.includes(state.importType) &&
                  targetType === type &&
                  id.match(targetIdRegExp) &&
                  (!isKey || id.match(targetKeyIdRegExp))
                );
              }).map((o, ii) => {
                return (
                  <div key={`preset-${ii}`}>
                    <Dropdown.Item
                      onClick={() =>
                        $columnValueModifierModal({
                          ...columnValueModifierModal,
                          selectedColumnModifier: o,
                        })
                      }
                    >
                      {o.key == "normalize" ? (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 50, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              {"例：＆？（）～㈱１２３ＡＢＣｱｲｳ ⇒ &?()~(株)123ABCアイウ"}
                            </Tooltip>
                          )}
                        >
                          <div className="Table__tooltip-content">{o.label}</div>
                        </OverlayTrigger>
                      ) : (
                        <div className="Table__tooltip-content">{o.label}</div>
                      )}
                    </Dropdown.Item>
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          {columnValueModifierModal.selectedColumnModifier.currentValueRequired && (
            <Form.Control
              className="mt-2"
              type="text"
              placeholder="現在の値を入力"
              defaultValue={columnValueModifierModal.currentValue}
              onChange={(e) => {
                $columnValueModifierModal({
                  ...columnValueModifierModal,
                  currentValue: e.target.value,
                });
              }}
            />
          )}
          {columnValueModifierModal.selectedColumnModifier.valueRequired &&
            (columnValueModifierModal.selectedColumn?.input_type === "options" ? (
              <div className="mt-2">
                <Form.Select
                  value={columnValueModifierModal.updateValue}
                  onChange={(e) => {
                    $columnValueModifierModal({
                      ...columnValueModifierModal,
                      updateValue: e.target.value,
                    });
                  }}
                >
                  <option value="">（値なし）</option>
                  {columnValueModifierModal?.selectedColumn?.id &&
                    columnTypeChoices?.[columnValueModifierModal?.selectedColumn?.id]?.map((value, i) => {
                      return (
                        <option key={`option${i}`} value={value}>
                          {value}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>
            ) : columnValueModifierModal.selectedColumn?.input_type === "date" ||
              columnValueModifierModal.selectedColumn?.input_type === "datetime" ? (
              <div className="mt-2">
                <SnapshotTimeSelector
                  selectedPointDate={columnValueModifierModal.selectedPointDate}
                  onChange={({ selectedPointDate }: onChangeOption) => {
                    if (!selectedPointDate) {
                      $columnValueModifierModal({
                        ...columnValueModifierModal,
                        updateValue: "",
                        selectedPointDate: null,
                      });
                    } else {
                      $columnValueModifierModal({
                        ...columnValueModifierModal,
                        updateValue: selectedPointDate.format("YYYY-MM-DD"),
                        selectedPointDate: selectedPointDate,
                      });
                    }
                  }}
                  isInput={true}
                />
              </div>
            ) : columnValueModifierModal.selectedColumn?.input_type === "boolean" ? (
              <div className="mt-2">
                {["1", "0"].map((value) => (
                  <Form.Check
                    type="radio"
                    label={value}
                    value={value}
                    checked={columnValueModifierModal.updateValue === value}
                    onChange={(e) => {
                      $columnValueModifierModal({
                        ...columnValueModifierModal,
                        updateValue: e.target.value,
                      });
                    }}
                    key={value}
                  />
                ))}
              </div>
            ) : (
              <Form.Control
                className="mt-2"
                type="text"
                placeholder={"値を入力"}
                defaultValue={columnValueModifierModal.updateValue}
                onChange={(e) => {
                  $columnValueModifierModal({
                    ...columnValueModifierModal,
                    updateValue: e.target.value,
                  });
                }}
              />
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() =>
              $columnValueModifierModal({
                ...columnValueModifierModal,
                selectedColumnModifier: VALUE_MODIFIERS[0],
                currentValue: "",
                updateValue: "",
                selectedPointDate: null,
                isOpen: false,
              })
            }
          >
            キャンセル
          </Button>
          <Button variant="primary" disabled={!columnValueModifierModal.selectedColumnModifier} onClick={executePreset}>
            一括操作を実行する
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={activeModal === "before_destroy"}
        onConfirm={() => {
          $activeModal("");
          destroyRecords();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        type="destructiveConfirm"
        confirmButtonName="破棄"
        message="インポート中の内容をすべて破棄します。よろしいですか？"
      />
      <ModalDialog
        show={activeModal === "before_delete"}
        onConfirm={() => {
          $activeModal("");
          deleteSelectedRecords();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        type="destructiveConfirm"
        confirmButtonName="削除"
        message="選択中のデータを削除します。よろしいですか？"
      />
      <ModalDialog
        show={activeModal === "before_validate"}
        onConfirm={() => {
          $activeModal("");
          validateRecords();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="インポート中のデータのバリデーションを実行しますか？この処理には少し時間がかかる場合があります。"
      />
      <ModalDialog
        show={activeModal === "before_finalize"}
        onConfirm={() => {
          $activeModal("");
          finalizeRecords();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="インポート中のデータの完了処理を開始しますか？この処理には少し時間がかかる場合があります。"
      />
      <ModalDialog
        show={activeModal === "invalid_file_uploaded" && loadError !== ""}
        onConfirm={() => {
          $activeModal("");
        }}
        message={`ファイルをアップロードできませんでした（${loadError}）`}
        type="alert"
      />
    </div>
  );
}

export default App;
