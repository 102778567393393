import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Tab, Form, Button, Alert, Nav, Accordion } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import Table from "../../component/Table";
import {
  getApplyTemplateSummaries,
  getApplyForms,
  getRequiredTemplates,
  selectApplyState,
  getRelatedAccounts,
  unselectRunningForms,
  singleDownloadApplyForms,
  notifyApplication,
  getTodoFormCounts,
} from "../../features/apply/applySlice";
import { selectUserRootRoles, selectUserState } from "../../features/login/userSlice";
import { setLoading } from "../../features/notification/notificationSlice";
import SnapshotTimeSelector, { onChangeOption } from "../../features/profile/SnapshotTimeSelector";
import { useState, useEffect, Fragment, useMemo } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { getAllTerms } from "../../app/translate";
import {
  APPLICATION_STATUSES,
  APPLICATION_STEP_STATUSES,
  RunningApplyForm,
  APPLICATION_CATEGORIES,
  ApplyTemplateSummary,
} from "./applyValues";
import classNames from "classnames";
import { getQuery } from "../../app/util";
import MemberSelector from "./MemberSelector";
import ModalDialog from "../../component/ModalDialog";

type TabDefinition = {
  [eventKey: string]: {
    defaultStatuses: string[];
    actionRequiredConditions?: {
      [key: string]: any;
    };
    listHeaderKeys: string[];
    isAvailable: (
      policies: { [apiName: string]: string[] },
      roles: string[],
      representativeTemplateSummaries: ApplyTemplateSummary[]
    ) => boolean;
  };
};
const tabPaneDefinitions = {
  applicant: {
    defaultStatuses: ["todo", "rejected_to_applicant", "running", "rejected", "copied"],
    actionRequiredConditions: {
      statuses: ["rejected_to_applicant"],
      stepStatuses: [],
    },
    listHeaderKeys: ["status", "application_type", "created_at", "applied_at", "completed_at", "updated_at", "detail"],
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("application_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
  representative_applicant: {
    defaultStatuses: ["todo", "rejected_to_applicant", "running", "rejected", "copied"],
    actionRequiredConditions: {
      statuses: ["rejected_to_applicant"],
      stepStatuses: [],
    },
    listHeaderKeys: [
      "status",
      "applicant",
      "application_type",
      "created_at",
      "applied_at",
      "completed_at",
      "updated_at",
      "detail",
    ],
    isAvailable: (policies, _, representativeTemplateSummaries) => {
      return (
        Object.keys(policies)
          .filter((key: string) => key.includes("application_manager"))
          ?.some((api) => policies[api]?.includes("GET")) && representativeTemplateSummaries.length > 0
      );
    },
  },
  processor: {
    defaultStatuses: ["running", "rejected", "rejected_to_applicant", "copied"],
    actionRequiredConditions: {
      statuses: [],
      stepStatuses: ["running"],
    },
    listHeaderKeys: [
      "status",
      "applicant",
      "application_type",
      "role",
      "applied_at",
      "completed_at",
      "updated_at",
      "detail",
    ],
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("application_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
  admin: {
    defaultStatuses: ["todo", "running", "rejected", "rejected_to_applicant", "copied"],
    listHeaderKeys: [
      "status",
      "applicant",
      "application_type",
      "created_at",
      "applied_at",
      "completed_at",
      "updated_at",
      "detail",
    ],
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("application_admin_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
} as TabDefinition;

function App() {
  const TERMS = getAllTerms();
  const { mode } = useParams();
  const {
    initialized,
    templateSummaries,
    runningFormsTotalCount,
    runningFormsHasMore,
    runningForms,
    fetchedRunningFormsPage,
    reviewingFormsLength,
    rejectedFormsLength,
    rejectedRepresentativeFormsLength,
    relatedAccounts,
  } = useAppSelector(selectApplyState);
  const { user, policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = useMemo(() => {
    return getQuery(search);
  }, [search]);
  const [state, $state] = useState({
    activeTabKey: (() => {
      switch (mode) {
        case "applicant":
        case "processor":
        case "admin":
        case "representative_applicant":
        case "representative_create":
          return mode;
        case "create":
        default:
          return "create";
      }
    })(),
    createdAtStartDateOfTerm: dayjs().startOf("day").add(-2, "month"),
    createdAtEndDateOfTerm: dayjs().endOf("day"),
    appliedAtStartDateOfTerm: dayjs().startOf("day").add(-2, "month"),
    appliedAtEndDateOfTerm: dayjs().endOf("day"),
    completedAtStartDateOfTerm: dayjs().startOf("day").add(-2, "month"),
    completedAtEndDateOfTerm: dayjs().endOf("day"),
    updatedAtStartDateOfTerm: dayjs().startOf("day").add(-2, "month"),
    updatedAtEndDateOfTerm: dayjs().endOf("day"),
    keyword: "",
    searchWithCreatedAt: false,
    searchWithAppliedAt: false,
    searchWithCompletedAt: false,
    searchWithUpdatedAt: false,
    statuses: [] as string[],
    stepStatuses: [] as string[],
    searchWithApplicant: false,
    applicant: undefined as number | undefined,
    checkedIds: [] as string[],
    activeModal: "",
  });

  const [templateTerms, selfTemplateSummaries, representativeTemplateSummaries] = useMemo(() => {
    const _terms = templateSummaries.reduce((prev, current) => {
      return { ...prev, [current.application_type]: current.name };
    }, {}) as { [applicationType: string]: string };
    const _activeTemplateSummaries = templateSummaries.filter(
      ({ is_active, template_status }) => is_active && template_status !== "obsolete"
    );
    const selfTemplateSummaries = _activeTemplateSummaries.filter(({ applicant_type }) =>
      ["all", "self"].includes(applicant_type)
    );
    const representativeTemplateSummaries = _activeTemplateSummaries.filter(
      ({ applicant_type, can_represent }) => ["all", "representative"].includes(applicant_type) && can_represent
    );
    return [_terms, selfTemplateSummaries, representativeTemplateSummaries];
  }, [templateSummaries]);

  const tabPanes = useMemo(() => {
    return Object.keys(tabPaneDefinitions)
      .filter((key) => tabPaneDefinitions[key].isAvailable(policies, roles, representativeTemplateSummaries))
      .reduce((prev, current) => {
        return { ...prev, [current]: tabPaneDefinitions[current] };
      }, {} as TabDefinition);
  }, [roles, policies, representativeTemplateSummaries]);

  useEffect(() => {
    dispatch(getTodoFormCounts());
    if (["applicant", "representative_applicant", "processor", "admin"].includes(state.activeTabKey)) {
      const applicant = query.applicant && !Number.isNaN(+query.applicant) ? +query.applicant : undefined;
      const checkedStatuses = tabPanes[state.activeTabKey]?.defaultStatuses ?? [];
      $state({
        ...state,
        statuses: applicant ? [] : checkedStatuses,
        searchWithApplicant: !!applicant,
        applicant,
      });
    } else {
      dispatch(unselectRunningForms());
    }
    dispatch(setLoading(true));
    dispatch(getApplyTemplateSummaries()).then(() => {
      dispatch(getRequiredTemplates());
      dispatch(setLoading(false));
    });
  }, []);

  useEffect(() => {
    // 申請者名を表示するためのリクエスト
    if (["representative_applicant", "processor", "admin"].includes(state.activeTabKey) && runningForms.length > 0) {
      const applicantIds = runningForms.map((f) => f.applicant_id);
      const representativeApplicantIds = runningForms
        .map((f) => f.representative_applicant_id)
        .filter((id) => id) as number[];
      const id__in = [...applicantIds, ...representativeApplicantIds].filter(
        (id) => !relatedAccounts.some((a) => a.id === id)
      );
      // 新規で取得しなくてはならない場合のみリクエスト
      if (id__in.length > 0) {
        dispatch(getRelatedAccounts({ id__in }));
      }
    }
  }, [runningForms]);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `/_/apply/${state.activeTabKey}/${
        state.searchWithApplicant && state.applicant ? `?applicant=${state.applicant}` : ""
      }`
    );
  }, [state.searchWithApplicant, state.applicant]);

  const searchRunningForms = ({ page = 1 }: { page?: number }) => {
    // タブの切り替わり・検索条件の変更で一覧の内容をリクエスト
    if (state.activeTabKey.endsWith("create")) return;
    const params = createSearchParams();
    const isAdmin = state.activeTabKey === "admin";
    if (!isAdmin) {
      params["request_type"] = state.activeTabKey;
    }
    if (state.stepStatuses.length) {
      params["steps__elemMatch"] = { ["status__in"]: state.stepStatuses };
    }
    params["sort_by"] = state.activeTabKey.endsWith("applicant") ? '[{ "created_at": -1 }]' : '[{ "applied_at": -1 }]';
    dispatch(getApplyForms({ params, isAdmin, page: page }));
  };

  useEffect(() => {
    searchRunningForms({});
  }, [
    state.createdAtStartDateOfTerm,
    state.createdAtEndDateOfTerm,
    state.appliedAtStartDateOfTerm,
    state.appliedAtEndDateOfTerm,
    state.completedAtStartDateOfTerm,
    state.completedAtEndDateOfTerm,
    state.updatedAtStartDateOfTerm,
    state.updatedAtEndDateOfTerm,
    state.statuses,
    state.stepStatuses,
    state.searchWithCreatedAt,
    state.searchWithAppliedAt,
    state.searchWithCompletedAt,
    state.searchWithUpdatedAt,
    state.searchWithApplicant,
    state.applicant,
  ]);

  const alertMessages = useMemo(() => {
    const alertMessages = {} as { [tabKey: string]: string };
    if (rejectedFormsLength > 0) {
      alertMessages["applicant"] = `差し戻されている申請書が ${rejectedFormsLength} 件あります。`;
    }
    if (reviewingFormsLength > 0) {
      alertMessages["processor"] = `承認待ちの申請書が ${reviewingFormsLength} 件あります。`;
    }
    return alertMessages;
  }, [reviewingFormsLength, rejectedFormsLength]);

  const notify = async () => {
    if (!state.checkedIds) return $state({ ...state, activeModal: "" });
    await dispatch(notifyApplication({ id__in: state.checkedIds }));
    $state({ ...state, activeModal: "" });
  };

  const createSearchParams = () => {
    const params = {} as { [key: string]: any };
    if (state.statuses.length) {
      params["status__in"] = state.statuses;
    }
    if (state.searchWithApplicant && state.applicant !== undefined) {
      params["applicant"] = state.applicant;
    }
    if (state.searchWithCreatedAt) {
      params["created_at__range"] = [state.createdAtStartDateOfTerm.valueOf(), state.createdAtEndDateOfTerm.valueOf()];
    }
    if (state.searchWithAppliedAt) {
      params["applied_at__range"] = [state.appliedAtStartDateOfTerm.valueOf(), state.appliedAtEndDateOfTerm.valueOf()];
    }
    if (state.searchWithCompletedAt) {
      params["completed_at__range"] = [
        state.completedAtStartDateOfTerm.valueOf(),
        state.completedAtEndDateOfTerm.valueOf(),
      ];
    }
    if (state.searchWithUpdatedAt) {
      params["updated_at__range"] = [state.updatedAtStartDateOfTerm.valueOf(), state.updatedAtEndDateOfTerm.valueOf()];
    }
    return params;
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"apply"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">申請書</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          {initialized ? (
            <Container>
              <Row>
                <Tab.Container
                  onSelect={(key) => {
                    let next = { ...state };
                    if (key) next = { ...next, activeTabKey: key };
                    if (key && !key.endsWith("create")) {
                      const statuses = tabPanes[key].defaultStatuses;
                      next = {
                        ...next,
                        statuses,
                        searchWithCreatedAt: false,
                        searchWithAppliedAt: false,
                        searchWithCompletedAt: false,
                        searchWithUpdatedAt: false,
                        stepStatuses: [],
                        searchWithApplicant: false,
                      };
                      window.history.replaceState({}, "", `/_/apply/${key}/`);
                    } else {
                      dispatch(unselectRunningForms());
                      if (key !== "create") window.history.replaceState({}, "", `/_/apply/${key}`);
                      else window.history.replaceState({}, "", `/_/apply/`);
                    }
                    $state(next);
                  }}
                  activeKey={state.activeTabKey}
                >
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="create">
                        <span>{TERMS.APPLICATION_TAB_create}</span>
                      </Nav.Link>
                    </Nav.Item>
                    {representativeTemplateSummaries.length > 0 && (
                      <Nav.Item>
                        <Nav.Link eventKey="representative_create">
                          <span>{TERMS.APPLICATION_TAB_representative_create}</span>
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {Object.keys(tabPanes).map((eventKey) => (
                      <Nav.Item key={eventKey}>
                        <Nav.Link eventKey={eventKey}>
                          <span>
                            {TERMS[`APPLICATION_TAB_${eventKey}`]}
                            {eventKey === "applicant" && rejectedFormsLength > 0 && (
                              <div className="Badge--count mx-1">{rejectedFormsLength}</div>
                            )}
                            {eventKey === "representative_applicant" && rejectedRepresentativeFormsLength > 0 && (
                              <div className="Badge--count mx-1">{rejectedRepresentativeFormsLength}</div>
                            )}
                            {eventKey === "processor" && reviewingFormsLength > 0 && (
                              <div className="Badge--count mx-1">{reviewingFormsLength}</div>
                            )}
                          </span>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane title={TERMS.APPLICATION_TAB_create} key="create" eventKey="create">
                      <Container className="mt-4">
                        {/* 社内展開用に一時コメントアウト 
                        <Row>
                          <Col>
                            <h2 className="Headline--section mb-2">申請が求められている申請書</h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {requiredTemplateSummaries.length > 0 ? (
                              <Table
                                col={[
                                  {
                                    name: "申請書名",
                                  },
                                ]}
                                row={requiredTemplateSummaries.map((f) => {
                                  return {
                                    data: [f.title],
                                    link: `/_/apply/create/${f.id}/`,
                                  };
                                })}
                              />
                            ) : (
                              <Alert variant={"info"}>該当する申請書はありません。</Alert>
                            )}
                          </Col>
                        </Row> */}
                        {selfTemplateSummaries.length === 0 ? (
                          <Row className="mt-4">
                            <Col>選択可能な申請書がありません。</Col>
                          </Row>
                        ) : (
                          <>
                            <Row className="mt-4">
                              <Col>
                                <h2 className="Headline--section mb-2">内容から申請書を選ぶ</h2>
                              </Col>
                            </Row>
                            <Row>
                              {APPLICATION_CATEGORIES.map((category) => {
                                const applicationTypes = selfTemplateSummaries.filter(({ categories }) =>
                                  categories.includes(category)
                                );
                                // カテゴリに当てはまる申請書がなければスルー
                                if (applicationTypes.length === 0) return null;
                                return (
                                  <Col md={6} key={category}>
                                    <Card className="my-1">
                                      <Card.Header className="--bold">
                                        {TERMS[`APPLICATION_CATEGORY_${category}`]}
                                      </Card.Header>
                                      <Card.Body>
                                        <Row>
                                          {applicationTypes.map(({ application_type, name }, i) => {
                                            return (
                                              <Col md={6} key={`${application_type}_${i}`}>
                                                <p>
                                                  <Link to={`/_/apply/create/${application_type}/`}>{name}</Link>
                                                </p>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                );
                              })}
                            </Row>
                            <Row className="mt-4">
                              <Col>
                                <h2 className="Headline--section mb-2">一覧から申請書を選ぶ</h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Table
                                  col={[
                                    {
                                      name: "申請書名",
                                      filterable: true,
                                    },
                                  ]}
                                  row={selfTemplateSummaries.map(({ application_type, name }) => {
                                    return {
                                      data: [name],
                                      link: `/_/apply/create/${application_type}/`,
                                    };
                                  })}
                                  usePagenation={true}
                                  useKeywordFilter={true}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Container>
                    </Tab.Pane>
                    <Tab.Pane
                      title={TERMS.APPLICATION_TAB_representative_create}
                      key="representative_create"
                      eventKey="representative_create"
                    >
                      <Container className="mt-4">
                        <Row>
                          <Col>
                            <Table
                              col={[
                                {
                                  name: "申請書名",
                                  filterable: true,
                                },
                              ]}
                              row={representativeTemplateSummaries.map(({ application_type, name }) => {
                                return {
                                  data: [name],
                                  link: `/_/apply/create/${application_type}/?is_representative=1`,
                                };
                              })}
                              usePagenation={true}
                              useKeywordFilter={true}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Tab.Pane>
                    {Object.keys(tabPanes).map((eventKey) => {
                      if (eventKey !== state.activeTabKey) return null;
                      const { actionRequiredConditions, listHeaderKeys } = tabPanes[eventKey];
                      return (
                        <Tab.Pane key={eventKey} eventKey={eventKey} data-testid={eventKey}>
                          <Container className="mt-4">
                            {alertMessages[eventKey] && (
                              <Alert variant="warning">
                                {alertMessages[eventKey]}
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() => {
                                    $state({
                                      ...state,
                                      ...actionRequiredConditions,
                                      searchWithCreatedAt: false,
                                      searchWithAppliedAt: false,
                                      searchWithCompletedAt: false,
                                      searchWithUpdatedAt: false,
                                    });
                                  }}
                                >
                                  確認が必要な申請のみ表示する
                                </Button>
                              </Alert>
                            )}
                            <Row className="mt-2">
                              <Col md={2}>
                                <div className="--bold pt-md-3">絞込条件</div>
                              </Col>
                              <Col md={10}>
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      {(() => {
                                        const conditions = [];
                                        if (state.searchWithApplicant && state.applicant) conditions.push("申請者");
                                        if (state.statuses.length) conditions.push("ステータス");
                                        if (state.stepStatuses.length) conditions.push("承認ステータス");
                                        if (["applicant", "admin"].includes(eventKey) && state.searchWithCreatedAt)
                                          conditions.push("作成日");
                                        if (state.searchWithAppliedAt) conditions.push("申請日");
                                        if (state.searchWithCompletedAt) conditions.push("完了日");
                                        if (state.searchWithUpdatedAt) conditions.push("最終更新日");
                                        return conditions.length ? conditions.join(", ") : "設定なし";
                                      })()}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {["processor", "admin"].includes(eventKey) && (
                                        <Row className="mb-1">
                                          <Col md={2} className="--bold d-flex align-items-center">
                                            申請者
                                          </Col>
                                          <Col md={10}>
                                            <Form.Check
                                              type="switch"
                                              label="申請者で絞り込む"
                                              id="searchWithApplicant"
                                              checked={state.searchWithApplicant}
                                              onChange={() => {
                                                const next = !state.searchWithApplicant;
                                                $state({
                                                  ...state,
                                                  searchWithApplicant: next,
                                                  applicant: next ? state.applicant : undefined,
                                                });
                                              }}
                                            />
                                            {state.searchWithApplicant && (
                                              <Row className="mb-1">
                                                <Col>
                                                  <MemberSelector
                                                    selectedAccountId={state.applicant}
                                                    onSelectedAccountChange={(accountId) => {
                                                      $state({ ...state, applicant: accountId });
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            )}
                                          </Col>
                                        </Row>
                                      )}
                                      <Row className="mb-1">
                                        <Col md={2} className="--bold">
                                          ステータス
                                        </Col>
                                        <Col md={10}>
                                          {APPLICATION_STATUSES.filter(
                                            (s) => !(eventKey === "processor" && s === "todo")
                                          ).map((s) => {
                                            return (
                                              <Form.Check
                                                inline
                                                type="checkbox"
                                                key={`status_${s}`}
                                                label={TERMS[`APPLICATION_STATUS_${s}`]}
                                                id={`status_${s}`}
                                                checked={state.statuses.includes(s)}
                                                onChange={() => {
                                                  const next = state.statuses.some((_s) => _s === s)
                                                    ? state.statuses.filter((_s) => _s !== s)
                                                    : [...state.statuses, s];
                                                  $state({ ...state, statuses: next });
                                                }}
                                              />
                                            );
                                          })}
                                        </Col>
                                      </Row>
                                      {eventKey === "processor" && (
                                        <Row className="mb-1">
                                          <Col md={2} className="--bold">
                                            承認ステータス
                                          </Col>
                                          <Col md={10}>
                                            {APPLICATION_STEP_STATUSES.filter((s) => s !== "todo").map((s) => {
                                              return (
                                                <Form.Check
                                                  inline
                                                  type="checkbox"
                                                  key={`stepStatus_${s}`}
                                                  label={TERMS[`APPLICATION_STEP_STATUS_${s}`]}
                                                  id={`stepStatus_${s}`}
                                                  checked={state.stepStatuses.includes(s)}
                                                  onChange={() => {
                                                    const next = state.stepStatuses.some((_s) => _s === s)
                                                      ? state.stepStatuses.filter((_s) => _s !== s)
                                                      : [...state.stepStatuses, s];
                                                    $state({ ...state, stepStatuses: next });
                                                  }}
                                                />
                                              );
                                            })}
                                          </Col>
                                        </Row>
                                      )}
                                      {["applicant", "admin"].includes(eventKey) && (
                                        <Row className="mb-1">
                                          <Col md={2} className="--bold">
                                            作成日
                                          </Col>
                                          <Col md={10}>
                                            <Form.Check
                                              type="switch"
                                              label="作成日で絞り込む"
                                              id="searchWithCreatedAt"
                                              checked={state.searchWithCreatedAt}
                                              onChange={() => {
                                                $state({
                                                  ...state,
                                                  searchWithCreatedAt: !state.searchWithCreatedAt,
                                                });
                                              }}
                                            />
                                            {state.searchWithCreatedAt && (
                                              <Fragment>
                                                <Row className="mb-1">
                                                  <Col md="5">
                                                    <SnapshotTimeSelector
                                                      selectedPointDate={state.createdAtStartDateOfTerm}
                                                      onChange={({ selectedPointDate }: onChangeOption) => {
                                                        if (!selectedPointDate) return;
                                                        $state({
                                                          ...state,
                                                          createdAtStartDateOfTerm: selectedPointDate.startOf("day"),
                                                        });
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col md="1" className="--text-align-center pt-md-3">
                                                    ～
                                                  </Col>
                                                  <Col md="5">
                                                    <SnapshotTimeSelector
                                                      selectedPointDate={state.createdAtEndDateOfTerm}
                                                      onChange={({ selectedPointDate }: onChangeOption) => {
                                                        if (!selectedPointDate) return;
                                                        $state({
                                                          ...state,
                                                          createdAtEndDateOfTerm: selectedPointDate.endOf("day"),
                                                        });
                                                      }}
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                  <Col className="d-flex">
                                                    <Button
                                                      variant="link"
                                                      className="--font-s"
                                                      onClick={() => {
                                                        $state({
                                                          ...state,
                                                          createdAtStartDateOfTerm: dayjs()
                                                            .startOf("day")
                                                            .add(-2, "month"),
                                                          createdAtEndDateOfTerm: dayjs().endOf("day"),
                                                        });
                                                      }}
                                                    >
                                                      直近２か月を選択する
                                                    </Button>
                                                    <Button
                                                      variant="link"
                                                      className="--font-s mx-1"
                                                      onClick={() => {
                                                        $state({
                                                          ...state,
                                                          createdAtStartDateOfTerm: dayjs()
                                                            .startOf("day")
                                                            .add(-1, "year"),
                                                          createdAtEndDateOfTerm: dayjs().endOf("day"),
                                                        });
                                                      }}
                                                    >
                                                      直近１年を選択する
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </Fragment>
                                            )}
                                          </Col>
                                        </Row>
                                      )}
                                      <Row className="mb-1">
                                        <Col md={2} className="--bold">
                                          申請日
                                        </Col>
                                        <Col md={10}>
                                          <Form.Check
                                            type="switch"
                                            label="申請日で絞り込む"
                                            id="searchWithAppliedAt"
                                            checked={state.searchWithAppliedAt}
                                            onChange={() => {
                                              $state({ ...state, searchWithAppliedAt: !state.searchWithAppliedAt });
                                            }}
                                          />
                                          {state.searchWithAppliedAt && (
                                            <Fragment>
                                              <Row className="mb-1">
                                                <Col md="5">
                                                  <SnapshotTimeSelector
                                                    selectedPointDate={state.appliedAtStartDateOfTerm}
                                                    onChange={({ selectedPointDate }: onChangeOption) => {
                                                      if (!selectedPointDate) return;
                                                      $state({
                                                        ...state,
                                                        appliedAtStartDateOfTerm: selectedPointDate.startOf("day"),
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                                <Col md="1" className="--text-align-center pt-md-3">
                                                  ～
                                                </Col>
                                                <Col md="5">
                                                  <SnapshotTimeSelector
                                                    selectedPointDate={state.appliedAtEndDateOfTerm}
                                                    onChange={({ selectedPointDate }: onChangeOption) => {
                                                      if (!selectedPointDate) return;
                                                      $state({
                                                        ...state,
                                                        appliedAtEndDateOfTerm: selectedPointDate.endOf("day"),
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                              <Row className="mb-2">
                                                <Col className="d-flex">
                                                  <Button
                                                    variant="link"
                                                    className="--font-s"
                                                    onClick={() => {
                                                      $state({
                                                        ...state,
                                                        appliedAtStartDateOfTerm: dayjs()
                                                          .startOf("day")
                                                          .add(-2, "month"),
                                                        appliedAtEndDateOfTerm: dayjs().endOf("day"),
                                                      });
                                                    }}
                                                  >
                                                    直近２か月を選択する
                                                  </Button>
                                                  <Button
                                                    variant="link"
                                                    className="--font-s mx-1"
                                                    onClick={() => {
                                                      $state({
                                                        ...state,
                                                        appliedAtStartDateOfTerm: dayjs()
                                                          .startOf("day")
                                                          .add(-1, "year"),
                                                        appliedAtEndDateOfTerm: dayjs().endOf("day"),
                                                      });
                                                    }}
                                                  >
                                                    直近１年を選択する
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </Fragment>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="mb-1">
                                        <Col md={2} className="--bold">
                                          完了日
                                        </Col>
                                        <Col md={10}>
                                          <Form.Check
                                            type="switch"
                                            label="完了日で絞り込む"
                                            id="searchWithCompletedAt"
                                            checked={state.searchWithCompletedAt}
                                            onChange={() => {
                                              $state({
                                                ...state,
                                                searchWithCompletedAt: !state.searchWithCompletedAt,
                                              });
                                            }}
                                          />
                                          {state.searchWithCompletedAt && (
                                            <Fragment>
                                              <Row className="mb-1">
                                                <Col md="5">
                                                  <SnapshotTimeSelector
                                                    selectedPointDate={state.completedAtStartDateOfTerm}
                                                    onChange={({ selectedPointDate }: onChangeOption) => {
                                                      if (!selectedPointDate) return;
                                                      $state({
                                                        ...state,
                                                        completedAtStartDateOfTerm: selectedPointDate.startOf("day"),
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                                <Col md="1" className="--text-align-center pt-md-3">
                                                  ～
                                                </Col>
                                                <Col md="5">
                                                  <SnapshotTimeSelector
                                                    selectedPointDate={state.completedAtEndDateOfTerm}
                                                    onChange={({ selectedPointDate }: onChangeOption) => {
                                                      if (!selectedPointDate) return;
                                                      $state({
                                                        ...state,
                                                        completedAtEndDateOfTerm: selectedPointDate.endOf("day"),
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                              <Row className="mb-2">
                                                <Col className="d-flex">
                                                  <Button
                                                    variant="link"
                                                    className="--font-s"
                                                    onClick={() => {
                                                      $state({
                                                        ...state,
                                                        completedAtStartDateOfTerm: dayjs()
                                                          .startOf("day")
                                                          .add(-2, "month"),
                                                        completedAtEndDateOfTerm: dayjs().endOf("day"),
                                                      });
                                                    }}
                                                  >
                                                    直近２か月を選択する
                                                  </Button>
                                                  <Button
                                                    variant="link"
                                                    className="--font-s mx-1"
                                                    onClick={() => {
                                                      $state({
                                                        ...state,
                                                        completedAtStartDateOfTerm: dayjs()
                                                          .startOf("day")
                                                          .add(-1, "year"),
                                                        completedAtEndDateOfTerm: dayjs().endOf("day"),
                                                      });
                                                    }}
                                                  >
                                                    直近１年を選択する
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </Fragment>
                                          )}
                                        </Col>
                                      </Row>
                                      <Row className="mb-1">
                                        <Col md={2} className="--bold">
                                          最終更新日
                                        </Col>
                                        <Col md={10}>
                                          <Form.Check
                                            type="switch"
                                            label="最終更新日で絞り込む"
                                            id="searchWithUpdatedAt"
                                            checked={state.searchWithUpdatedAt}
                                            onChange={() => {
                                              $state({
                                                ...state,
                                                searchWithUpdatedAt: !state.searchWithUpdatedAt,
                                              });
                                            }}
                                          />
                                          {state.searchWithUpdatedAt && (
                                            <Fragment>
                                              <Row className="mb-1">
                                                <Col md="5">
                                                  <SnapshotTimeSelector
                                                    selectedPointDate={state.updatedAtStartDateOfTerm}
                                                    onChange={({ selectedPointDate }: onChangeOption) => {
                                                      if (!selectedPointDate) return;
                                                      $state({
                                                        ...state,
                                                        updatedAtStartDateOfTerm: selectedPointDate.startOf("day"),
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                                <Col md="1" className="--text-align-center pt-md-3">
                                                  ～
                                                </Col>
                                                <Col md="5">
                                                  <SnapshotTimeSelector
                                                    selectedPointDate={state.updatedAtEndDateOfTerm}
                                                    onChange={({ selectedPointDate }: onChangeOption) => {
                                                      if (!selectedPointDate) return;
                                                      $state({
                                                        ...state,
                                                        updatedAtEndDateOfTerm: selectedPointDate.endOf("day"),
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                              <Row className="mb-2">
                                                <Col className="d-flex">
                                                  <Button
                                                    variant="link"
                                                    className="--font-s"
                                                    onClick={() => {
                                                      $state({
                                                        ...state,
                                                        updatedAtStartDateOfTerm: dayjs()
                                                          .startOf("day")
                                                          .add(-2, "month"),
                                                        updatedAtEndDateOfTerm: dayjs().endOf("day"),
                                                      });
                                                    }}
                                                  >
                                                    直近２か月を選択する
                                                  </Button>
                                                  <Button
                                                    variant="link"
                                                    className="--font-s mx-1"
                                                    onClick={() => {
                                                      $state({
                                                        ...state,
                                                        updatedAtStartDateOfTerm: dayjs()
                                                          .startOf("day")
                                                          .add(-1, "year"),
                                                        updatedAtEndDateOfTerm: dayjs().endOf("day"),
                                                      });
                                                    }}
                                                  >
                                                    直近１年を選択する
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </Fragment>
                                          )}
                                        </Col>
                                      </Row>
                                      {/* <Row className="mb-1">
                                      <Col md={2} className="--bold">
                                        キーワード
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          type="text"
                                          id={`keyword`}
                                          placeholder="（工事中）"
                                          value={state.keyword}
                                          onChange={(e) => {
                                            $state({ ...state, keyword: e.target.value });
                                          }}
                                        />
                                      </Col>
                                    </Row> */}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Col>
                            </Row>
                            <Row className="my-2">
                              <Col>
                                <Button
                                  className="mx-2"
                                  variant="outline-secondary"
                                  hidden={state.activeTabKey !== "admin"}
                                  disabled={runningForms.length === 0}
                                  onClick={() => {
                                    const params = createSearchParams();
                                    dispatch(singleDownloadApplyForms({ params: params }));
                                  }}
                                >
                                  ダウンロード
                                </Button>
                                <Button
                                  className="mx-2"
                                  variant="outline-secondary"
                                  onClick={() => {
                                    $state({
                                      ...state,
                                      searchWithCreatedAt: false,
                                      searchWithAppliedAt: false,
                                      searchWithCompletedAt: false,
                                      searchWithUpdatedAt: false,
                                      statuses: [],
                                      stepStatuses: [],
                                      searchWithApplicant: false,
                                      applicant: undefined,
                                    });
                                  }}
                                >
                                  絞込条件をクリア
                                </Button>
                                <Button
                                  className="mx-2 float-end"
                                  variant="outline-primary"
                                  hidden={state.activeTabKey !== "admin"}
                                  disabled={state.checkedIds.length === 0}
                                  onClick={() => {
                                    $state({ ...state, activeModal: "before_notify" });
                                  }}
                                >
                                  選択した申請書のリマインド通知をする
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {runningForms.length > 0 ? (
                                  <>
                                    <Table
                                      col={listHeaderKeys.map((key) => ({
                                        name: TERMS[`APPLICATION_LIST_HEADER_${key}`],
                                        width: (() => {
                                          switch (key) {
                                            case "applicant":
                                              return 160;
                                            case "application_type":
                                              return 160;
                                            case "detail":
                                              return 90;
                                            default:
                                              return 120;
                                          }
                                        })(),
                                      }))}
                                      row={runningForms.map((f) => {
                                        return {
                                          id: f.id,
                                          data: listHeaderKeys.map((key) => {
                                            switch (key) {
                                              case "status":
                                                return (
                                                  <span
                                                    className={classNames({
                                                      "Badge--waiting": ["todo"].includes(f.status),
                                                      "Badge--running": ["running", "copied"].includes(f.status),
                                                      "Badge--danger": ["rejected", "rejected_to_applicant"].includes(
                                                        f.status
                                                      ),
                                                      "Badge--ok": f.status === "done",
                                                      "mx-1": true,
                                                    })}
                                                  >
                                                    {TERMS[`APPLICATION_STATUS_${f.status}`]}
                                                  </span>
                                                );
                                              case "applicant":
                                                const applicantName =
                                                  relatedAccounts.find(({ id }) => id === f.applicant_id)?.label ??
                                                  "DELETED";
                                                const isDisplayRepresentative =
                                                  f.representative_applicant_id &&
                                                  ["processor", "admin"].includes(state.activeTabKey);
                                                if (!isDisplayRepresentative) return applicantName;
                                                const representativeApplicantName =
                                                  relatedAccounts.find(({ id }) => id === f.representative_applicant_id)
                                                    ?.label ?? "DELETED";
                                                return (
                                                  <>
                                                    {applicantName}
                                                    <br />
                                                    （代理:{representativeApplicantName}）
                                                  </>
                                                );
                                              case "application_type":
                                                return templateTerms[f.template_type];
                                              case "created_at":
                                                return f.created_at ? dayjs(f.created_at).format("YYYY/MM/DD") : "---";
                                              case "applied_at":
                                                return f.applied_at ? dayjs(f.applied_at).format("YYYY/MM/DD") : "---";
                                              case "completed_at":
                                                return f.completed_at
                                                  ? dayjs(f.completed_at).format("YYYY/MM/DD")
                                                  : "---";
                                              case "updated_at":
                                                return f.updated_at ? dayjs(f.updated_at).format("YYYY/MM/DD") : "---";
                                              case "role":
                                                const mySteps = f.steps
                                                  .filter((s) => s.processors.some((p) => p.id === user.id))
                                                  .map((s) => s.name)
                                                  .join("/");
                                                return mySteps ?? "--";
                                              case "detail":
                                                return (
                                                  <Link to={`/_/apply/edit/${f.applicant_id}/${f.id}/`}>確認する</Link>
                                                );
                                              default:
                                                if (!(key in f)) return "";
                                                const _key = key as keyof RunningApplyForm;
                                                return f[_key];
                                            }
                                          }),
                                        };
                                      })}
                                      checkedIds={eventKey === "admin" ? state.checkedIds : undefined}
                                      onCheck={
                                        eventKey === "admin"
                                          ? (next) => {
                                              const checkedIds = next as string[];
                                              $state({ ...state, checkedIds });
                                            }
                                          : undefined
                                      }
                                    />
                                    {
                                      <Button
                                        variant="outline-secondary"
                                        className="mt-2 float-end"
                                        disabled={!runningFormsHasMore}
                                        onClick={() => {
                                          searchRunningForms({ page: fetchedRunningFormsPage + 1 });
                                        }}
                                      >
                                        さらに表示（全 {runningFormsTotalCount} 件中 {runningForms.length} 件表示中）
                                      </Button>
                                    }
                                  </>
                                ) : (
                                  <Alert variant={"info"}>該当する申請書はありません。</Alert>
                                )}
                              </Col>
                            </Row>
                          </Container>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Tab.Container>
              </Row>
              <ModalDialog
                show={state.activeModal === "before_notify"}
                message="選択した申請書のリマインド通知を行います。完了した申請書は通知対象外です。よろしいですか？"
                onConfirm={notify}
                onCancel={() => {
                  $state({ ...state, activeModal: "" });
                }}
              />
            </Container>
          ) : (
            <Container>
              <Row>
                <Col>申請書データのダウンロード中・・・</Col>
              </Row>
            </Container>
          )}
        </main>
      </div>
    </div>
  );
}

export default App;
