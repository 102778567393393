const data = {
  category: "accessible_section",
  table: "accessible_section",
  subFields: {
    accessible_group_type: {},
    section_code: {},
    position_code: {},
    accessibility_type: {},
  },
};
export default data;
