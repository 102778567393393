import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getMailSetting,
  getMailHistories,
  selectMailSettingState,
  unselectMailSetting,
  unselectMailHistories,
} from "./mailSettingSlice";
import { SEND_RESULT_STATUS, SearchCondition, MailHistory } from "./mailSettingValues";
import { selectCurrentCompany } from "../login/userSlice";
import Sidebar from "../../component/Sidebar";
import Table from "../../component/Table";
import { Container, Row, Col, Accordion, Alert, Form } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { mailHistories, selectedMailSetting } = useAppSelector(selectMailSettingState);
  const current_company = useAppSelector(selectCurrentCompany);
  const [state, $state] = useState({
    timeoutId: null as number | null,
    inputKeyword: "", // 入力キーワード
    searchKeyword: "", // 検索用キーワード（入力し終わって500ms経過）
    statuses: [] as number[],
  });

  useEffect(() => {
    if (id) {
      dispatch(getMailSetting({ id })).then((res) => {
        // 存在しないidの場合、メール送信設定一覧へ遷移させる
        if (!res.payload) navigate("/_/mail_setting/");
      });
      dispatch(getMailHistories({ id }));
    }
  }, [id]);

  useEffect(() => {
    updateMailHistories();
  }, [state.searchKeyword, state.statuses]);

  useEffect(() => {
    return () => {
      dispatch(unselectMailSetting());
      dispatch(unselectMailHistories());
    };
  }, []);

  const userTimeZone = useMemo(() => {
    return current_company.timezone ?? "Asia/Tokyo";
  }, [current_company]);

  const updateMailHistories = async () => {
    if (!id) return;
    const conditions = {} as SearchCondition;
    if (state.searchKeyword) {
      conditions["keyword"] = state.searchKeyword;
    }
    if (state.statuses.length > 0) {
      conditions["statuses"] = state.statuses;
    }
    dispatch(getMailHistories({ id, conditions }));
  };

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"mail_setting"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">{selectedMailSetting.name}の送信履歴一覧</h1>
        <div className="Grouping mt-3 bg-white">
          <Container>
            <Row>
              <Col>
                <Row className="mb-4">
                  <Col md="2">
                    <div className="--bold pt-md-3">絞込条件</div>
                  </Col>
                  <Col md="10">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          {(() => {
                            const conditions = [];
                            if (state.searchKeyword) conditions.push("送信対象");
                            if (state.statuses.length) conditions.push("ステータス");
                            return conditions.length ? conditions.join(", ") : "設定なし";
                          })()}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form.Control
                            type="text"
                            id="search"
                            value={state.inputKeyword}
                            className="mb-3"
                            placeholder="送信対象で絞り込む"
                            onChange={(e) => {
                              const keyword = e.target.value;
                              // 打ち終わって500ms後に検索のリクエストをする
                              if (state.timeoutId) {
                                window.clearTimeout(state.timeoutId);
                              }
                              const timeoutId = window.setTimeout(() => {
                                $state({ ...state, timeoutId: null, searchKeyword: keyword, inputKeyword: keyword });
                              }, 500);
                              $state({ ...state, timeoutId, inputKeyword: keyword });
                            }}
                          />
                          <div className="--bold mb-1">ステータス</div>
                          {SEND_RESULT_STATUS.map((s) => {
                            return (
                              <Form.Check
                                inline
                                type="checkbox"
                                key={`status_${s.value}`}
                                label={s.name}
                                id={`status_${s.value}`}
                                checked={state.statuses.includes(s.value)}
                                className="mx-2"
                                onChange={() => {
                                  const next = state.statuses.some((_s) => _s === s.value)
                                    ? state.statuses.filter((_s) => _s !== s.value)
                                    : [...state.statuses, s.value];
                                  $state({ ...state, statuses: next });
                                }}
                              />
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
                {mailHistories.length > 0 ? (
                  <Table
                    col={[
                      {
                        name: "送信対象",
                        width: 350,
                      },
                      {
                        name: "件名",
                        width: 300,
                      },
                      {
                        name: "ステータス",
                        width: 200,
                      },
                      {
                        name: "送信日時",
                        width: 300,
                      },
                    ]}
                    row={mailHistories.map((m: MailHistory) => {
                      return {
                        id: m.id,
                        key: m.id,
                        data: [
                          m.receiver,
                          m.subject,
                          SEND_RESULT_STATUS.find((s) => s.value === m.status)?.name,
                          `${dayjs(m.created_at).tz(userTimeZone).format("YYYY-MM-DD HH:mm")} (${userTimeZone})`,
                        ],
                      };
                    })}
                  />
                ) : (
                  <Alert variant={"info"}>該当する送信履歴はありません。</Alert>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;
