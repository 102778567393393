const data = {
  category: "spouse",
  table: "spouse",
  subFields: {
    spouse_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    valid_to_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    spouse_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    spouse_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    gender_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relationship_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    birthday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    age_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    marriage_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    postcode_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    address_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    occupation_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    residence_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_living_together_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    income_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    tax_dependent_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    dependent_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    tax_dependent_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    loss_of_tax_dependent_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    disability_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    relation_file_id_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    insurance_dependent_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    insurance_dependent_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    insurance_dependent_on_reason_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    insurance_dependent_on_other_reason_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    loss_of_insurance_dependent_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    insurance_dependent_loss_reason_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    insurance_dependent_loss_other_reason_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    family_benefit_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    death_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      spouse_history: "配偶者履歴",
    },
  },
};

export default data;
