import { useState, useEffect, useMemo, Fragment, useCallback } from "react";
import { Container, Row, Col, Alert, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import MyNumberRow from "./MyNumberRow";
import MyNumberLogsModal from "./MyNumberLogsModal";
import {
  getMyNumber,
  getMyNumberViews,
  selectMyNumberState,
  clearMyNumberValue,
  postMyNumber,
  revertMyNumberDeletable,
  getStatusMessages,
} from "./myNumberSlice";
import Sidebar from "../../component/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { MY_NUMBER_DISPLAY_MS, MyNumber, MyNumberView } from "./myNumberValues";
import { selectUserState } from "../login/userSlice";
import { setLoading } from "../notification/notificationSlice";
import ModalDialog from "../../component/ModalDialog";

function MyNumberDetailView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountId } = useParams();
  const { myNumberViews, myNumberValue, statusMessages } = useAppSelector(selectMyNumberState);
  const { user } = useAppSelector(selectUserState);

  const [displayRequested, $displayRequested] = useState({
    myNumberId: "",
    timeoutId: null as number | null,
  });
  const [isLogModalActive, $isLogModalActive] = useState(false);
  const [targetMyNumber, $targetMyNumber] = useState<MyNumber | undefined>(undefined);
  const [targetAccountName, $targetAccountName] = useState("");
  const [revertModal, $revertModal] = useState({
    isOpen: false,
    targetMyNumberId: "",
    targetName: "",
  });
  const [showOldFamily, $showOldFamily] = useState(false);

  const isAdmin = useMemo(() => {
    return user.role === "admin";
  }, [user]);

  const targetAccountId = useMemo(() => {
    if (isAdmin) return accountId ? +accountId : 0;
    return user.id;
  }, [accountId, user, isAdmin]);

  useEffect(() => {
    return () => {
      dispatch(clearMyNumberValue());
    };
  }, []);

  const resetViews = useCallback(() => {
    dispatch(setLoading(true));
    dispatch(getMyNumberViews({ accountId: targetAccountId })).then((res: any) => {
      const views = res.payload.myNumberViews as MyNumberView[];
      // 差し戻し時のメッセージを取得
      const rejectedMyNumberIds = views.filter((v) => v.status === "rejected").map((v) => v.id) as string[];
      if (rejectedMyNumberIds.length > 0) {
        dispatch(getStatusMessages({ myNumberIds: rejectedMyNumberIds })).then(() => dispatch(setLoading(false)));
      } else {
        dispatch(setLoading(false));
      }
    });
  }, [dispatch, targetAccountId]);

  useEffect(() => {
    return () => {
      if (displayRequested.timeoutId) window.clearTimeout(displayRequested.timeoutId);
    };
  }, [displayRequested]);

  useEffect(() => {
    if (targetAccountId > 0) {
      resetViews();
    }
  }, [targetAccountId]);

  const [targetAccountMyNumberViews, oldMyNumberViews] = useMemo(() => {
    // 現在表示しているアカウントで絞る
    const views = myNumberViews.filter((v) => v.account_id === targetAccountId);
    // 過去と現在のデータで分ける
    const currentViews = views.filter((v) => v.is_current);
    const oldViews = views.filter((v) => !v.is_current);
    // 現在のデータを、本人→配偶者→家族の順に並べる（レスポンスがそうなっているはずだが、念のため）
    const self = currentViews.find((v) => v.type === "self");
    if (!self) return [[], []]; // 本人のデータがないことはありえないので、ない場合は空配列を返す
    const spouse = currentViews.find((v) => v.type === "spouse");
    const dependents = currentViews
      .filter((v) => v.type === "dependent")
      .sort((a, b) => (a.dependent_serial || 0) - (b.dependent_serial || 0)); // 家族はdependent_serialで並べる
    const current = [self, spouse, ...dependents].filter((v) => v) as MyNumberView[];
    // 過去のデータを、配偶者→家族の順に並べる
    const oldSpouses = oldViews.filter((v) => v.type === "spouse");
    const oldDependents = oldViews.filter((v) => v.type === "dependent");
    const oldViewsSorted = [...oldSpouses, ...oldDependents].filter((v) => v) as MyNumberView[];
    return [current, oldViewsSorted];
  }, [myNumberViews, targetAccountId]);

  useEffect(() => {
    // マイナンバーの値は、一定時間表示された後にクリアする
    if (myNumberValue !== "") {
      const timeoutId = window.setTimeout(() => {
        dispatch(clearMyNumberValue());
        $displayRequested({ myNumberId: "", timeoutId: null });
      }, MY_NUMBER_DISPLAY_MS);
      $displayRequested({ ...displayRequested, timeoutId });
    }
  }, [myNumberValue]);

  const _getUrl = (isAdmin: boolean, id: string, accountId?: string) => {
    return isAdmin ? `/_/my_number/admin/${accountId}/edit/${id}` : `/_/my_number/user/edit/${id}`;
  };

  const handler = ({ id: myNumberId, action, name }: { id: string; action: string; name: string }) => {
    const matched = targetAccountMyNumberViews.find((v) => v.id === myNumberId);
    if (action === "browse") {
      $displayRequested({ ...displayRequested, myNumberId });
      dispatch(getMyNumber({ id: myNumberId }));
    } else if (action === "register") {
      const matched = targetAccountMyNumberViews.find((v) => v.id === myNumberId);
      if (matched?.id?.startsWith("_temp")) {
        dispatch(
          postMyNumber({
            accountId: isAdmin ? matched?.account_id : undefined,
            type: matched?.type ?? "",
            dependentSerial: matched?.dependent_serial ?? undefined,
          })
        ).then((res: any) => {
          const id = res.payload.id;
          navigate(_getUrl(isAdmin, id, accountId));
        });
        return;
      } else if (matched?.id) {
        navigate(_getUrl(isAdmin, matched?.id, accountId));
      }
    } else if (action === "review") {
      if (!matched) return;
      navigate(_getUrl(isAdmin, matched?.id, accountId));
    } else if (action === "list_logs") {
      if (!matched) return;
      $targetMyNumber(matched);
      $targetAccountName(name);
      $isLogModalActive(true);
    } else if (action === "revert_deletable") {
      if (!matched) return;
      $revertModal({ isOpen: true, targetMyNumberId: matched.id, targetName: name });
    }
  };

  const isRetired = useMemo(() => {
    return !!(accountId && myNumberViews.find((v) => v.account_id === +accountId)?.enrollment_type === "退職");
  }, [myNumberViews, accountId]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"my_number"} />
      </div>
      {targetAccountMyNumberViews.length > 0 && (
        <div className="Layout__main">
          <h1 className="Headline--page">
            マイナンバー
            {isAdmin ? ` - ${targetAccountMyNumberViews[0].employee_name}${isRetired ? "（退職）" : ""}` : ""}
          </h1>
          <main className="mt-3 py-4 px-md-2 bg-white">
            <Container>
              <Row>
                <Col>
                  <Alert variant="info">マイナンバーについての操作履歴は全て記録されます。</Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  {targetAccountMyNumberViews.map((v, i) => {
                    return (
                      <Fragment key={v.id}>
                        {v.type === "self" ? (
                          <h2 className="Headline--section-sub">本人のマイナンバー</h2>
                        ) : ["spouse", "dependent"].includes(v.type) && i === 1 ? (
                          <h2 className="Headline--section-sub">配偶者・家族のマイナンバー</h2>
                        ) : null}
                        <MyNumberRow
                          key={v.id}
                          id={v.id}
                          value={v.id === displayRequested.myNumberId ? myNumberValue : ""}
                          name={v.name}
                          status={v.status}
                          status_reason={statusMessages[v.id]}
                          canReview={isAdmin}
                          handler={handler}
                          className="mb-1"
                        />
                      </Fragment>
                    );
                  })}
                  {!targetAccountMyNumberViews.some(({ type }) => ["spouse", "dependent"].includes(type)) && (
                    <div className="mt-2 --font-s">現在、配偶者・家族は登録されていません。</div>
                  )}
                </Col>
              </Row>
              {oldMyNumberViews.length > 0 && (
                <>
                  <Form.Check
                    type="switch"
                    key="showOldFamily"
                    id="showOldFamily"
                    label="過去の配偶者・家族を表示する"
                    onChange={() => $showOldFamily(!showOldFamily)}
                    checked={showOldFamily}
                  />
                  {showOldFamily && (
                    <>
                      {oldMyNumberViews.map((v, i) => {
                        return (
                          <MyNumberRow
                            key={v.id}
                            id={v.id}
                            value={v.id === displayRequested.myNumberId ? myNumberValue : ""}
                            name={v.name}
                            status={v.status}
                            status_reason={statusMessages[v.id]}
                            canReview={isAdmin}
                            handler={handler}
                            className="mb-1"
                          />
                        );
                      })}
                    </>
                  )}
                </>
              )}
              <MyNumberLogsModal
                isActive={isLogModalActive}
                myNumber={targetMyNumber}
                name={targetAccountName}
                onClose={() => {
                  $isLogModalActive(false);
                  $targetAccountName("");
                }}
              />
              <ModalDialog
                show={revertModal.isOpen}
                onConfirm={async () => {
                  await dispatch(revertMyNumberDeletable({ id: revertModal.targetMyNumberId }));
                  resetViews();
                  $revertModal({ isOpen: false, targetMyNumberId: "", targetName: "" });
                }}
                onCancel={() => $revertModal({ isOpen: false, targetMyNumberId: "", targetName: "" })}
                message={`${revertModal.targetName} さんの削除予定を取消します。よろしいですか？`}
              />
            </Container>
          </main>
        </div>
      )}
    </div>
  );
}

export default MyNumberDetailView;
