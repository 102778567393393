import { useState, useEffect, useMemo } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ACCOUNT_SEARCH_LIMIT } from "./applyValues";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { clearFilteredAccounts, searchCompanyMembers, selectApplyState } from "./applySlice";
import { selectUserState } from "../login/userSlice";

function MemberSelector({
  selectedAccountId,
  onSelectedAccountChange,
}: {
  selectedAccountId?: number;
  onSelectedAccountChange: (id: number) => any;
}) {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);
  const [state, $state] = useState({
    inputKeyword: "",
    keyword: "",
    timeoutId: null as number | null,
  });

  const { filteredAccounts } = useAppSelector(selectApplyState);

  const selectTarget = (id: number) => {
    onSelectedAccountChange(id);
  };

  const onChangeKeyWord = (keyword: string) => {
    // 打ち終わって500ms後に検索のリクエストをするためのkeywordを更新する
    if (state.timeoutId) {
      window.clearTimeout(state.timeoutId);
    }
    const timeoutId = window.setTimeout(() => {
      $state({ ...state, timeoutId: null, keyword, inputKeyword: keyword });
    }, 500);
    $state({ ...state, timeoutId, inputKeyword: keyword });
  };

  useEffect(() => {
    if (state.keyword) {
      dispatch(
        searchCompanyMembers({
          keyword: state.keyword,
          current_company_code: user.current_company.code,
          limit: ACCOUNT_SEARCH_LIMIT,
          page: 1,
        })
      );
    } else if (selectedAccountId) {
      dispatch(
        searchCompanyMembers({ account_id: selectedAccountId, current_company_code: user.current_company.code })
      );
    } else {
      dispatch(clearFilteredAccounts());
    }
  }, [state.keyword]);

  const checkedMember = useMemo(() => {
    return filteredAccounts.find((t) => selectedAccountId === t.id);
  }, [filteredAccounts, selectedAccountId]);

  useEffect(() => {
    return () => {
      dispatch(clearFilteredAccounts());
    };
  }, []);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{checkedMember ? checkedMember.name : "（未選択）"}</Accordion.Header>
        <Accordion.Body>
          <Row className="mb-2">
            <Col md="12" className="mt-4 mt-md-0">
              <Form.Control
                type="text"
                id="search"
                value={state.inputKeyword}
                className="mb-2"
                placeholder="キーワード（名前・ログインID）で絞り込む"
                onChange={(e) => onChangeKeyWord(e.target.value)}
              />
              <Row>
                {filteredAccounts.length > 0 ? (
                  filteredAccounts.map((t) => (
                    <Col md="6" key={`filteredAccounts_${t.id}`}>
                      <Form.Check
                        checked={selectedAccountId === t.id}
                        id={`member_${t.id}`}
                        type="radio"
                        label={`${t.label}${!t.isActive ? "（無効）" : ""}`}
                        onChange={() => selectTarget(t.id)}
                      />
                    </Col>
                  ))
                ) : (
                  <Col>キーワードに該当するアカウントが存在しません。キーワードを変更してください。</Col>
                )}
                {filteredAccounts.length === ACCOUNT_SEARCH_LIMIT && (
                  <Col>{`${ACCOUNT_SEARCH_LIMIT}名以上存在します。選択したいアカウントが表示されていない場合はキーワードを変更してください。`}</Col>
                )}
              </Row>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default MemberSelector;
