export const download = ({ file, fileName, mimeType }: { file: string; fileName: string; mimeType: string }) => {
  const dataUrl = `data:${mimeType};base64,${file}`;
  fetch(dataUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", url);
      a.click();
      URL.revokeObjectURL(url);
    });
};

export default download;
