import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ReportTemplate, SearchCondition, Personal } from "./reportValues";
import { adminReportTemplates, userReportTemplates } from "./reportTemplates";
import { download } from "../../app/download";
import { call } from "../../app/api";
import dayjs from "dayjs";
import { PolicyMap } from "../login/userSlice";

const SLICE_NAME = "report";

export type ReportMetaData = { title: string; validFrom: string; downloadResource: string; accountId: number };

interface ReportState {
  adminInitialized: boolean;
  adminReportTemplates: ReportTemplate[];
  userInitialized: boolean;
  userReportTemplates: ReportTemplate[];
  personalData: Personal[];
  withholdingTaxMetaData: ReportMetaData[];
  salaryMetaData: ReportMetaData[];
  bonusMetaData: ReportMetaData[];
}

const initialState: ReportState = {
  adminInitialized: false,
  adminReportTemplates: [],
  userInitialized: false,
  userReportTemplates: [],
  personalData: [],
  withholdingTaxMetaData: [],
  salaryMetaData: [],
  bonusMetaData: [],
};

export const getAdminReportTemplates = createAsyncThunk(SLICE_NAME + "/getAdminReportTemplates", async () => {
  return adminReportTemplates;
});

export const getUserReportTemplates = createAsyncThunk(
  SLICE_NAME + "/getUserReportTemplates",
  async (policies: PolicyMap) => {
    return userReportTemplates
      .filter(({ isAvailable }) => isAvailable && isAvailable(policies))
      .map(({ id, title }) => ({ id, title }));
  }
);

export const bulkDownloadReport = createAsyncThunk(
  SLICE_NAME + "/bulkDownloadReport",
  async ({
    report,
    baseDate,
    isPdfOutput,
    conditions,
  }: {
    report: string;
    baseDate: dayjs.Dayjs;
    isPdfOutput: boolean;
    conditions: SearchCondition;
  }) => {
    await call(
      "get",
      `report_admin_manager/${report}`
    )({
      ...conditions,
      base_date: baseDate.format("YYYY-MM-DD"),
      is_pdf_output: isPdfOutput,
    });
  }
);

export const bulkDownloadReportByValidFrom = createAsyncThunk(
  SLICE_NAME + "/bulkDownloadReportByValidFrom",
  async ({
    report,
    validFrom,
    isPdfOutput,
    conditions,
  }: {
    report: string;
    validFrom: dayjs.Dayjs;
    isPdfOutput: boolean;
    conditions: SearchCondition;
  }) => {
    await call(
      "get",
      `report_admin_manager/${report}`
    )({
      ...conditions,
      valid_from: validFrom.format("YYYY-MM-DD"),
      is_pdf_output: isPdfOutput,
    });
  }
);

export const singleDownloadReportByBaseDate = createAsyncThunk(
  SLICE_NAME + "/singleDownloadReportByBaseDate",
  async ({
    resource,
    accountId,
    baseDate,
    conditions,
  }: {
    resource: string;
    accountId: number;
    baseDate: dayjs.Dayjs;
    conditions?: SearchCondition;
  }) => {
    const res = await call(
      "get",
      `report_manager/${resource}`
    )({
      ...(conditions || {}),
      account_id: accountId,
      base_date: baseDate.format("YYYY-MM-DD"),
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/pdf",
    });
  }
);

export const singleDownloadReportByValidFrom = createAsyncThunk(
  SLICE_NAME + "/singleDownloadReportByValidFrom",
  async ({
    resource,
    accountId,
    validFrom,
    conditions,
  }: {
    resource: string;
    accountId: number;
    validFrom: dayjs.Dayjs;
    conditions?: SearchCondition;
  }) => {
    const res = await call(
      "get",
      `report_manager/${resource}`
    )({
      ...(conditions || {}),
      account_id: accountId,
      valid_from: validFrom.format("YYYY-MM-DD"),
    });
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/pdf",
    });
  }
);

export const getPersonal = createAsyncThunk(
  SLICE_NAME + "/getPersonal",
  async ({ accountId }: { accountId: number }) => {
    const res = await call(
      "get",
      "report_manager/permitted_report"
    )({
      report_id: "salary_slip_single_download",
      account_id: accountId,
    });
    return res.data.result;
  }
);

export const getWithholdingTax = createAsyncThunk(
  SLICE_NAME + "/getWithholdingTax",
  async ({ accountId }: { accountId: number }): Promise<ReportMetaData[]> => {
    const data1 = await new Promise<any>((resolve, reject) => {
      call(
        "get",
        "report_manager/permitted_report"
      )({
        report_id: "withholding_tax_certificate_single_download",
        account_id: accountId,
      })
        .then((res) => {
          resolve(res.data.result);
        })
        .catch(reject);
    });
    return data1
      .map((r: any) => {
        return {
          title: r.title,
          validFrom: r.valid_from,
          downloadResource: r.report_id,
          accountId,
        } as ReportMetaData;
      })
      .sort((a: any, b: any) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix());
  }
);

export const getSalaryAndBonus = createAsyncThunk(
  SLICE_NAME + "/getSalaryAndBonus",
  async ({ accountId }: { accountId: number }): Promise<ReportMetaData[]> => {
    const salaries = await call(
      "get",
      "report_manager/permitted_report"
    )({
      report_id: "salary_slip_single_download",
      account_id: accountId,
    });
    const bonuses = await call(
      "get",
      "report_manager/permitted_report"
    )({
      report_id: "bonus_slip_single_download",
      account_id: accountId,
    });
    // salaryとbonusのresult配列をマージする
    const mergedResult = salaries.data.result.concat(bonuses.data.result);
    // 新しいオブジェクトを作成して返す
    const res = {
      data: {
        result: mergedResult,
        status: salaries.data.status,
      },
    };
    return res.data.result
      .map((r: any) => {
        return {
          title: r.title,
          validFrom: r.valid_from,
          downloadResource: r.report_id,
          accountId,
        } as ReportMetaData;
      })
      .sort((a: any, b: any) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix());
  }
);

export const getBonus = createAsyncThunk(
  SLICE_NAME + "/getBonus",
  async ({ accountId }: { accountId: number }): Promise<ReportMetaData[]> => {
    const res = await call(
      "get",
      "report_manager/permitted_report"
    )({
      report_id: "bonus_slip_single_download",
      account_id: accountId,
    });
    return res.data.result
      .map((r: any) => {
        return {
          title: r.title,
          validFrom: r.valid_from,
          downloadResource: r.report_id,
          accountId,
        } as ReportMetaData;
      })
      .sort((a: any, b: any) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix());
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearPersonalData: (state) => {
      state.personalData = [];
      return state;
    },
    clearWithholdingTaxData: (state) => {
      state.withholdingTaxMetaData = [];
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminReportTemplates.fulfilled, (state, action) => {
      state.adminReportTemplates = action.payload;
      state.adminInitialized = true;
    });
    builder.addCase(getUserReportTemplates.fulfilled, (state, action) => {
      state.userReportTemplates = action.payload;
      state.userInitialized = true;
    });
    builder.addCase(getPersonal.fulfilled, (state, action) => {
      state.personalData = action.payload;
    });
    builder.addCase(getWithholdingTax.fulfilled, (state, action) => {
      state.withholdingTaxMetaData = action.payload;
    });
    builder.addCase(getSalaryAndBonus.fulfilled, (state, action) => {
      state.salaryMetaData = action.payload;
    });
    builder.addCase(getBonus.fulfilled, (state, action) => {
      state.bonusMetaData = action.payload;
    });
  },
});

export const { clearPersonalData, clearWithholdingTaxData } = slice.actions;

export const selectReportState = (state: RootState) => {
  return state.report as ReportState;
};

export const selectYearlyData = (state: RootState) => {
  const reportState = selectReportState(state);
  const { salaryMetaData, bonusMetaData, withholdingTaxMetaData } = reportState;
  const toGroup = (metaData: ReportMetaData[]) => {
    const groupedData = [] as { data: ReportMetaData[]; year: number }[];
    metaData.forEach((d) => {
      let target = groupedData.find((_) => _.year === +dayjs(d.validFrom).format("YYYY"));
      if (!target) {
        target = { data: [], year: dayjs(d.validFrom).year() };
        groupedData.push(target);
      }
      target.data.push(d);
    });
    return groupedData
      .map((group) => {
        return {
          data: group.data.sort((a, b) => dayjs(b.validFrom).unix() - dayjs(a.validFrom).unix()),
          year: group.year,
        };
      })
      .sort((a, b) => b.year - a.year);
  };
  return {
    salaryMetaDataPerYear: toGroup(salaryMetaData),
    bonusMetaDataPerYear: toGroup(bonusMetaData),
    withholdingTaxMetaDataPerYear: toGroup(withholdingTaxMetaData),
  };
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
