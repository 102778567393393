import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import { searchCompanyMembers, selectProfileState } from "../profile/profileSlice";
import { selectUserState } from "../login/userSlice";
import { selectYearlyData, getWithholdingTax, singleDownloadReportByValidFrom } from "./reportSlice";
import dayjs from "dayjs";

function App() {
  const { accountId } = useParams();
  const { withholdingTaxMetaDataPerYear } = useAppSelector(selectYearlyData);
  const { filteredAccounts } = useAppSelector(selectProfileState);
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const _accountId = accountId ? +accountId : user.id;
    dispatch(getWithholdingTax({ accountId: _accountId }));
    dispatch(
      searchCompanyMembers({ conditions: { accountId: _accountId }, current_company_code: user.current_company.code })
    );
  }, [user]);

  const userName = useMemo(() => {
    if (!accountId) return "";
    const account = filteredAccounts.find((a) => a.id === +accountId);
    return account?.name ?? "";
  }, [user, filteredAccounts]);
  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"fileOutput"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">源泉徴収票{userName ? ` - ${userName}` : ""}</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            <Row>
              <Col>
                {withholdingTaxMetaDataPerYear.length > 0 ? (
                  withholdingTaxMetaDataPerYear.map(({ data }, i) => {
                    return data.map((d) => {
                      return (
                        <div key={`year${i}`}>
                          <a
                            className="--inline-link --cursor-pointer"
                            onClick={() => {
                              dispatch(
                                singleDownloadReportByValidFrom({
                                  resource: d.downloadResource,
                                  accountId: d.accountId,
                                  conditions: {},
                                  validFrom: dayjs(d.validFrom),
                                })
                              );
                            }}
                          >
                            {d.title}
                          </a>
                        </div>
                      );
                    });
                  })
                ) : (
                  <div>データがありません</div>
                )}
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default App;
