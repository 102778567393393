import { useAppDispatch, useAppSelector } from "../../app/store";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Card,
  Alert,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Sidebar from "../../component/Sidebar";
import {
  getApplyForms,
  getApplyTemplate,
  unselectFormTemplate,
  selectApplyState,
  selectTemplateAsProfileField,
  postRunningForm,
  unselectRunningForm,
  getRelatedAccounts,
  unselectRunningForms,
  getApplyTemplateSummaries,
} from "./applySlice";
import { setLoading } from "../notification/notificationSlice";
import { selectUserState } from "../login/userSlice";
import { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import ProfileField from "../profile/ProfileField";
import { APPLY_STEPPER_KEYS, ProcessorsPerStep, isEditableItem, validateSteps } from "./applyValues";
import { ProfileFieldStatus } from "../profile/profileValues";
import { FieldValue } from "../profile/profileFieldValues";
import { getAllTerms } from "../../app/translate";
import classNames from "classnames";
import Stepper from "../../component/Stepper";
import ApplySteps from "./ApplySteps";
import ModalDialog from "../../component/ModalDialog";
import { getQuery, testResponse } from "../../app/util";
import Icon from "../../component/Icon";
import MemberSelector from "./MemberSelector";

function App() {
  const TERMS = getAllTerms();
  const { applicationType } = useParams();
  const { selectedTemplate, selectedRunningForm, relatedAccounts, runningForms, templateSummaries } =
    useAppSelector(selectApplyState);
  const { originalProfileField, validateProfileField } = useAppSelector(selectTemplateAsProfileField);
  const { user } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const isRepresentative = useMemo(() => {
    return !!getQuery(search)?.is_representative;
  }, [search]);
  const [state, $state] = useState({
    mode: "previewing",
    isError: false,
    isSaving: false, // selectedRunningForm が存在する場合の挙動を、遷移直後・作成後で分けるために考える
    activeModal: "",
    existingId: "", // 既存の申請書へ遷移する際のID情報
    existingApplies: [] as { id: string; value: FieldValue[]; label: string }[],
    isGettingRecords: false, // 既存の申請書を取得中かどうか
    isDepricateConfirmed: false, // 廃止予定の場合確認したかどうか
  });
  const [profileField, $profileField] = useState({ ...originalProfileField });
  const [fieldStatus, $fieldStatus] = useState({} as ProfileFieldStatus);
  const [validated, $validated] = useState(false);
  const [processorsPerSteps, $processorsPerSteps] = useState([] as ProcessorsPerStep[]);
  const [applicant, $applicant] = useState(undefined as number | undefined);
  const [isTemplateLoading, $isTemplateLoading] = useState(false);

  useEffect(() => {
    if (originalProfileField.category && !isTemplateLoading) {
      $profileField({ ...originalProfileField });
      // 初回のバリデート実施
      const { validated, subFields } = validateProfileField(originalProfileField.subFields);
      $validated(validated);
      const fieldName = originalProfileField.fieldName;
      let nextFieldStatus = {} as ProfileFieldStatus;
      subFields.forEach((sf) => {
        const path = `${fieldName}/${sf.id}`;
        nextFieldStatus = {
          ...nextFieldStatus,
          [path]: { validated: !sf.errorMessage, errorMessage: sf.errorMessage ?? "" },
        };
      });
      $fieldStatus(nextFieldStatus);
    }
  }, [originalProfileField.category, isTemplateLoading]);

  const moveToEditView = () => {
    navigate(`/_/apply/edit/${user.id}/${state.isSaving ? selectedRunningForm.id : state.existingId}`);
  };
  const create = async () => {
    const res = testResponse(
      await dispatch(
        postRunningForm({
          applicant,
          application_type: selectedTemplate.id,
          inputs: profileField.subFields.reduce((prev, current) => {
            if (!current.editable) return prev;
            else {
              const correspondingInputInTemplate = selectedTemplate.inputs_on_create.find((_) => _.key === current.id);
              if (!correspondingInputInTemplate) return prev;
              return {
                ...prev,
                [current.id]: current.value,
              };
            }
          }, {} as { [key: string]: FieldValue }),
          steps: selectedTemplate.steps.map((step, stepIndex) => {
            return {
              order: step.order,
              processors: processorsPerSteps[stepIndex].processorChoices
                .filter((c) => c.isSelected)
                .map(({ id }) => ({ id })),
            };
          }),
        })
      )
    );

    if (res) {
      // 作成成功
      $state({ ...state, isSaving: true });
    } else {
      // 作成失敗（申請書の使用設定が変更になった以外でここに入る場合は実装確認）
      $state({ ...state, activeModal: "failed" });
    }
  };

  const _getAccountLabel = useCallback(
    (accountId: number) => {
      return relatedAccounts.find((_) => _.id === accountId)?.label ?? "DELETED";
    },
    [relatedAccounts]
  );

  useEffect(() => {
    // 今回の申請書の作成直後
    if (selectedRunningForm.id && state.isSaving) {
      moveToEditView();
    }
  }, [selectedRunningForm, state.isSaving]);

  useEffect(() => {
    // 既存の申請書がある
    if (runningForms.length) {
      // 作成時の入力項目のうち、転記するものが含まれない／並列申請不可
      if (!selectedTemplate.inputs_on_create.some((input) => input.targets) || !selectedTemplate.parallelizable) {
        // 転記する作成時の入力項目なし
        // 並列申請不可
        // → 作成済の申請書ページへ遷移できるようにモーダル表示
        $state({ ...state, existingId: runningForms[0].id, activeModal: "before_leaving", isGettingRecords: false });
      } else {
        // 作成時の入力項目あり
        // existing_application_check_keyが指定されている場合は指定されたキー、指定されていない場合はinputs_on_createの1件目
        let input = selectedTemplate.inputs_on_create[0];
        if (selectedTemplate.existing_application_check_key) {
          const check_key = selectedTemplate.inputs_on_create.filter(
            (i) => selectedTemplate.existing_application_check_key === i.key
          );
          if (check_key.length > 0) input = check_key[0];
        }
        const { key, options } = input;
        // → この時点では自動遷移させられない=モーダルの表示に必要な情報をまとめる
        const existingApplies = runningForms.map((f) => {
          const rawValue = f.inputs[key] ?? [];
          const value = Array.isArray(rawValue) ? [...rawValue] : ([rawValue] as (string | number | boolean)[]);
          let label = value.map((v) => options?.find((o) => o.value === v)?.label ?? `${v}`).join(",");
          if (f.template_type !== selectedTemplate.id) {
            const summary = templateSummaries.find(({ application_type }) => application_type === f.template_type);
            label = `${label}（${summary?.name}）`;
          }
          if (!value) label = "新規作成";

          return { id: f.id, value, label };
        });
        // モーダル表示
        $state({ ...state, existingApplies, activeModal: "before_leaving", isGettingRecords: false });
        // 作成できない値は選択肢から削除する
        const subFields = profileField.subFields.map((s) => {
          const labelValueOptions = s.labelValueOptions?.filter(
            ({ value }) => !existingApplies.some((a) => a.value.indexOf(value) !== -1)
          );
          return { ...s, labelValueOptions };
        });
        $profileField({ ...profileField, subFields });
      }
    } else {
      $state({ ...state, isGettingRecords: false });
    }
  }, [runningForms, templateSummaries]);

  useEffect(() => {
    if (templateSummaries.length === 0) {
      dispatch(getApplyTemplateSummaries());
    }
    dispatch(setLoading(true));
    return () => {
      dispatch(unselectFormTemplate());
      dispatch(unselectRunningForm());
      dispatch(unselectRunningForms());
    };
  }, []);

  const templateSummary = useMemo(() => {
    return templateSummaries.find(({ application_type }) => application_type === applicationType);
  }, [templateSummaries]);

  useEffect(() => {
    if (templateSummary) {
      if (
        !templateSummary?.is_active ||
        templateSummary?.template_status === "obsolete" ||
        (isRepresentative && !templateSummary?.can_represent) ||
        (!isRepresentative && !["all", "self"].includes(templateSummary?.applicant_type))
      ) {
        // 以下の場合、動線がないはずなので、そのままトップへ遷移
        // ・使用中ではない
        // ・廃止済
        // ・代理申請フラグ付きだが代理申請は許されていない種別
        // ・代理申請フラグがついていないが本人申請不可
        dispatch(setLoading(false));
        navigate("/_/apply/");
      } else if (templateSummary.template_status === "depricated" && !state.isDepricateConfirmed) {
        // 廃止予定のテンプレートの場合は冒頭で確認する
        dispatch(setLoading(false));
        $state({ ...state, activeModal: "depricated" });
      } else if (isRepresentative && !applicant) {
        // 代理申請で申請者が決まっていない場合、ローディングを終了する
        dispatch(setLoading(false));
        dispatch(unselectFormTemplate());
      } else {
        // 上記全て当てはまらない=有効なテンプレートの場合はテンプレートを取得
        $isTemplateLoading(true);
        dispatch(getApplyTemplate({ application_type: applicationType ?? "", applicant })).then(() => {
          dispatch(setLoading(false));
          $isTemplateLoading(false);
        });
      }
    }
  }, [user, templateSummary, applicant, state.isDepricateConfirmed]);
  useEffect(() => {
    if (selectedTemplate.id) {
      // 承認者のアカウント情報を取得
      const id__in = selectedTemplate.steps.map(({ processors }) => processors.map(({ id }) => id)).flat();
      dispatch(getRelatedAccounts({ id__in }));

      const inputsOnCreate = selectedTemplate.inputs_on_create;
      if (
        inputsOnCreate.length &&
        !inputsOnCreate.every(({ type, options }) => !["options", "checkbox"].includes(type) || options?.length)
      ) {
        // 選択肢が存在しない→モーダル表示してトップへ
        $state({ ...state, activeModal: "no_options" });
      } else if (Object.values(selectedTemplate.exist_target).some((v) => v === false)) {
        // PUT/DELETE対象が存在しない→モーダル表示してトップへ
        $state({ ...state, activeModal: "no_records" });
      } else if (selectedTemplate.check_existing_application) {
        // 複数申請不可の場合既存の申請書を取得する
        $state({ ...state, isGettingRecords: true });
        dispatch(
          getApplyForms({
            params: {
              applicant,
              request_type: "for_create",
              ...selectedTemplate.existing_application_conditions,
            },
          })
        );
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate.id) {
      // 承認ステップの初期設定
      const _next = selectedTemplate.steps.map((s) => {
        const processorChoices = s.processors.map(({ id }) => ({
          id,
          label: _getAccountLabel(id),
          // 編集不可の場合・候補が一人の場合はあらかじめ選択しておく
          isSelected: !s.editable || s.processors.length === 1,
        }));
        return { processorChoices };
      });
      const next = validateSteps(_next, selectedTemplate.steps);
      $processorsPerSteps(next);
    }
  }, [relatedAccounts, selectedTemplate]);

  const displayPageIndexes = useMemo(() => {
    // 申請時の入力内容に表示するページのindexを取得
    return selectedTemplate.input_pages
      .map(({ replication_number_key, conditions, display }, i) => {
        // 非表示設定
        if (display === false) return;
        // 条件に一致するか
        if (conditions) {
          const isDisplay = Object.keys(conditions).every((k) => {
            const currentValue = profileField.subFields.find(({ id }) => id === k)?.value;
            return currentValue === conditions[k];
          });
          if (!isDisplay) return;
        }
        // 複製回数が0より大きいか
        if (replication_number_key) {
          const currentValue = profileField.subFields.find(({ id }) => id === replication_number_key)?.value;
          const isDisplay = !!(currentValue && +currentValue > 0);
          if (!isDisplay) return;
        }
        return i;
      })
      .filter((_) => _ !== undefined) as number[];
  }, [selectedTemplate, profileField.subFields]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"apply"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">{templateSummary?.name}</h1>
        <div className="bg-white py-2 my-2">
          <Stepper steps={APPLY_STEPPER_KEYS.map((key) => ({ label: TERMS[key] }))}></Stepper>
        </div>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Container>
            {isRepresentative && (
              <Row className="mb-2">
                <Col>
                  <h2 className="Headline--section mb-2">申請者</h2>
                  <MemberSelector selectedAccountId={applicant} onSelectedAccountChange={(id) => $applicant(id)} />
                </Col>
              </Row>
            )}
            {selectedTemplate.id && (!isRepresentative || applicant) ? (
              <>
                <Row>
                  <Col>
                    <Alert>
                      {selectedTemplate.inputs_on_create.length > 0
                        ? "承認フロー・作成時入力項目を設定し、申請時入力項目をご確認の上、「作成」してください。"
                        : "承認フローを設定し、申請時入力項目をご確認の上、「作成」してください。"}
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 className="Headline--section mb-2">承認フロー</h2>
                    <ApplySteps
                      templateSteps={selectedTemplate.steps}
                      processorsPerSteps={processorsPerSteps}
                      mode="editing"
                      loginUserId={user.id}
                      terms={{}}
                      onChange={(_next) => {
                        const next = validateSteps(_next, selectedTemplate.steps);
                        $processorsPerSteps(next);
                      }}
                    ></ApplySteps>
                  </Col>
                </Row>
                {selectedTemplate.inputs_on_create.length > 0 && (
                  <Row className="mt-4">
                    <Col>
                      <h2 className="Headline--section mb-2">作成時入力項目</h2>
                      {profileField.fieldName && (
                        <ProfileField
                          fieldName={profileField.fieldName}
                          labelMap={profileField.labelMap}
                          subFields={profileField.subFields}
                          isEditing={true}
                          useControl={false}
                          showHeader={false}
                          fieldStatus={fieldStatus}
                          onChange={async (next, updatedSubFieldIndex) => {
                            const f = profileField.fieldName;
                            let { validated, subFields } = validateProfileField(next);

                            const nextFieldStatus = { ...fieldStatus };
                            nextFieldStatus[f] = nextFieldStatus[f] || {
                              validated: true,
                              errorMessage: "",
                            };
                            nextFieldStatus[f].validated = validated;
                            nextFieldStatus[f].errorMessage = validated ? "" : "入力内容を確認してください";
                            subFields.forEach((sf, index) => {
                              if (index !== updatedSubFieldIndex) return;
                              const path = `${f}/${sf.id}`;
                              nextFieldStatus[path] = nextFieldStatus[path] || {
                                validated: true,
                                errorMessage: "",
                              };
                              nextFieldStatus[path].validated = !!sf.errorMessage;
                              nextFieldStatus[path].errorMessage = sf.errorMessage ?? "";
                            });
                            $profileField({
                              ...profileField,
                              subFields,
                            });
                            $fieldStatus(nextFieldStatus);
                            $validated(validated);
                          }}
                          onEditOrCancel={(next) => {
                            $profileField({
                              ...profileField,
                              subFields: next,
                            });
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                )}
                <Row className="mt-4">
                  <Col>
                    <h2 className="Headline--section mb-2">申請時入力項目</h2>
                    <Accordion
                      defaultActiveKey={displayPageIndexes.length === 1 ? [`key_${displayPageIndexes[0]}`] : []}
                    >
                      {displayPageIndexes.map((index) => {
                        const inputs =
                          selectedTemplate.input_pages?.[index]?.inputs?.filter(
                            ({ type, display, reference_type }) =>
                              type === "staticLabel" ||
                              isEditableItem({ type, display: display ?? true, referenceType: reference_type })
                          ) ?? [];
                        const pageName = selectedTemplate.input_pages[index].name;
                        return (
                          <Accordion.Item eventKey={`key_${index}`} key={`key_${index}`}>
                            {displayPageIndexes.length > 1 && <Accordion.Header>{pageName}</Accordion.Header>}
                            <Accordion.Body>
                              {inputs.map(({ required, key, label, targets, required_conditions }) => {
                                const annotation = (() => {
                                  if (!required_conditions || Object.keys(required_conditions).length === 0) return;
                                  const table = targets?.[0].table?.split("::")?.[0];
                                  const column = targets?.[0].column ?? key;
                                  const termsKey =
                                    table && column ? `${table}__${column}` : `${selectedTemplate.id}__${key}`;
                                  return TERMS[`EDITING_ANNOTATION_${termsKey}`];
                                })();

                                return (
                                  <div
                                    className={classNames({ "--bold": true, "--required-label": required })}
                                    key={`input_${key}`}
                                  >
                                    {label}
                                    {annotation && (
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 50, hide: 50 }}
                                        overlay={(props) => (
                                          <Tooltip id={`tooltip_${key}`} {...props}>
                                            {annotation}
                                          </Tooltip>
                                        )}
                                      >
                                        <span className="ms-1">
                                          <Icon width={15} height={15} type="info-circle-fill" />
                                        </span>
                                      </OverlayTrigger>
                                    )}
                                  </div>
                                );
                              })}
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Button
                      variant="primary"
                      disabled={!validated || processorsPerSteps.some((_) => _.errorMessage)}
                      onClick={() => {
                        $state({ ...state, activeModal: "before_create" });
                      }}
                    >
                      作成
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </Container>
        </main>
      </div>
      <Modal
        show={state.activeModal === "before_leaving"}
        onHide={() => {
          if (isRepresentative) {
            $state({ ...state, activeModal: "" });
            $applicant(undefined);
          } else window.history.back();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {state.existingApplies.length ? (
            <Fragment>
              以下は作成済の申請書があるため、作成できません。
              <Card className="my-2">
                <Card.Body>
                  {state.existingApplies.map(({ id, label }, i) => (
                    <Form.Check
                      type="radio"
                      label={label}
                      key={`existing_check_${i}`}
                      id={`existing_check_${i}`}
                      checked={state.existingId === id}
                      value={id}
                      onChange={(e) => {
                        $state({ ...state, existingId: e.target.value });
                      }}
                    />
                  ))}
                </Card.Body>
              </Card>
            </Fragment>
          ) : (
            `作成済の申請書（${
              templateSummaries.find(({ application_type }) => application_type === runningForms[0]?.template_type)
                ?.name
            }）があるため、作成できません。`
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (isRepresentative) {
                $state({ ...state, activeModal: "" });
                $applicant(undefined);
              } else window.history.back();
            }}
            variant="outline-secondary"
          >
            {isRepresentative ? "他の申請者を選択する" : "戻る"}
          </Button>
          {state.existingApplies.length ? (
            <Button onClick={() => $state({ ...state, activeModal: "" })} variant="primary">
              上記以外で申請書を作成する
            </Button>
          ) : null}
          <Button onClick={moveToEditView} variant="primary" disabled={state.existingId.length === 0}>
            {state.existingApplies.length ? "選択した申請書を確認する" : "申請書を確認する"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalDialog
        show={state.activeModal === "no_options"}
        type="alert"
        onConfirm={() => {
          if (isRepresentative) {
            $state({ ...state, activeModal: "" });
            $applicant(undefined);
          } else window.history.back();
        }}
        message={
          isRepresentative
            ? "選択可能な選択肢が存在しません。申請者を選択し直してください。"
            : "選択可能な選択肢が存在しないため、申請書トップへ戻ります。"
        }
      />
      <ModalDialog
        show={state.activeModal === "no_records"}
        type="alert"
        onConfirm={() => {
          if (isRepresentative) {
            $state({ ...state, activeModal: "" });
            $applicant(undefined);
          } else window.history.back();
        }}
        message={
          isRepresentative
            ? "対象が存在しません。申請者を選択し直してください。"
            : "対象が存在しないため、申請書トップへ戻ります。"
        }
      />
      <ModalDialog
        show={state.activeModal === "before_create"}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
        onConfirm={create}
        message={"作成しますか？"}
      />
      <ModalDialog
        show={state.activeModal === "failed"}
        onCancel={() => {
          $state({ ...state, activeModal: "" });
        }}
        type="alert"
        onConfirm={() => window.history.back()}
        message={"作成に失敗しました。作成が許可されていない可能性があります。申請書トップへ遷移します。"}
      />
      <ModalDialog
        show={state.activeModal === "depricated"}
        onCancel={() => window.history.back()}
        onConfirm={() => $state({ ...state, activeModal: "", isDepricateConfirmed: true })}
        message={`選択された申請書種別は ${templateSummary?.obsolete_on} に廃止されます。作成を続けますか？`}
      />
    </div>
  );
}

export default App;
