import { useState, useEffect, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  getRoles,
  selectPermissionState,
  getApiSummaries,
  getRoleBehaviors,
  commitRoleBehaviors,
  createEmptyRoleBehavior,
  stageRoleApiBehaviors,
  unselectRoleApiBehaviors,
} from "./permissionSlice";
import EditPolicyComponent from "./EditPolicyComponent";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Dropdown, DropdownButton } from "react-bootstrap";
import "../../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalDialog from "../../component/ModalDialog";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { policyDocId, roleBehaviors, apiSummaries } = useAppSelector(selectPermissionState);
  const [state, $state] = useState({
    roleName: {
      value: "",
      entered: false,
      validated: false,
      message: "",
    },
    roleLabel: {
      value: "",
      validated: false,
      entered: false,
      message: "",
    },
    description: {
      value: "",
      entered: false,
      validated: false,
      message: "",
    },
  });
  const [copyingRoleLabel, $copyingRoleLabel] = useState("（コピーするロール）");
  const [activeModal, $activeModal] = useState("");

  useEffect(() => {
    dispatch(getApiSummaries());
    dispatch(getRoles());
  }, []);
  useEffect(() => {
    if (apiSummaries.length > 0) {
      dispatch(getRoleBehaviors({ apiSummaries })).then(() => {
        dispatch(createEmptyRoleBehavior({ apiSummaries }));
      });
    }
    return () => {
      dispatch(unselectRoleApiBehaviors());
    };
  }, [apiSummaries.length]);

  useEffect(() => {
    const next = { ...state };
    if (next.roleName.entered) {
      if (!/^[a-zA-Z0-9]+$/.test(state.roleName.value)) {
        next.roleName.validated = false;
        next.roleName.message = "アルファベットと数字のみを使用して入力してください";
      } else if (roleBehaviors.some((_) => _.roleName === state.roleName.value)) {
        next.roleName.validated = false;
        next.roleName.message = "既存のロールと重複しています";
      } else {
        next.roleName.validated = true;
        next.roleName.message = "";
      }
    }
    if (next.roleLabel.entered) {
      if (next.roleLabel.value === "") {
        next.roleLabel.validated = false;
        next.roleLabel.message = "入力してください";
      } else if (roleBehaviors.some((_) => _.label === state.roleLabel.value)) {
        next.roleLabel.validated = false;
        next.roleLabel.message = "既存のロール名と重複しています";
      } else {
        next.roleLabel.validated = true;
        next.roleLabel.message = "";
      }
    }
    if (next.description.entered) {
      next.description.validated = !!next.description.value;
      next.description.message = next.description.validated ? "" : "入力してください";
    }
    $state(next);
  }, [state.roleName.value, state.roleLabel.value, state.description.value]);

  const validated = useMemo(() => {
    return state.roleName.validated && state.roleLabel.validated && state.description.validated;
  }, [state.roleName.validated, state.roleLabel.validated, state.description.validated]);

  const saveRole = async () => {
    const _roleBehaviors = roleBehaviors.map((role) => {
      if (role.roleDocId !== "__new") return role;
      return {
        ...role,
        roleDocId: "__new",
        roleName: state.roleName.value,
        label: state.roleLabel.value,
        description: state.description.value,
      };
    });
    await dispatch(
      commitRoleBehaviors({
        policyDocId,
        roleDocId: "__new",
        roleBehaviors: _roleBehaviors,
      })
    );
    navigate("/_/permission/role");
  };

  return (
    <Container>
      <Row className="mt-4 --align-items-center">
        <Col md={4}>
          <Form.Label>既存のロールをコピーする</Form.Label>
        </Col>
        <Col md={8}>
          {roleBehaviors.length > 0 && (
            <DropdownButton variant="outline-primary" title={copyingRoleLabel}>
              {roleBehaviors
                .filter((role) => role.roleDocId !== "__new")
                .map((role) => {
                  return (
                    <Dropdown.Item
                      key={`role_${role.roleName}`}
                      onClick={() => {
                        $copyingRoleLabel(role.label);
                        $state({
                          roleName: {
                            value: "",
                            entered: false,
                            validated: false,
                            message: "",
                          },
                          roleLabel: {
                            value: "",
                            entered: false,
                            validated: false,
                            message: "",
                          },
                          description: {
                            value: role.description,
                            entered: true,
                            validated: false,
                            message: "",
                          },
                        });
                        const next = roleBehaviors.map((_r) => {
                          if (_r.roleDocId !== "__new") return _r;
                          return {
                            ..._r,
                            content: role.content,
                          };
                        });
                        dispatch(stageRoleApiBehaviors(next));
                      }}
                    >
                      {role.label}
                    </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className="mb-3">
            <h2 className="Headline--section mb-2">新しいロール</h2>
            <Form.Label htmlFor="clientName" className="--required-label">
              ロール ID
            </Form.Label>
            <Form.Control
              type="text"
              id="roleName"
              placeholder=""
              value={state.roleName.value}
              onChange={(e) =>
                $state({
                  ...state,
                  roleName: {
                    ...state.roleName,
                    value: e.target.value,
                    entered: true,
                  },
                })
              }
            />
            {state.roleName.message && <div className="--font-s --text-annotation mt-1">{state.roleName.message}</div>}
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="clientName" className="--required-label">
              ロール名
            </Form.Label>
            <Form.Control
              type="text"
              id="roleLabel"
              placeholder=""
              value={state.roleLabel.value}
              onChange={(e) =>
                $state({
                  ...state,
                  roleLabel: {
                    ...state.roleLabel,
                    value: e.target.value,
                    entered: true,
                  },
                })
              }
            />
            {state.roleLabel.message && (
              <div className="--font-s --text-annotation mt-1">{state.roleLabel.message}</div>
            )}
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="clientName" className="--required-label">
              説明
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={state.description.value}
              onChange={(e) =>
                $state({
                  ...state,
                  description: {
                    ...state.description,
                    value: e.target.value,
                    entered: true,
                  },
                })
              }
            />
            {state.description.message && (
              <div className="--font-s --text-annotation mt-1">{state.description.message}</div>
            )}
          </div>
          <div className="mb-3">
            <div className="--fw-bold mb-2">設定</div>
            <EditPolicyComponent
              roleApiBehaviors={roleBehaviors.find((_) => _.roleDocId === "__new")?.content ?? []}
              onChange={(content) => {
                const next = roleBehaviors.map((role) => {
                  if (role.roleDocId !== "__new") return role;
                  return {
                    ...role,
                    content,
                  };
                });
                dispatch(stageRoleApiBehaviors(next));
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant="primary"
            disabled={!validated}
            onClick={() => {
              $activeModal("before_save");
            }}
          >
            保存
          </Button>
        </Col>
      </Row>
      <ModalDialog
        show={activeModal === "before_save"}
        onConfirm={() => {
          $activeModal("");
          saveRole();
        }}
        onCancel={() => {
          $activeModal("");
        }}
        message="作成します。よろしいですか？"
      />
    </Container>
  );
}

export default App;
