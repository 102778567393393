import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector } from "../../app/store";
import { selectUserState } from "../login/userSlice";

function MyNumberTop() {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectUserState);
  useEffect(() => {
    if (user.id > 0) {
      if (user.services.includes(10)) navigate(user.role === "admin" ? "/_/my_number/admin" : "/_/my_number/user");
      else navigate("/_/dashboard");
    }
  }, [user]);
  return <div />;
}

export default MyNumberTop;
