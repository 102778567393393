import { useEffect, useState, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../app/store";
import { Tab, Nav } from "react-bootstrap";
import PermissionList from "./PermissionList";
import RoleList from "../permission/RoleList";
import RoleEdit from "../permission/RoleEdit";
import RoleCreate from "../permission/RoleCreate";
import RolePriority from "../permission/RolePriority";
import Sidebar from "../../component/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import { selectUserRootRoles, PolicyMap, selectUserState } from "../login/userSlice";

type TAB = {
  label: string;
  eventKey: string;
  isAvailable: (policies: PolicyMap, roles: string[]) => boolean;
};

const TABS: TAB[] = [
  {
    label: "アクセス権限",
    eventKey: "permission",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
  {
    label: "ロール",
    eventKey: "role",
    isAvailable: (policies, _) => {
      return (policies.template_manager ?? []).some((_) => _ === "POST");
    },
  },
];

const getRolePageType = (pathname: string) => {
  if (pathname.indexOf("/_/permission/role/edit") === 0) return "edit";
  else if (pathname.indexOf("/_/permission/role/create") === 0) return "create";
  else if (pathname.indexOf("/_/permission/role/priority") === 0) return "priority";
  else if (pathname.indexOf("/_/permission/role") === 0) return "list";
  else return "";
};

function PermissionTop() {
  const location = useLocation();
  const { mode } = useParams();
  const { policies } = useAppSelector(selectUserState);
  const roles = useAppSelector(selectUserRootRoles);
  const tabPanes = useMemo(() => {
    const tabPanes = TABS.filter((tab) => tab.isAvailable(policies, roles));
    return tabPanes;
  }, [roles, policies]);
  const [state, $state] = useState({
    rolePageType: getRolePageType(location.pathname),
    activeTabKey: (() => {
      if (getRolePageType(location.pathname) !== "") return "role";
      switch (mode) {
        case "role":
          return mode;
        default:
          return "permission";
      }
    })() as string,
  });

  useEffect(() => {
    $state({
      ...state,
      rolePageType: getRolePageType(location.pathname),
    });
  }, [location]);

  const tabContent = useMemo(() => {
    if (state.activeTabKey === "role") {
      if (state.rolePageType === "edit") return <RoleEdit />;
      else if (state.rolePageType === "create") return <RoleCreate />;
      else if (state.rolePageType === "priority") return <RolePriority />;
      else return <RoleList />;
    } else {
      return <PermissionList />;
    }
  }, [state]);

  return (
    <div className="Layout">
      <div className="Layout__side">
        <Sidebar current={"permission"} />
      </div>
      <div className="Layout__main">
        <h1 className="Headline--page">権限管理</h1>
        <main className="mt-3 py-4 px-md-2 bg-white">
          <Tab.Container
            onSelect={(key: string | null) => {
              let next = { ...state };
              if (key) {
                next = { ...next, activeTabKey: key };
                if (key !== "permission") {
                  window.history.replaceState({}, "", `/_/permission/${key}`);
                  if (key === "role") {
                    next = { ...next, rolePageType: "list" };
                  }
                } else {
                  window.history.replaceState({}, "", `/_/permission/`);
                }
              }
              $state(next);
            }}
            activeKey={state.activeTabKey}
          >
            <Nav variant="tabs">
              {tabPanes.map((t) => (
                <Nav.Item key={t.eventKey}>
                  <Nav.Link eventKey={t.eventKey}>{t.label}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            <Tab.Content>{tabContent}</Tab.Content>
          </Tab.Container>
        </main>
      </div>
    </div>
  );
}

export default PermissionTop;
