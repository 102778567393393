import { Row, Col, Accordion, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import { useState, useEffect, useMemo } from "react";
import Calendar from "react-calendar";
import classNames from "classnames";

export type onChangeOption = {
  selectedPointDate: dayjs.Dayjs | null;
};

function SnapshotTimeSelector({
  onChange,
  selectedPointDate,
  useFarthestDaySelector = true,
  useFarthestPastDaySelector = true,
  isInput = false,
  subFieldId = "",
  minDate = new Date("1900-01-01"),
  maxDate = new Date("9999-12-31"),
}: {
  onChange: ({ selectedPointDate }: onChangeOption) => any;
  selectedPointDate: dayjs.Dayjs | null;
  useFarthestDaySelector?: boolean;
  useFarthestPastDaySelector?: boolean;
  isInput?: boolean;
  subFieldId?: string;
  minDate?: Date;
  maxDate?: Date;
}) {
  const [startOfRange, $startOfRange] = useState(selectedPointDate?.format("YYYY-MM-DD"));
  useEffect(() => {
    $startOfRange(selectedPointDate?.format("YYYY-MM-DD"));
  }, [selectedPointDate]);

  return (
    <Accordion className={classNames({ input: isInput })}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{selectedPointDate?.format("YYYY/MM/DD") ?? ""}</Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col>
              <div className="my-2">
                <Row>
                  <Col md={12}>
                    <Calendar
                      formatDay={(_, date) => `${date.getDate()}`}
                      activeStartDate={startOfRange ? new Date(startOfRange) : undefined}
                      onChange={(selectedDate: Date) => {
                        onChange({
                          selectedPointDate: dayjs(selectedDate),
                        });
                      }}
                      onActiveStartDateChange={(e) => {
                        const next = dayjs(e.activeStartDate).format("YYYY-MM-DD");
                        $startOfRange(next);
                      }}
                      value={selectedPointDate?.toDate()}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {!isInput && useFarthestPastDaySelector && (
                      <Button
                        variant="link"
                        className="mx-1"
                        onClick={() => {
                          onChange({
                            selectedPointDate: dayjs("1900-01-01"),
                          });
                        }}
                      >
                        最も遠い過去日を選択
                      </Button>
                    )}
                    <Button
                      variant="link"
                      className="mx-1"
                      onClick={() => {
                        onChange({
                          selectedPointDate: dayjs(),
                        });
                      }}
                    >
                      本日を選択
                    </Button>
                    {!isInput && useFarthestDaySelector && (
                      <Button
                        variant="link"
                        className="mx-1"
                        onClick={() => {
                          onChange({
                            selectedPointDate: dayjs("9999-12-31"),
                          });
                        }}
                      >
                        最も遠い未来日を選択
                      </Button>
                    )}
                    {isInput && (
                      <Button
                        variant="link"
                        className="mx-1"
                        onClick={() => {
                          onChange({ selectedPointDate: null });
                        }}
                      >
                        値を削除
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default SnapshotTimeSelector;
