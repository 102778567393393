import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppSelector, useAppDispatch } from "../../app/store";
import "react-calendar/dist/Calendar.css";
import { commitMyNumberSettings, getMyNumberSettings, selectMyNumberState } from "../myNumber/myNumberSlice";
import ModalDialog from "../../component/ModalDialog";

function MyNumberConfig() {
  const dispatch = useAppDispatch();
  const { textForEmployee, textForFamily, holdImageFiles, pendingYears, pendingMonths, notificationTemplate } =
    useAppSelector(selectMyNumberState);
  const [isUnsavedChanges, $isUnsavedChanges] = useState(false);
  const [activeModal, $activeModal] = useState("");

  const [next, $next] = useState({
    textForEmployee: "",
    textForFamily: "",
    pendingYears: 0 as number | "",
    pendingMonths: 0 as number | "",
    holdImageFiles: true,
    notificationTemplate: "",
  });

  const errorMessages = useMemo(() => {
    const errorMessages = {} as { [id: string]: string };
    (["textForEmployee", "textForFamily", "notificationTemplate"] as (keyof typeof next)[]).forEach((id) => {
      if (`${next[id]}`.trim() === "") errorMessages[id] = "入力してください";
    });
    if (next.pendingYears === "" || next.pendingYears < 0)
      errorMessages["pendingYears"] = "0以上の整数を入力してください";
    if (next.pendingMonths === "" || next.pendingMonths < 0 || next.pendingMonths > 11)
      errorMessages["pendingMonths"] = "0以上11以下の整数を入力してください";
    return errorMessages;
  }, [next]);

  useEffect(() => {
    dispatch(getMyNumberSettings());
  }, []);

  useEffect(() => {
    $next({
      textForEmployee,
      textForFamily,
      pendingYears,
      pendingMonths,
      holdImageFiles,
      notificationTemplate,
    });
  }, [textForEmployee, textForFamily, pendingYears, pendingMonths, holdImageFiles, pendingYears, notificationTemplate]);

  const commit = async () => {
    await dispatch(
      commitMyNumberSettings({
        textForEmployee: next.textForEmployee,
        textForFamily: next.textForFamily,
        pendingYears: next.pendingYears || 0,
        pendingMonths: next.pendingMonths || 0,
        holdImageFiles: next.holdImageFiles,
        notificationTemplate: next.notificationTemplate,
      })
    );
    $isUnsavedChanges(false);
    $activeModal("");
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <div>
            <Link to={"/_/my_number/download_log"}>
              <Button variant="outline-primary">一括ダウンロードログを表示</Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md={6} className="--required-label">
          退職者または削除予定としたマイナンバーが完全に削除されるまでの期間
        </Col>
        <Col md={2}>
          <Form.Control
            type="number"
            value={next.pendingYears}
            min={0}
            onChange={(e) => {
              const nextValue = parseInt(e.target.value);
              $next({ ...next, pendingYears: isNaN(nextValue) ? "" : nextValue });
              $isUnsavedChanges(true);
            }}
          />
          {errorMessages["pendingYears"] && (
            <Form.Text className="text-danger">{errorMessages["pendingYears"]}</Form.Text>
          )}
        </Col>
        <Col md={1}>年</Col>
        <Col md={2}>
          <Form.Control
            type="number"
            value={next.pendingMonths}
            min={0}
            max={11}
            onChange={(e) => {
              const nextValue = parseInt(e.target.value);
              $next({ ...next, pendingMonths: isNaN(nextValue) ? "" : nextValue });
              $isUnsavedChanges(true);
            }}
          />
          {errorMessages["pendingMonths"] && (
            <Form.Text className="text-danger">{errorMessages["pendingMonths"]}</Form.Text>
          )}
        </Col>
        <Col md={1}>か月</Col>
      </Row>
      <Row className="mt-4 --align-items-center">
        <Col md={6}>確認資料の保管</Col>
        <Col md={6}>
          <Form.Check
            inline
            type="checkbox"
            id={`holdImages`}
            label={"マイナンバー確認後も確認資料を保持する"}
            checked={next.holdImageFiles}
            onChange={() => {
              $next({ ...next, holdImageFiles: !next.holdImageFiles });
              $isUnsavedChanges(true);
            }}
          ></Form.Check>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="--required-label">
          利用目的（社員本人向け）
        </Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.textForEmployee}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                textForEmployee: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
          {errorMessages["textForEmployee"] && (
            <Form.Text className="text-danger">{errorMessages["textForEmployee"]}</Form.Text>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="--required-label">
          利用目的（配偶者・家族向け）
        </Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.textForFamily}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                textForFamily: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
          {errorMessages["textForFamily"] && (
            <Form.Text className="text-danger">{errorMessages["textForFamily"]}</Form.Text>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="--required-label">
          一括提出依頼のデフォルト文面
        </Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            value={next.notificationTemplate}
            style={{ height: "200px" }}
            onChange={(e) => {
              $next({
                ...next,
                notificationTemplate: `${e.target.value}`,
              });
              $isUnsavedChanges(true);
            }}
          />
          {errorMessages["notificationTemplate"] && (
            <Form.Text className="text-danger">{errorMessages["notificationTemplate"]}</Form.Text>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div>
            <Button
              onClick={() => $activeModal("before_commit")}
              disabled={!isUnsavedChanges || Object.keys(errorMessages).length > 0}
              variant="primary"
            >
              保存
            </Button>
          </div>
        </Col>
      </Row>
      <ModalDialog
        show={activeModal === "before_commit"}
        onConfirm={commit}
        onCancel={() => $activeModal("")}
        message={"保存します。よろしいですか？"}
      />
    </Container>
  );
}

export default MyNumberConfig;
