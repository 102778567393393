import {
  FieldValidationRule,
  FieldValue,
  Event,
  ProfileCondition,
  ProfileSubFieldType,
} from "../profile/profileFieldValues";

// 申請書のステップ
export type ApplyApprovementStep = {
  name: string;
  order: number;
  processors: { id: number }[];
  status: string;
  processed_at?: number;
  processed_by?: number;
};

// ステップのテンプレート
export type ApplyTemplateStep = {
  name: string;
  order: number;
  editable: boolean;
  processors: { id: number }[];
  hasInput?: boolean;
  copy?: boolean;
};

export type ApplicationConfigStep = {
  editable: boolean;
  name: string;
  type: "related" | "fixed";
  group: {
    position_codes?: string[];
    section_codes?: string[];
    role_names?: string[];
  };
  copy?: boolean;
  inputs?: string[];
};
export type ApplicationConfig = {
  id: string;
  name: string;
  application_type: string;
  created_at: number;
  updated_at: number;
  version: number | null;
  notes?: string;
  steps: ApplicationConfigStep[];
  input_pages: ApplicationConfigInputPage[];
};

type TemplateStatusType = "obsolete" | "depricated" | "active";

export type ApplyTemplateSummary = {
  application_type: string;
  name: string;
  display_names: string[];
  version: number;
  categories: string[];
  is_active: boolean;
  template_status: TemplateStatusType;
  obsolete_on: null | Date;
  description: string;
  inactive_copy_targets: string[];
  applicant_type: "self" | "representative" | "all";
  can_represent: boolean;
};

export type ApplyInputPage = {
  key?: string;
  name: string;
  replication_number_key?: string;
  conditions?: { [key: string]: FieldValue };
  inputs?: ApplyFormRow[];
  processor_inputs?: ApplyFormRow[];
  display?: boolean;
  display_editable?: boolean;
};

export type ApplyTemplate = {
  id: string;
  title: string;
  notes?: string;
  copy_types: { [table: string]: string };
  steps: ApplyTemplateStep[];
  input_pages: ApplyInputPage[];
  inputs_on_create: ApplyFormRow[];
  show_before_apply_record: boolean;
  check_existing_application: boolean;
  existing_application_conditions?: { [key: string]: any };
  existing_application_check_key?: string;
  parallelizable: boolean;
  exist_target: { [table: string]: boolean };
  condition_target_keys: string[];
};
export type ApplyFormRow = {
  key: string;
  label: string;
  value: FieldValue;
  required?: boolean;
  required_conditions?: ProfileCondition;
  targets?: { table: string; column?: string }[];
  type: ProfileSubFieldType;
  options?: { label: string; value: string | number }[];
  reference?: string;
  reference_type?: string;
  reference_format?: string;
  rules: FieldValidationRule[];
  correlation_rules: FieldValidationRule[];
  input_step_order: number;
  additional?: boolean;
  pageIndex?: number;
  display?: boolean;
  display_conditions?: ProfileCondition;
  events?: Event[];
  option_editable?: boolean;
  required_editable?: boolean;
  display_editable?: boolean;
  keep_value?: boolean;
};
export type RunningApplyForm = {
  id: string;
  applicant_id: number;
  representative_applicant_id?: number;
  template_type: string;
  inputs: { [key: string]: FieldValue };
  status: string;
  steps: ApplyApprovementStep[];
  template_steps: ApplyTemplateStep[];
  config_version: number;
  before_apply_record?: { [key: string]: FieldValue };
  applied_at: number;
  created_at: number;
  updated_at: number;
  completed_at: number;
};

export type ApplicationHistoryRow = {
  id: string;
  applicant: number;
  comment: string;
  processor: number;
  status: string;
  step_name: string;
  step_order: number;
  type: string;
  created_at: number;
  updated_at: number;
};

export type Account = {
  id: number;
  label: string;
  name?: string;
  isActive?: boolean;
};

export enum ApplyStepperStep {
  Create,
  Input,
  Apply,
  Approve,
}

export const APPLY_STEPPER_KEYS = (() => {
  return Object.keys(ApplyStepperStep)
    .filter((s) => isNaN(+s))
    .map((s) => `APPLY_STEPPER_${s.toLowerCase()}`);
})();

export type Assignment = {
  accountId: number;
  sectionName: string;
  positionName: string;
  isConcurrent: boolean;
};

export type ProcessorChoice = Account & {
  isSelected: boolean;
};

export type ProcessorsPerStep = {
  processorChoices: ProcessorChoice[];
  errorMessage?: string;
};

export const validateSteps = (processorsPerSteps: ProcessorsPerStep[], templateSteps: ApplyTemplateStep[]) => {
  const next = processorsPerSteps.map((step, index) => {
    const templateStep = templateSteps[index];
    const { copy, hasInput, processors: templateProcessors } = templateStep;
    const errorMessage = (() => {
      // 選択中の承認者アカウントID
      const selectingIds = step.processorChoices.filter((_) => _.isSelected).map(({ id }) => id);
      // 以降選択なしの場合について確認
      if (selectingIds.length) return;
      // 候補がいるのに選択されていない
      if (templateProcessors.length) return "候補がいる場合、必ず1人以上選択してください。";
      // データ反映/入力項目ありステップは必ず承認者が必要
      if (copy) return "データ反映のステップには必ず1人以上選択してください。";
      if (hasInput) return "入力項目ありのステップには必ず1人以上選択してください。";
    })();
    return { ...step, errorMessage };
  });
  return next;
};
export type PutApplicationParameters = {
  id: string;
  updated_at: number;
  request_type?: string;
  inputs?: { [key: string]: FieldValue };
  to_rejected_step?: boolean;
  comment?: string;
  reject_to?: number;
  step_order?: number;
  steps?: { order: number; processors: { id: number }[] }[];
  isAdmin?: boolean;
};

export type ApplicationConfigInput = {
  key?: string;
  type?: string;
  label?: string;
  value?: FieldValue;
  additional?: boolean;
  options?: { label: string }[];
  reference?: string;
  edited?: boolean;
  required?: boolean;
  display?: boolean;
  reference_type?: string;
  reference_format?: string;
};

export type ApplicationConfigInputPage = {
  display?: boolean;
  inputs: ApplicationConfigInput[];
  processor_inputs: ApplicationConfigInput[];
};

export type ApplicationStatus = {
  [applicationType: string]: boolean;
};

export type NotificationTarget = {
  [applicationType: string]: number[];
};

export const APPLICATION_STATUSES = ["todo", "running", "rejected", "rejected_to_applicant", "copied", "done"];

export const APPLICATION_STEP_STATUSES = ["todo", "running", "rejected", "done"];

export const SELECTABLE_SUB_FIELD_TYPES = [
  "string",
  "longtext",
  "number",
  "float",
  "number__calculation", // subFieldType ではないが、項目として選択して設定可能とする
  "boolean",
  "postCode",
  "bankNumber",
  "bankBranchNumber",
  "bankAccountNo",
  "bankAccountNameKana",
  "nameKana",
  "addressKana",
  "date",
  "time",
  "yearMonth",
  "email",
  "phoneNumber",
  "file",
  "staticText",
  "staticLine",
  "options",
  "checkbox",
  "staticLabel",
] as const;

export const STATIC_SUB_FIELD_TYPES = ["staticText", "staticLine"];
export const NOT_INPUT_SUB_FIELD_TYPES = ["staticText", "staticLine", "staticLabel", "number__calculation"];

export const APPLICATION_CATEGORIES = [
  "entrance_resignation",
  "personnel_change",
  "salary",
  "spouse",
  "dependent",
  "childcare",
  "health_care",
  "other_leave",
  // "employment_agreement", このカテゴリの申請書がまだないため一旦コメントアウト
  "skill",
  "others",
];

export const APPLICATION_LIST_UPTO = 10;

export const CALCULATION_FORMAT_REGEXP = /^[\d\.\(\)\+\-\*\/]*$/;

export const ACCOUNT_SEARCH_LIMIT = 10;

export const isEditableItem = ({
  display,
  type,
  referenceType,
}: {
  display: boolean;
  type: ProfileSubFieldType;
  referenceType?: string;
}) => {
  return (
    display !== false && // 表示されている
    !NOT_INPUT_SUB_FIELD_TYPES.includes(type) && // 入力不要なtypeではない
    !referenceType // 参照項目ではない
  );
};
