import { LangDictionary } from "../../app/translate";
const postCode = "例：123-4567";
const address = "例：東京都港区芝１－２ 港タワー１１１";
const addressKana = "例：トウキョウトミナトクシバ１－２ ミナトタワー１１１";
const phoneNumber = "例：03-1234-5678";
const nameTaro = "例：山田　太郎";
const nameHanako = "例：山田　花子";
const nameKana = "例：ヤマダ　タロウ";
const nameEn = "例：Taro Yamada";
const prefecture = "例：東京都";
const cityTownVillage = "例：港区";
const streetAddress = "例：芝１－２";
const apartmentBuilding = "例：港タワー１１１";
const payroll2FixedAmountMessageJp = "給与振込2 固定金額を指定した場合必須です";
const payroll2FixedAmountMessageEn = "Required if payroll_2_fixed_amount is set";
const payroll3FixedAmountMessageJp = "給与振込3 固定金額を指定した場合必須です";
const payroll3FixedAmountMessageEn = "Required if payroll_3_fixed_amount is set";
const bonus2FixedAmountMessageJp = "賞与振込2 固定金額を指定した場合必須です";
const bonus2FixedAmountMessageEn = "Required if bonus_2_fixed_amount is set";
const bonus3FixedAmountMessageJp = "賞与振込3 固定金額を指定した場合必須です";
const bonus3FixedAmountMessageEn = "Required if bonus_3_fixed_amount is set";
const payrollPaymentTypeMessageJp = "給与 支給区分が「振込のみ」「振込＋現金」の場合必須です";
const payrollPaymentTypeMessageEn = "Required if payroll_payment_type is 振込のみ or 振込＋現金";
const bonusPaymentTypeMessageJp = "賞与 支給区分が「振込のみ」「振込＋現金」の場合必須です";
const bonusPaymentTypeMessageEn = "Required if bonus_payment_type is 振込のみ or 振込＋現金";
const nameDivideMessageJp =
  "半角スペースで姓と名に分けられます。スペースが複数ある場合は、最初のスペースより後がすべて名になります。";
const nameDivideMessageEn = "Divide into last and first names with ' '";
const nameEnDivideMessageJp =
  "半角スペースで名と姓に分けられます。スペースが複数ある場合は、最後のスペースより前がすべて名になります。";
const nameEnDivideMessageEn = "Divide into first and last names with ' '";
const createNameByLastFirstNameKanaJP = "姓・名が入力されている場合、それらをもとに氏名(フリガナ)が更新されます。";
const createNameByLastFirstNameKanaEN =
  "If last name and first name are entered, 氏名(フリガナ) will be updated based on them.";
const createNameByLastFirstNameEnJP = "姓・名が入力されている場合、それらをもとに氏名(英字)が更新されます。";
const createNameByLastFirstNameEnEN =
  "If last name and first name are entered, 氏名(英字) will be updated based on them.";
const _placeholderDictionary = {
  profile_u_bank_account: {
    ...["payroll", "bonus"].reduce((prev, type) => {
      return [1, 2, 3].reduce((_prev, num) => {
        const fixedAmount = num === 1 ? {} : { [`${type}_${num}_fixed_amount`]: { ja: "例：70000", en: "" } };
        return {
          ..._prev,
          ...fixedAmount,
          [`${type}_${num}_payee_bank_number`]: { ja: "例：1234", en: "" },
          [`${type}_${num}_payee_branch_number`]: { ja: "例：123", en: "" },
          [`${type}_${num}_account_no`]: { ja: "例：1234567", en: "" },
          [`${type}_${num}_account_name_kana`]: { ja: nameKana, en: "" },
        };
      }, prev);
    }, {}),
  },
  account: {
    name_kana: { ja: nameKana, en: "" },
    name_en: { ja: nameEn, en: "" },
  },
  profile_u_personal: {
    name: { ja: nameTaro, en: "" },
    name_kana: { ja: nameKana, en: "" },
    name_en: { ja: nameEn, en: "" },
    birthplace: { ja: "例：東京都", en: "" },
  },
  profile_u_address: {
    postcode: { ja: postCode, en: "" },
    prefecture: { ja: prefecture, en: "" },
    city_town_village: { ja: cityTownVillage, en: "" },
    street_address: { ja: streetAddress, en: "" },
    apartment_building_etc: { ja: apartmentBuilding, en: "" },
    address_kana: { ja: addressKana, en: "" },
    phone_number: { ja: phoneNumber, en: "" },
    certificate_postcode: { ja: "例：456-1234", en: "" },
    certificate_prefecture: { ja: "例：神奈川県", en: "" },
    certificate_city_town_village: { ja: "例：横浜市", en: "" },
    certificate_street_address: { ja: "例：中区１－１", en: "" },
    certificate_apartment_building_etc: { ja: "例：サンハイツ１０１", en: "" },
    certificate_address_kana: { ja: "例：カナガワケンヨコハマシナカク１－１ サンハイツ１０１", en: "" },
    personal_mobile_number: { ja: "例：090-1234-5678", en: "" },
    personal_email: { ja: "例：yamada@example.com", en: "" },
    household_head_name: { ja: nameTaro, en: "" },
  },
  profile_u_emergency_contact: {
    emergency_contact_name: { ja: nameHanako, en: "" },
    emergency_contact_address: { ja: address, en: "" },
    emergency_contact_phone_number: { ja: phoneNumber, en: "" },
  },
  profile_u_spouse: {
    postcode: { ja: postCode, en: "" },
    spouse_name: { ja: nameHanako, en: "" },
    spouse_name_kana: { ja: "例：ヤマダ　ハナコ", en: "" },
    address: { ja: address, en: "" },
  },
  profile_u_dependent: {
    postcode: { ja: postCode, en: "" },
    dependent_name: { ja: "例：山田　一郎", en: "" },
    dependent_name_kana: { ja: "例：ヤマダ　イチロウ", en: "" },
    address: { ja: address, en: "" },
  },
  profile_u_personal_classification: {
    legal_name: { ja: nameTaro, en: "" },
    legal_name_kana: { ja: nameKana, en: "" },
  },
  profile_u_former_job: {
    postcode: { ja: postCode, en: "" },
    company_name: { ja: "例：株式会社日本", en: "" },
    company_address: { ja: "例：東京都港区六本木１－１ ツインタワー７階", en: "" },
  },
  profile_u_education: {
    school_name: { ja: "例：工業大学", en: "" },
    faculty_name: { ja: "例：工学部", en: "" },
    department_name: { ja: "例：機械工学科", en: "" },
    major_name: { ja: "例：生産技術", en: "" },
  },
} as { [table: string]: LangDictionary };

const _editingAnnotationDictionary = {
  profile_m_position: {
    rank: { ja: "権限処理において上位者・下位者の判定に使用されます", en: "" },
  },
  profile_u_bank_account: {
    payroll_2_fixed_amount: { ja: "こちらを指定した場合は、給与振込1に差引き額が振り込まれます", en: "" },
    payroll_3_fixed_amount: { ja: "こちらを指定した場合は、給与振込1に差引き額が振り込まれます", en: "" },
    bonus_2_fixed_amount: { ja: "こちらを指定した場合は、賞与振込1に差引き額が振り込まれます", en: "" },
    bonus_3_fixed_amount: { ja: "こちらを指定した場合は、賞与振込1に差引き額が振り込まれます", en: "" },
    payroll_2_payee_bank_number: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_2_payee_bank_name: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_2_payee_branch_number: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_2_payee_branch_name: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_2_deposit_type: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_2_account_no: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_2_account_name: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_2_account_name_kana: { ja: payroll2FixedAmountMessageJp, en: payroll2FixedAmountMessageEn },
    payroll_3_payee_bank_number: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    payroll_3_payee_bank_name: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    payroll_3_payee_branch_number: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    payroll_3_payee_branch_name: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    payroll_3_deposit_type: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    payroll_3_account_no: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    payroll_3_account_name: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    payroll_3_account_name_kana: { ja: payroll3FixedAmountMessageJp, en: payroll3FixedAmountMessageEn },
    bonus_2_payee_bank_number: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_2_payee_bank_name: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_2_payee_branch_number: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_2_payee_branch_name: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_2_deposit_type: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_2_account_no: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_2_account_name: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_2_account_name_kana: { ja: bonus2FixedAmountMessageJp, en: bonus2FixedAmountMessageEn },
    bonus_3_payee_bank_number: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    bonus_3_payee_bank_name: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    bonus_3_payee_branch_number: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    bonus_3_payee_branch_name: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    bonus_3_deposit_type: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    bonus_3_account_no: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    bonus_3_account_name: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    bonus_3_account_name_kana: { ja: bonus3FixedAmountMessageJp, en: bonus3FixedAmountMessageEn },
    payroll_1_payee_bank_number: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    payroll_1_payee_bank_name: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    payroll_1_payee_branch_number: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    payroll_1_payee_branch_name: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    payroll_1_deposit_type: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    payroll_1_account_no: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    payroll_1_account_name: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    payroll_1_account_name_kana: { ja: payrollPaymentTypeMessageJp, en: payrollPaymentTypeMessageEn },
    bonus_1_payee_bank_number: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    bonus_1_payee_bank_name: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    bonus_1_payee_branch_number: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    bonus_1_payee_branch_name: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    bonus_1_deposit_type: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    bonus_1_account_no: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    bonus_1_account_name: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    bonus_1_account_name_kana: { ja: bonusPaymentTypeMessageJp, en: bonusPaymentTypeMessageEn },
    payroll_cash_payment_category_type: {
      ja: "給与 支給区分が「現金のみ」「振込＋現金」の場合必須です",
      en: "Required if payroll_payment_type is 現金のみ or 振込＋現金",
    },
    bonus_cash_payment_category_type: {
      ja: "賞与 支給区分が「現金のみ」「振込＋現金」の場合必須です",
      en: "Required if bonus_payment_type is 現金のみ or 振込＋現金",
    },
    payroll_cash_fixed_amount: {
      ja: "給与現金 支給区分が「定額」の場合必須です",
      en: "Required if payroll_cash_payment_category_type is 定額",
    },
    bonus_cash_fixed_amount: {
      ja: "賞与現金 支給区分が「定額」の場合必須です",
      en: "Required if bonus_cash_payment_category_type is 定額",
    },
  },
  profile_u_dependent: {
    residence_type: {
      ja: "国内に住所または現在まで1年以上居所を有する場合は「居住者」を選択してください",
      en: "Please select '居住者' if you have a domicile or have lived in Japan for at least one year.",
    },
    death_on: {
      ja: "変更理由が「死亡」の場合は必須です。",
      en: "",
    },
  },
  profile_u_spouse: {
    residence_type: {
      ja: "国内に住所または現在まで1年以上居所を有する場合は「居住者」を選択してください",
      en: "Please select '居住者' if you have a domicile or have lived in Japan for at least one year.",
    },
    death_on: {
      ja: "変更理由が「死亡」の場合は必須です。",
      en: "",
    },
  },
  profile_u_personal_classification: {
    taxation_type: {
      ja: "当社のみで働く方は「甲欄」を選択してください。",
      en: "Please select 甲欄 if you work only for us",
    },
    widow_single_parent_type: {
      ja: "配偶者と死別・離婚している方は確認して選択してください",
      en: "If you are bereaved or divorced from your spouse, please check and select",
    },
    resident_type: {
      ja: "国内に住所または現在まで1年以上居所を有する場合は「居住者」を選択してください",
      en: "Please select '居住者' if you have a domicile or have lived in Japan for at least one year.",
    },
    legal_name: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    legal_name_kana: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
  },
  profile_u_personal: {
    name: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    name_kana: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    name_en: { ja: nameEnDivideMessageJp, en: nameEnDivideMessageEn },
    previous_name: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    previous_name_kana: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    last_name_kana: { ja: createNameByLastFirstNameKanaJP, en: createNameByLastFirstNameKanaEN },
    first_name_kana: { ja: createNameByLastFirstNameKanaJP, en: createNameByLastFirstNameKanaEN },
    last_name_en: { ja: createNameByLastFirstNameEnJP, en: createNameByLastFirstNameEnEN },
    first_name_en: { ja: createNameByLastFirstNameEnJP, en: createNameByLastFirstNameEnEN },
  },
} as { [table: string]: LangDictionary };

const _detailAnnotationDictionary = {
  profile_m_position: {
    rank: { ja: "権限処理において上位者・下位者の判定に使用されます", en: "" },
  },
  profile_u_dependent: {
    dependent_type: { ja: "現時点の年齢で計算しています", en: "Calculated based on current age" },
    age: { ja: "現時点の年齢で表示しています", en: "Based on current age" },
  },
  profile_u_spouse: {
    dependent_type: { ja: "税扶養区分をもとに自動で設定されます", en: "Automatically set based on '税扶養区分'" },
    age: { ja: "現時点の年齢で表示しています", en: "Based on current age" },
  },
  profile_u_personal: {
    age: { ja: "現時点の年齢で表示しています", en: "Based on current age" },
    name: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    name_kana: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    name_en: { ja: nameEnDivideMessageJp, en: nameEnDivideMessageEn },
    previous_name: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    previous_name_kana: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
  },
  profile_u_personal_classification: {
    legal_name: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
    legal_name_kana: { ja: nameDivideMessageJp, en: nameDivideMessageEn },
  },
} as { [table: string]: LangDictionary };

const Dictionary = {
  ...Object.keys(_placeholderDictionary).reduce((prev, tableName) => {
    const dict = _placeholderDictionary[tableName];
    return Object.keys(dict).reduce((_prev, columnName) => {
      return { ..._prev, [`PLACEHOLDER_${tableName}__${columnName}`]: dict[columnName] };
    }, prev);
  }, {}),
  ...Object.keys(_editingAnnotationDictionary).reduce((prev, tableName) => {
    const dict = _editingAnnotationDictionary[tableName];
    return Object.keys(dict).reduce((_prev, columnName) => {
      return { ..._prev, [`EDITING_ANNOTATION_${tableName}__${columnName}`]: dict[columnName] };
    }, prev);
  }, {}),
  ...Object.keys(_detailAnnotationDictionary).reduce((prev, tableName) => {
    const dict = _detailAnnotationDictionary[tableName];
    return Object.keys(dict).reduce((_prev, columnName) => {
      return { ..._prev, [`DETAIL_ANNOTATION_${tableName}__${columnName}`]: dict[columnName] };
    }, prev);
  }, {}),
} as LangDictionary;

export default Dictionary;
