import { useState, useEffect, useMemo } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ACCOUNT_SEARCH_LIMIT } from "./profileValues";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getMyStaff, selectProfileState } from "./profileSlice";
import { selectUserState } from "../login/userSlice";

function MyStaffSelector({
  selectedAccountId,
  onSelectedAccountChange,
}: {
  selectedAccountId?: number;
  onSelectedAccountChange: (id: number) => any;
}) {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUserState);
  const { myStaffs } = useAppSelector(selectProfileState);

  const [state, $state] = useState({
    keyword: "",
  });

  const [selectableMyStaffs, hasMore] = useMemo(() => {
    const selectableMyStaffs = myStaffs
      .filter((t) => t.id !== user.id)
      .filter((t) => {
        const name = t.name.toLowerCase().replace(/\s/g, "");
        const keyword = state.keyword.toLowerCase().replace(/\s/g, "");
        return name.includes(keyword) || t.loginCode.includes(keyword);
      });
    const hasMore = selectableMyStaffs.length > ACCOUNT_SEARCH_LIMIT;
    return [selectableMyStaffs.slice(0, ACCOUNT_SEARCH_LIMIT), hasMore];
  }, [myStaffs, state.keyword]);

  const selectTarget = (id: number) => {
    onSelectedAccountChange(id);
  };

  useEffect(() => {
    if (myStaffs.length === 0) dispatch(getMyStaff());
  }, [myStaffs]);

  const checkedMember = useMemo(() => {
    return myStaffs.find((t) => t.id === selectedAccountId);
  }, [myStaffs, selectedAccountId]);

  return (
    <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{checkedMember ? checkedMember.name : "（未選択）"}</Accordion.Header>
        <Accordion.Body>
          <Row className="mb-2">
            <Col md="12">
              <Form.Control
                type="text"
                id="search"
                value={state.keyword}
                className="mb-2"
                placeholder="キーワード（名前・ログインID）で絞り込む"
                onChange={(e) => $state({ ...state, keyword: e.target.value })}
              />
            </Col>
            {selectableMyStaffs.map((t) => (
              <Col md="6" key={`myStaff_${t.id}`}>
                <Form.Check
                  checked={selectedAccountId === t.id}
                  id={`myStaff_${t.id}`}
                  type="radio"
                  label={`${t.name}（${t.loginCode}）${!t.isActive ? "（無効）" : ""}`}
                  onChange={() => selectTarget(t.id)}
                />
              </Col>
            ))}
            {hasMore && (
              <Col>
                {`${ACCOUNT_SEARCH_LIMIT}名以上存在します。選択したいアカウントが表示されていない場合はキーワードを変更してください。`}
              </Col>
            )}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default MyStaffSelector;
