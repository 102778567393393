import { useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Table from "../../component/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import { clearMyNumberLogs, getMyNumberLogs, selectLogsWithAccount } from "./myNumberSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import dayjs from "dayjs";
import { getMembers } from "../profile/profileSlice";
import { MyNumber, myNumberLogActionDictionary } from "./myNumberValues";
import { setLoading } from "../notification/notificationSlice";

type Props = {
  isActive: boolean;
  name: string;
  myNumber?: MyNumber;
  onClose: () => any;
};

function MyNumberLogsModal({ isActive, myNumber, name, onClose }: Props) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectLogsWithAccount);

  useEffect(() => {
    if (myNumber && isActive) {
      dispatch(setLoading(true));
      dispatch(getMyNumberLogs({ myNumberId: myNumber.id }));
    } else if (!isActive && data.length > 0) {
      dispatch(clearMyNumberLogs());
    }
  }, [myNumber, isActive]);

  useEffect(() => {
    if (data.length === 0) return;
    const accountToFetch = data
      .filter(({ account, log }) => !account && log.subject !== "batch")
      .map(({ log }) => log.subject)
      .filter((_, i, self) => self.indexOf(_) === i);
    // 不足しているアカウントを取得
    if (accountToFetch.length > 0) {
      dispatch(getMembers()).then(() => dispatch(setLoading(false)));
    } else {
      dispatch(setLoading(false));
    }
  }, [data]);

  return (
    <Modal show={isActive} onHide={onClose} size="lg" centered>
      <Modal.Header>
        <Modal.Title>マイナンバー操作ログ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="--align-items-center">
          <Col md="3" className="--bold">
            対象者
          </Col>
          <Col md="9">{name}</Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Table
              col={[
                {
                  name: "種別",
                },
                {
                  name: "操作者",
                },
                {
                  name: "日時",
                },
              ]}
              row={data.map(({ log, account }) => {
                return {
                  data: [
                    myNumberLogActionDictionary[log.action_type],
                    log.subject === "batch" ? "バッチ" : account?.name ?? "",
                    dayjs(log.time).format("YYYY/MM/DD HH:mm:ss"),
                  ],
                };
              })}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyNumberLogsModal;
