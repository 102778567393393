import { useState, useMemo, useEffect } from "react";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AdhocCell } from "../features/client/clientValues";

function App({
  cell,
  columnChoices,
  onChange,
  onClose,
}: {
  cell: AdhocCell;
  columnChoices: { [key: string]: string[] };
  onChange: (cellId: string, value: string | number | boolean) => any;
  onClose: (cellId: string, value: string | number | boolean, cancelled: boolean) => any;
}) {
  // 編集開始したときの値を記憶し、キャンセルできるようにする
  const initValue = useMemo(() => cell.value, [cell.correcting]);
  const [currentValue, $currentValue] = useState("");

  useEffect(() => {
    if (cell.value === null) $currentValue("");
    else if (typeof cell.value === "string" || typeof cell.value === "number") $currentValue(`${cell.value}`);
    else $currentValue(`${cell.value}`); // boolean が該当
  }, [cell]);
  return (
    <div id={cell.id}>
      {!cell.correcting ? (
        cell.errors.length === 0 ? (
          <span>{currentValue}</span>
        ) : (
          <OverlayTrigger
            placement="top"
            delay={{ show: 50, hide: 400 }}
            overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                {cell.errors.join("\n")}
              </Tooltip>
            )}
          >
            <div className="Table__tooltip-content">{currentValue}</div>
          </OverlayTrigger>
        )
      ) : null}
      {cell.correcting ? (
        <div>
          {cell.column.indexOf("is_") === 0 ? (
            ["1", "0"].map((param) => (
              <Form.Check
                type="radio"
                label={param}
                key={cell.id + "_" + param}
                id={cell.id + "_" + param}
                checked={param === cell.value}
                value={param}
                onChange={(e) => {
                  cell.correcting && onChange(cell.id, e.target.value);
                }}
              />
            ))
          ) : cell.column.endsWith("_type") ? (
            <Form.Select
              value={currentValue}
              onChange={(e) => {
                cell.correcting && onChange(cell.id, e.target.value);
              }}
            >
              {/* 選択肢表示 */}
              <option value={""}>---</option>
              {columnChoices[cell.column]?.map((value, i) => {
                return (
                  <option key={`option_${i}`} value={value}>
                    {value}
                  </option>
                );
              })}
            </Form.Select>
          ) : (
            <Form.Control
              type="text"
              placeholder=""
              defaultValue={currentValue}
              onChange={(e) => {
                cell.correcting && onChange(cell.id, e.target.value);
              }}
            />
          )}
          <Button
            variant="outline-secondary"
            size="sm"
            className="mt-2"
            onClick={() => {
              if (!cell.correcting) return;
              onClose(cell.id, initValue, true);
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="mt-2 ms-1"
            onClick={() => {
              // 選択肢にない値で更新する場合は空文字にする
              const value =
                cell.column.endsWith("_type") && !columnChoices[cell.column].includes(currentValue) ? "" : currentValue;
              cell.correcting && onClose(cell.id, value, false);
            }}
          >
            更新
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default App;
