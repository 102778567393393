const data = {
  category: "personal",
  table: "personal",
  subFields: {
    personal_history: {
      type: "tableHandler",
      tag: "tb_",
    },
    valid_from_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    last_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    first_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    last_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    first_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    name_en_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    last_name_en_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    first_name_en_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    employment_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    enrollment_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    gender_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    birthday_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    age_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    extension_phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    outside_phone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    company_cellphone_number_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    joined_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    joined_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    resigned_on_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    exclusion_years_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_acceptance_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    birthplace_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    is_solo_transfer_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    marital_status_type_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    previous_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    previous_last_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    previous_first_name_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    previous_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    previous_last_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    previous_first_name_kana_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
    remarks_$N: {
      tag: "tb_",
      tagGroupIndex: 1,
    },
  },
  labelMap: {
    ja: {
      personal_history: "基本履歴",
    },
  },
};

export default data;
