import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import { dummyRequestedTemplates } from "./applyTemplates";
import {
  ApplyTemplate,
  ApplyTemplateSummary,
  ApplicationConfigStep,
  ApplicationConfig,
  RunningApplyForm,
  ApplicationHistoryRow,
  Account,
  ApplyFormRow,
  Assignment,
  ApplyTemplateStep,
  PutApplicationParameters,
  ApplicationStatus,
  ApplicationConfigInputPage,
  APPLICATION_LIST_UPTO,
  ApplyInputPage,
  NotificationTarget,
} from "./applyValues";
import {
  ProfileField,
  ProfileSubField,
  ProfileSubFieldType,
  FieldValue,
  FieldValidationRule,
  userColumnChoices,
  generateValidator,
  FieldValidator,
  ProfileFile,
  ProfileFileElement,
  ProfileCondition,
  Event,
} from "../profile/profileFieldValues";
import { call } from "../../app/api";
import dayjs from "dayjs";
import { DecodedFileData } from "../../component/Uploader";
import { download } from "../../app/download";

const SLICE_NAME = "apply";

interface ApplyState {
  initialized: boolean;
  templateSummaries: ApplyTemplateSummary[];
  requiredTemplateSummaries: ApplyTemplateSummary[];
  runningForms: RunningApplyForm[];
  reviewingFormsLength: number;
  rejectedFormsLength: number;
  rejectedRepresentativeFormsLength: number;
  selectedTemplate: ApplyTemplate;
  previewTemplate: ApplyTemplate;
  selectedRunningForm: RunningApplyForm;
  attachedFiles: ProfileFile[];
  applicationHistory: ApplicationHistoryRow[];
  latestApplicationConfig: ApplicationConfig;
  editingApplicationConfig: ApplicationConfig;
  relatedAccounts: Account[];
  assignments: Assignment[];
  filteredAccounts: Account[];
  applicationStatus: ApplicationStatus;
  applicationStatusId: string | null;
  runningFormsTotalCount: number;
  runningFormsHasMore: boolean;
  fetchedRunningFormsPage: number;
  notificationTargetId: string | null;
  notificationTarget: NotificationTarget;
  applicantConfigId: string | null;
  applicantConfig: { [key: string]: any };
}

/*
  state の初期状態
*/
const emptyTemplate = {
  id: "",
  title: "",
  copy_types: {},
  steps: [],
  input_pages: [],
  inputs: [],
  inputs_on_create: [],
  show_before_apply_record: false,
  check_existing_application: false,
  existing_application_conditions: {},
  existing_application_check_key: "",
  exist_target: {},
  parallelizable: true,
  condition_target_keys: [],
};

const emptyRunningForm = {
  id: "",
  applicant_id: 0,
  applicant_name: "",
  template_type: "",
  inputs: {},
  status: "",
  steps: [],
  template_steps: [],
  config_version: 0,
  applied_at: 0,
  created_at: 0,
  updated_at: 0,
  completed_at: 0,
};

const emptyApplicationConfig = {
  id: "",
  name: "",
  application_type: "",
  created_at: 0,
  updated_at: 0,
  version: null,
  steps: [],
  input_pages: [],
};

const initialState: ApplyState = {
  initialized: false,
  templateSummaries: [],
  requiredTemplateSummaries: [],
  runningForms: [],
  reviewingFormsLength: 0,
  rejectedFormsLength: 0,
  rejectedRepresentativeFormsLength: 0,
  selectedTemplate: {
    ...emptyTemplate,
  },
  previewTemplate: {
    ...emptyTemplate,
  },
  selectedRunningForm: {
    ...emptyRunningForm,
  },
  attachedFiles: [],
  applicationHistory: [],
  latestApplicationConfig: { ...emptyApplicationConfig },
  editingApplicationConfig: { ...emptyApplicationConfig },
  relatedAccounts: [],
  assignments: [],
  filteredAccounts: [],
  applicationStatus: {},
  applicationStatusId: null,
  runningFormsTotalCount: 0,
  runningFormsHasMore: false,
  fetchedRunningFormsPage: 0,
  notificationTargetId: null,
  notificationTarget: {},
  applicantConfigId: null,
  applicantConfig: {},
};

const typeMap: { [id: string]: ProfileSubFieldType } = {
  death_name_kana: "nameKana",
};

type RawInput = {
  key: string;
  required?: boolean;
  required_conditions?: ProfileCondition;
  targets: { table: string; column?: string }[];
  type: ProfileSubFieldType;
  value?: FieldValue;
  label?: string;
  input_step_order: number;
  options?: { label: string; value: string | number }[];
  reference?: string;
  reference_type?: string;
  reference_format?: string;
  rules?: FieldValidationRule[];
  additional?: boolean;
  display?: boolean;
  display_conditions?: ProfileCondition;
  events?: Event[];
  correlation_rules: FieldValidationRule[];
  option_editable?: boolean;
  org_required?: boolean;
  keep_value?: boolean;
};

export const getApplyTemplateSummaries = createAsyncThunk(SLICE_NAME + "/getApplyTemplateSummaries", async () => {
  const res = await call("get", "application_manager/template_summary")();
  return res.data.result;
});

const convertToApplyFormRow = (
  templateInputs: RawInput[],
  index?: number,
  conditionTargetKeys?: string[]
): ApplyFormRow[] => {
  return templateInputs.map((input: RawInput) => {
    return {
      key: input.key,
      label: input.label,
      required: input.required,
      required_conditions: input.required_conditions ?? {},
      targets: input.targets,
      input_step_order: input.input_step_order ?? 0,
      options: input.options,
      reference: input.reference,
      type: input.type,
      value: input.value ?? null,
      rules: input.rules ?? [],
      reference_type: input.reference_type ?? "",
      reference_format: input.reference_format,
      additional: input.additional ?? false,
      pageIndex: index,
      display: input.display,
      display_conditions: input.display_conditions ?? {},
      events: input.events,
      correlation_rules: input.correlation_rules ?? [],
      option_editable: input.option_editable ?? false,
      // 元々requiredではない & 条件付き表示ではない場合に編集可能
      required_editable: !input.org_required && !input.display_conditions,
      keep_value: input.keep_value ?? false,
      // 元々requiredではない & 条件の参照先ではない場合に編集可能
      display_editable: !input.org_required && !(conditionTargetKeys ?? []).includes(input.key),
    } as ApplyFormRow;
  });
};

const toForm = (raw: any): RunningApplyForm => {
  return {
    id: raw.id,
    applicant_id: raw.applicant,
    representative_applicant_id: raw.representative_applicant,
    template_type: raw.application_type,
    inputs: raw.inputs,
    status: raw.status,
    steps: raw.steps.map((step: any) => {
      const templateStep = raw.template.steps.find((s: ApplyTemplateStep) => s.order === step.order);
      return { ...step, name: templateStep.name };
    }),
    template_steps: raw.template.steps.map((step: ApplyTemplateStep) => {
      return { ...step, copy: raw.template.copy_step_order === step.order };
    }),
    config_version: raw.template.config_version,
    before_apply_record: raw.before_apply_record,
    applied_at: raw.applied_at,
    created_at: raw.created_at,
    updated_at: raw.updated_at,
    completed_at: raw.completed_at,
  };
};

export const getApplyForms = createAsyncThunk(
  SLICE_NAME + "/getApplyForms",
  async ({ params, isAdmin, page }: { params: { [key: string]: any }; isAdmin?: boolean; page?: number }) => {
    const api = isAdmin ? "application_admin_manager" : "application_manager";
    if (page) {
      params.limit = APPLICATION_LIST_UPTO;
      params.page = page;
    }
    const res = await call("get", `${api}/application`)(params);
    const runningFormsTotalCount = res.data.count;
    const runningFormsHasMore = res.data.has_more;
    const runningForms = res.data.result.map((r: any) => toForm(r));
    const fetchedRunningFormsPage = page ?? 1;
    return { runningFormsTotalCount, runningFormsHasMore, runningForms, fetchedRunningFormsPage };
  }
);

export const getRunningForm = createAsyncThunk(
  SLICE_NAME + "/getRunningForm",
  async ({ id, isAdmin }: { id: string; isAdmin?: boolean }) => {
    const api = isAdmin ? "application_admin_manager" : "application_manager";
    const res = await call("get", `${api}/application`)({ id });
    return toForm(res.data.result[0]);
  }
);

export const singleDownloadApplyForms = createAsyncThunk(
  SLICE_NAME + "/singleDownloadApplyForms",
  async ({ params }: { params: { [key: string]: any } }) => {
    params.format = "xlsx";
    const res = await call("get", "application_admin_manager/application")(params);
    const { file, file_name } = res.data.result;
    download({
      file,
      fileName: file_name,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  }
);

export const postRunningForm = createAsyncThunk(
  SLICE_NAME + "/postRunningForm",
  async ({
    applicant,
    application_type,
    inputs,
    steps,
  }: {
    applicant?: number;
    application_type: string;
    inputs: { [key: string]: FieldValue };
    steps: { order: number; processors: { id: number }[] }[];
  }) => {
    const res = await call("post", "application_manager/application", { handleError: false })({
      applicant,
      application_type,
      inputs,
      steps,
    });
    return toForm(res.data.result[0]);
  }
);

export const putRunningForm = createAsyncThunk(
  SLICE_NAME + "/putRunningForm",
  async ({
    id,
    updated_at,
    request_type,
    inputs,
    to_rejected_step,
    comment,
    reject_to,
    step_order,
    steps,
    isAdmin,
  }: PutApplicationParameters) => {
    const api = isAdmin ? "application_admin_manager" : "application_manager";
    const res = await call("put", `${api}/application`, { handleError: false })({
      request_type,
      id,
      updated_at,
      inputs,
      to_rejected_step,
      comment,
      reject_to,
      step_order,
      steps,
    });
    return toForm(res.data.result[0]);
  }
);

export const getTodoFormCounts = createAsyncThunk(SLICE_NAME + "/getTodoFormCounts", async () => {
  const params = [
    { request_type: "applicant", status: "rejected_to_applicant" },
    { request_type: "representative_applicant", status: "rejected_to_applicant" },
    { request_type: "processor", steps__elemMatch: { status: "running" } },
  ];
  const res = await Promise.all(params.map((param) => call("get", "application_manager/application")(param)));
  return {
    rejectedFormsLength: res[0].data.result.length,
    rejectedRepresentativeFormsLength: res[1].data.result.length,
    reviewingFormsLength: res[2].data.result.length,
  };
});

export const getAssienment = createAsyncThunk(
  SLICE_NAME + "/getAssienment",
  async ({ accountId }: { accountId: number }) => {
    const res = await call("get", "user_data_manager/assignment2")({ account_id: accountId });
    return res.data.result;
  }
);

export const getRequiredTemplates = createAsyncThunk(SLICE_NAME + "/getRequiredTemplates", async () => {
  return dummyRequestedTemplates;
});

const _toTemplate = (data: any) => {
  const input_pages = data.input_pages.map((page: any, i: number) => {
    const { inputs, processor_inputs, display_editable, ..._page } = page;
    return {
      display_editable: display_editable ?? false,
      inputs: convertToApplyFormRow(inputs, i, data.condition_target_keys),
      processor_inputs: convertToApplyFormRow(processor_inputs, i, data.condition_target_keys),
      ..._page,
    };
  });
  const allProcessorInputs = input_pages.map((page: any) => page.processor_inputs).flat() as ApplyFormRow[];
  return {
    id: data.application_type,
    title: `${data.application_type}`,
    copy_types: data.copy_types,
    notes: data.notes,
    steps: data.steps.map((step: ApplyTemplateStep) => {
      return {
        editable: step.editable,
        name: step.name,
        order: step.order,
        processors: step.processors,
        copy: data.copy_step_order === step.order,
        hasInput: allProcessorInputs.some((_) => _.input_step_order === step.order),
      } as ApplyTemplateStep;
    }),
    input_pages,
    inputs_on_create: convertToApplyFormRow(data.inputs_on_create),
    show_before_apply_record: data.show_before_apply_record ?? false,
    check_existing_application: data.check_existing_application ?? false,
    existing_application_conditions: data.existing_application_conditions ?? {},
    existing_application_check_key: data.existing_application_check_key ?? "",
    exist_target: data.exist_target ?? {},
    parallelizable: data.parallelizable ?? true,
    is_obsolete: data.is_obsolete ?? false,
    condition_target_keys: data.condition_target_keys ?? [],
  };
};

export const getApplyTemplate = createAsyncThunk(
  SLICE_NAME + "/getApplyTemplate",
  async ({
    application_type,
    application_id,
    applicant,
  }: {
    application_type: string;
    application_id?: string;
    applicant?: number;
  }) => {
    const templateRes = await call(
      "get",
      "application_manager/template"
    )({ application_type, application_id, applicant });
    const data = templateRes.data.result[0];
    return _toTemplate(data);
  }
);

export const getPreviewTemplate = createAsyncThunk(
  SLICE_NAME + "/getPreviewTemplate",
  async ({ application_type, inputs }: { application_type: string; inputs?: { [key: string]: FieldValue } }) => {
    const templateRes = await call(
      "get",
      "template_manager/application_preview_template"
    )({ application_type, inputs });
    const data = templateRes.data.result[0];
    return _toTemplate(data);
  }
);

// 申請書を削除する
export const deleteRunningForm = createAsyncThunk(SLICE_NAME + "/deleteRunningForm", async ({ id }: { id: string }) => {
  await call(
    "delete",
    "application_manager/application"
  )({
    id,
  });
});

// 申請書の処理履歴を取得する
export const getApplicationHistory = createAsyncThunk(
  SLICE_NAME + "/getApplicationHistory",
  async ({ application_id }: { application_id: string }) => {
    const res = await call(
      "get",
      "application_manager/history"
    )({
      application_id,
    });
    return res.data.result;
  }
);

const _toApplicationConfig = (result: any) => {
  return {
    ...result,
    steps: result.steps.map((step: ApplicationConfigStep) => {
      return { ...step, inputs: step.inputs ?? [] };
    }),
    version: result.version ?? 0,
  };
};

// 最新の設定（リリース済）を取得する
export const getLatestApplicationConfig = createAsyncThunk(
  SLICE_NAME + "/getLatestApplicationConfig",
  async ({ application_type }: { application_type: string }) => {
    const res = await call(
      "get",
      "template_manager/application_config"
    )({
      application_type,
    });
    const result = res.data.result[0];
    if (!result) return { ...emptyApplicationConfig };
    return _toApplicationConfig(result);
  }
);

// 編集中の設定を取得する
export const getEditingApplicationConfig = createAsyncThunk(
  SLICE_NAME + "/getEditingApplicationConfig",
  async ({ application_type }: { application_type: string }) => {
    const res = await call(
      "get",
      "template_manager/application_config"
    )({
      application_type,
      is_editing: true,
    });
    const result = res.data.result[0];
    if (!result) return { ...emptyApplicationConfig };
    return _toApplicationConfig(result);
  }
);

// 承認フローをコピー元とするために取得する。state に入れない
export const getReferringApplicationConfig = createAsyncThunk(
  SLICE_NAME + "/getReferringApplicationConfig",
  async ({ application_type }: { application_type: string }) => {
    const res = await call(
      "get",
      "template_manager/application_config"
    )({
      application_type,
    });
    const result = res.data.result[0];
    if (!result) return;
    return {
      ...result,
      steps: result.steps.map((step: ApplicationConfigStep) => {
        return {
          ...step,
          inputs: step.inputs ?? [],
        };
      }),
    };
  }
);

export const putApplicationConfig = createAsyncThunk(
  SLICE_NAME + "/putApplicationConfig",
  async ({
    id,
    name,
    steps,
    input_pages,
    notes,
  }: {
    id: string;
    name: string;
    steps: ApplicationConfigStep[];
    input_pages: ApplicationConfigInputPage[];
    notes: string;
  }) => {
    const res = await call(
      "put",
      "template_manager/application_config"
    )({
      id,
      name,
      request_type: "update",
      steps: steps.map((step) => {
        return {
          name: step.name,
          type: step.type,
          group: step.group,
          editable: step.editable,
          copy: step.copy,
          inputs: step.inputs,
        };
      }),
      input_pages,
      notes,
    });
    return _toApplicationConfig(res.data.result[0]);
  }
);

export const releaseApplicationConfig = createAsyncThunk(
  SLICE_NAME + "/releaseApplicationConfig",
  async ({ id }: { id: string }) => {
    const res = await call("put", "template_manager/application_config")({ id, request_type: "release" });
    return _toApplicationConfig(res.data.result[0]);
  }
);

export const createApplicationConfigVersion = createAsyncThunk(
  SLICE_NAME + "/createApplicationConfigVersion",
  async ({ application_type }: { application_type: string }) => {
    const res = await call(
      "post",
      "template_manager/application_config"
    )({ request_type: "version", application_type });
    return _toApplicationConfig(res.data.result[0]);
  }
);

export const copyApplicationConfig = createAsyncThunk(
  SLICE_NAME + "/copyApplicationConfig",
  async ({ application_type }: { application_type: string }) => {
    await call("post", "template_manager/application_config")({ request_type: "copy", application_type });
  }
);

export const deleteApplicationConfig = createAsyncThunk(
  SLICE_NAME + "/deleteApplicationConfig",
  async ({ id }: { id: string }) => {
    await call("delete", "template_manager/application_config")({ id });
    return;
  }
);

// アカウント情報を取得する
export const getRelatedAccounts = createAsyncThunk(
  SLICE_NAME + "/getRelatedAccounts",
  async ({ id__in }: { id__in: number[] }) => {
    const accountRes = await call(
      "get",
      "account_manager/account_view"
    )({
      account_id__in: id__in,
    });
    const relatedAccounts = accountRes.data.result.map((account: any) => {
      return {
        id: account["account_id"],
        label: `${account["name"]}（${account["login_code"]}）`,
      };
    });

    return relatedAccounts;
  }
);

// 現在の部署・役職情報を取得する
export const getAssignments = createAsyncThunk(
  SLICE_NAME + "/getAssignments",
  async ({ account_id__in }: { account_id__in: number[] }) => {
    const res = await call(
      "get",
      "user_data_manager/assignment2"
    )({
      account_id__in,
      base_date: dayjs().format("YYYY-MM-DD"),
    });
    return res.data.result.map((r: any) => ({
      accountId: r.account_id,
      sectionName: r.section_name,
      positionName: r.position_name,
      isConcurrent: r.is_concurrent,
    }));
  }
);

// ゲストアカウントを除外してアカウントを検索する
export const searchCompanyMembers = createAsyncThunk(
  SLICE_NAME + "/searchCompanyMembers",
  async ({
    keyword,
    account_id,
    current_company_code,
    limit,
    page,
  }: {
    keyword?: string;
    account_id?: number;
    current_company_code: string;
    limit?: number;
    page?: number;
  }) => {
    const params = {
      ...(keyword ? { or: [{ name__contain: keyword, login_code__contain: keyword }] } : {}),
      account_id,
      main_company_code: current_company_code,
      limit,
      page,
    };

    const res = await call("get", "account_manager/account_view")(params);
    const filteredAccounts = res.data.result.map((account: any) => {
      return {
        id: account["account_id"],
        label: `${account["name"]}（${account["login_code"]}）`,
        name: account["name"],
        isActive: !!account["is_active"],
      };
    });
    return filteredAccounts;
  }
);

export const getApplicationStatus = createAsyncThunk(SLICE_NAME + "/getApplicationStatus", async () => {
  const res = await call("get", "template_manager/status")();
  return {
    id: res.data.result[0].id ?? null,
    applicationStatus: res.data.result[0],
  };
});

export const commitApplicationStatus = createAsyncThunk(
  SLICE_NAME + "/commitApplicationStatus",
  async (applicationStatus: ApplicationStatus) => {
    const id = store.getState().apply.applicationStatusId;
    const res = await call(
      id ? "put" : "post",
      "template_manager/status"
    )(
      id
        ? {
            id,
            ...applicationStatus,
          }
        : applicationStatus
    );
    return {
      id: res.data.result[0].id,
      applicationStatus: res.data.result[0],
    };
  }
);

// 添付ファイルの取得
export const getAttachedFiles = createAsyncThunk(
  SLICE_NAME + "/getAttachedFiles",
  async ({ id__in }: { id__in: string[] }) => {
    const res = await call("get", "file_manager/attach")({ id__in });
    return res.data.result.map((result: { [key: string]: any }) => {
      return {
        id: result.id,
        owner: result.owner,
        files: result.files as ProfileFileElement[],
      } as ProfileFile;
    });
  }
);

// ファイル添付
export const attachFile = createAsyncThunk(
  SLICE_NAME + "/attachFile",
  async ({
    applicationId,
    applicationUpdatedAt,
    subField,
    decodedFileData,
  }: {
    applicationId: string;
    applicationUpdatedAt: number;
    subField: ProfileSubField;
    decodedFileData: DecodedFileData;
  }) => {
    if (!applicationId) return;
    // body作成
    const body = (() => {
      let body = {
        name: decodedFileData.name,
        file: decodedFileData.dataURI,
      } as { [key: string]: any };
      if (subField.value) {
        body = { ...body, id: subField.value };
      }
      return body;
    })();
    // 添付ファイルの登録
    const fileRes = await call("post", "file_manager/attach")(body);
    if (!fileRes.data.result) {
      throw new Error("file attachments failed");
    }
    const fileRecord: ProfileFile = fileRes.data.result[0];
    if (subField.value) return { file: fileRecord };
    // 申請書レコードを更新する
    const inputs = { [subField.id]: fileRecord.id };
    const appRes = await call(
      "put",
      "application_manager/application"
    )({ id: applicationId, updated_at: applicationUpdatedAt, inputs, request_type: "update" });
    return {
      file: fileRecord,
      application: toForm(appRes.data.result[0]),
    };
  }
);

// ファイル削除
export const deleteFile = createAsyncThunk(
  SLICE_NAME + "/deleteFile",
  async ({ fileId, key }: { fileId: string; key: string }) => {
    if (!fileId || !key) return;
    const res = await call("delete", "file_manager/attach")({ id: fileId, key });
    if (!res.data.result) {
      throw new Error("file delete failed");
    }
    const fileRecord: ProfileFile = res.data.result[0];
    return fileRecord;
  }
);

// データを取得する
export const getComplementData = createAsyncThunk(
  SLICE_NAME + "/getComplementData",
  async ({ from, mapping }: { from: string; mapping: { [key: string]: string } }) => {
    const res = await call("get", "application_manager/proxy")({ from, mapping });
    return res.data.result[0];
  }
);

// 申請書のメール通知
export const notifyApplication = createAsyncThunk(
  SLICE_NAME + "/notifyApplication",
  async ({ id__in }: { id__in: string[] }) => {
    await call("post", `application_admin_manager/application_notify`)({ id__in });
  }
);

export const getNotificationTarget = createAsyncThunk(SLICE_NAME + "/getNotificationTarget", async () => {
  const res = await call("get", "template_manager/application_notification_target")();
  return {
    id: res.data.result[0].id ?? null,
    notificationTarget: res.data.result[0] ?? {},
  };
});
export const commitNotificationTarget = createAsyncThunk(
  SLICE_NAME + "/commitNotificationTarget",
  async (notoficationTarget: NotificationTarget) => {
    const id = store.getState().apply.notificationTargetId;
    const res = await call(
      id ? "put" : "post",
      "template_manager/application_notification_target"
    )(id ? { id, ...notoficationTarget } : notoficationTarget);
    return {
      id: res.data.result[0].id ?? null,
      notificationTarget: res.data.result[0],
    };
  }
);

export const getApplicantConfig = createAsyncThunk(SLICE_NAME + "/getApplicantConfig", async () => {
  const res = await call("get", "template_manager/applicant_config")();
  return {
    id: res.data.result[0].id ?? null,
    applicantConfig: res.data.result[0] ?? {},
  };
});

export const commitApplicantConfig = createAsyncThunk(
  SLICE_NAME + "/commitApplicantConfig",
  async (config: { [key: string]: any }) => {
    const id = store.getState().apply.applicantConfigId;
    const res = await call(id ? "put" : "post", "template_manager/applicant_config")(id ? { id, ...config } : config);
    return {
      id: res.data.result[0].id ?? null,
      applicantConfig: res.data.result[0],
    };
  }
);

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    unselectFormTemplate: (state) => {
      state.selectedTemplate = { ...emptyTemplate };
    },
    unselectPreviewTemplate: (state) => {
      state.previewTemplate = { ...emptyTemplate };
    },
    unselectRunningForm: (state) => {
      state.selectedRunningForm = { ...emptyRunningForm };
    },
    unselectApplicationConfig: (state) => {
      state.latestApplicationConfig = { ...emptyApplicationConfig };
    },
    unselectEditingApplicationConfig: (state) => {
      state.editingApplicationConfig = { ...emptyApplicationConfig };
    },
    unselectRunningForms: (state) => {
      state.runningForms = [];
    },
    clearFilteredAccounts: (state) => {
      state.filteredAccounts = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplyTemplateSummaries.fulfilled, (state, action) => {
      state.templateSummaries = action.payload;
      state.initialized = true;
    });
    builder.addCase(getLatestApplicationConfig.fulfilled, (state, action) => {
      state.latestApplicationConfig = action.payload;
    });
    builder.addCase(getEditingApplicationConfig.fulfilled, (state, action) => {
      state.editingApplicationConfig = action.payload;
    });
    builder.addCase(putApplicationConfig.fulfilled, (state, action) => {
      state.editingApplicationConfig = action.payload;
    });
    builder.addCase(releaseApplicationConfig.fulfilled, (state, action) => {
      state.latestApplicationConfig = action.payload;
      state.editingApplicationConfig = { ...emptyApplicationConfig };
    });
    builder.addCase(createApplicationConfigVersion.fulfilled, (state, action) => {
      state.editingApplicationConfig = action.payload;
    });
    builder.addCase(deleteApplicationConfig.fulfilled, (state, _) => {
      state.editingApplicationConfig = { ...emptyApplicationConfig };
    });
    builder.addCase(getApplyForms.fulfilled, (state, action) => {
      state.runningFormsTotalCount = action.payload.runningFormsTotalCount;
      state.runningFormsHasMore = action.payload.runningFormsHasMore;
      state.runningForms =
        action.payload.fetchedRunningFormsPage === 1
          ? action.payload.runningForms
          : [...state.runningForms, ...action.payload.runningForms];
      state.fetchedRunningFormsPage = action.payload.fetchedRunningFormsPage;
    });
    builder.addCase(getRunningForm.fulfilled, (state, action) => {
      state.selectedRunningForm = action.payload;
    });
    builder.addCase(postRunningForm.fulfilled, (state, action) => {
      state.selectedRunningForm = action.payload;
    });
    builder.addCase(putRunningForm.fulfilled, (state, action) => {
      state.selectedRunningForm = action.payload;
    });
    builder.addCase(getTodoFormCounts.fulfilled, (state, action) => {
      state.reviewingFormsLength = action.payload.reviewingFormsLength;
      state.rejectedFormsLength = action.payload.rejectedFormsLength;
      state.rejectedRepresentativeFormsLength = action.payload.rejectedRepresentativeFormsLength;
    });
    builder.addCase(getRequiredTemplates.fulfilled, (state, action) => {
      state.requiredTemplateSummaries = action.payload;
    });
    builder.addCase(getApplyTemplate.fulfilled, (state, action) => {
      if (action.payload) state.selectedTemplate = action.payload;
    });
    builder.addCase(getPreviewTemplate.fulfilled, (state, action) => {
      if (action.payload) state.previewTemplate = action.payload;
    });
    builder.addCase(getApplicationHistory.fulfilled, (state, action) => {
      state.applicationHistory = action.payload;
    });
    builder.addCase(getRelatedAccounts.fulfilled, (state, action) => {
      state.relatedAccounts = [...state.relatedAccounts, ...action.payload];
    });
    builder.addCase(getAssignments.fulfilled, (state, action) => {
      state.assignments = action.payload;
    });
    builder.addCase(searchCompanyMembers.fulfilled, (state, action) => {
      state.filteredAccounts = action.payload;
    });
    builder.addCase(getApplicationStatus.fulfilled, (state, action) => {
      state.applicationStatusId = action.payload.id ?? null;
      state.applicationStatus = action.payload.applicationStatus;
    });
    builder.addCase(commitApplicationStatus.fulfilled, (state, action) => {
      state.applicationStatusId = action.payload.id ?? null;
      state.applicationStatus = action.payload.applicationStatus;
    });
    builder.addCase(getAttachedFiles.fulfilled, (state, action) => {
      state.attachedFiles = action.payload;
    });
    builder.addCase(attachFile.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.attachedFiles = state.attachedFiles.map((file) => {
        if (file.id === action.payload?.file.id) return { ...file, files: action.payload.file.files };
        return file;
      });
      state.selectedRunningForm = action.payload.application ?? state.selectedRunningForm;
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.attachedFiles = state.attachedFiles.map((file) => {
        if (file.id === action.payload?.id) return { ...file, files: action.payload.files };
        return file;
      });
    });
    builder.addCase(getNotificationTarget.fulfilled, (state, action) => {
      state.notificationTargetId = action.payload.id ?? null;
      state.notificationTarget = action.payload.notificationTarget;
    });
    builder.addCase(commitNotificationTarget.fulfilled, (state, action) => {
      state.notificationTargetId = action.payload.id ?? null;
      state.notificationTarget = action.payload.notificationTarget;
    });
    builder.addCase(getApplicantConfig.fulfilled, (state, action) => {
      state.applicantConfigId = action.payload.id ?? null;
      state.applicantConfig = action.payload.applicantConfig;
    });
    builder.addCase(commitApplicantConfig.fulfilled, (state, action) => {
      state.applicantConfigId = action.payload.id ?? null;
      state.applicantConfig = action.payload.applicantConfig;
    });
  },
});

export const {
  unselectFormTemplate,
  unselectPreviewTemplate,
  unselectRunningForm,
  unselectApplicationConfig,
  unselectEditingApplicationConfig,
  unselectRunningForms,
  clearFilteredAccounts,
} = slice.actions;

export const selectApplyState = (state: RootState) => {
  return state.apply as ApplyState;
};

/**
 * 申請者と承認者の入力の分割要素を取得
 */
const getSplitter = (processorInputs: ApplyFormRow[], pageIndex: number) => {
  // 承認者の入力項目がない場合は表示しない
  const displayProcessorInputs = processorInputs.filter(({ display }) => display !== false);
  if (displayProcessorInputs.length === 0) return [];
  // 全ての入力項目に条件が設定されているか判定（初期状態で全て非表示である）
  const isAllProcessorInputsHasConditions = displayProcessorInputs.every(
    ({ required_conditions, display_conditions }) => {
      const hasRequiredConditions = required_conditions && Object.keys(required_conditions).length > 0;
      const hasDisplayConditions = display_conditions && Object.keys(display_conditions).length > 0;
      return hasRequiredConditions || hasDisplayConditions;
    }
  );
  const conditions = (() => {
    if (!isAllProcessorInputsHasConditions) return {};
    const allConditions = [
      ...displayProcessorInputs.map(({ required_conditions }) => required_conditions),
      ...displayProcessorInputs.map(({ display_conditions }) => display_conditions),
    ].filter((conditions) => conditions && Object.keys(conditions).length > 0) as ProfileCondition[];
    // 条件を全てorでつなぐ
    const dedupedConditions = allConditions.reduce((prev: ProfileCondition[], current: ProfileCondition) => {
      if (prev.some((p) => JSON.stringify(p) === JSON.stringify(current))) return prev;
      return [...prev, current];
    }, [] as ProfileCondition[]);
    return { or: dedupedConditions };
  })();
  return [
    {
      type: "staticText",
      value: "以下は承認の過程で入力されます。",
      pageIndex,
      display_conditions: conditions,
    },
    { type: "staticLine", pageIndex, display_conditions: conditions },
  ];
};

export const selectTemplateAsProfileField = (
  state: RootState
): {
  originalProfileField: ProfileField;
  validateProfileField: FieldValidator;
} => {
  const existPreviewTemplate = state.apply.previewTemplate.id;
  const template = existPreviewTemplate ? state.apply.previewTemplate : state.apply.selectedTemplate;
  if (!template.id)
    return {
      originalProfileField: {
        fieldName: "",
        table: "",
        category: "",
        subFields: [],
        labelMap: {},
        account: { id: -1 },
      },
      validateProfileField: (subFields) => {
        return {
          validated: true,
          subFields,
        };
      },
    };
  const applyForm = state.apply.selectedRunningForm as RunningApplyForm;
  const inputs = (() => {
    // 作成時でプレビューではない場合はinputs_on_createを使う
    if (!applyForm.id && !existPreviewTemplate) return template.inputs_on_create;
    // 作成後はinput_pages を使用してProfileFieldを作成するが、
    // 参照のため、inputs_on_create も表示せず保持しておく
    const _inputs = template.input_pages
      .map((page: ApplyInputPage, pageIndex: number) => {
        if (page.display === false) return [];
        const inputs = page.inputs ?? [];
        const processorInputs = page.processor_inputs ?? [];
        const splitter = getSplitter(processorInputs, pageIndex);
        return [...inputs, ...splitter, ...processorInputs];
      })
      .flat();
    const _inputsOnCreate = template.inputs_on_create.map((input: ApplyFormRow) => ({ ...input, pageIndex: -1 }));
    return [..._inputs, ..._inputsOnCreate];
  })();
  let labelMap = {};
  const _subFields = inputs.map((input: ApplyFormRow, index: number): ProfileSubField => {
    const { key, value, pageIndex } = input;
    const type = typeMap[input.key] || input.type;
    const targets = input.targets?.map((t) => ({ column: key, ...t })) ?? [];
    let { table, column } = targets[0] ?? {};
    table = table?.split("::")?.[0];
    const _key = key ?? `inserted-sub-field--${index}`;
    labelMap = { ...labelMap, [_key]: input.label || _key };
    return {
      id: _key,
      type,
      value,
      editable: !!key && input.reference_type === "",
      errorMessage: "",
      rules: input.rules ?? [],
      correlationRules: input.correlation_rules ?? [],
      labelValueOptions:
        input.options?.map(({ label, value }) => ({ label, value: value ?? label })) ??
        (() => {
          if (type === "options") {
            return (
              userColumnChoices[table][column ?? input.key]?.map((label) => ({
                label,
                value: label,
              })) ?? []
            );
          }
        })() ??
        [],
      entered: false,
      required: !!input.required,
      requiredConditions: input.required_conditions ?? {},
      display: input.display,
      displayConditions: input.display_conditions ?? {},
      keepValue: !!input.keep_value,
      tag: "",
      tagGroupsToUse: [],
      tagGroupIndex: 0,
      minTagGroupsLength: 0,
      maxTagGroupsLength: 0,
      termsKey: table && column ? `${table}__${column}` : `${template.id}__${key}`,
      pageIndex,
      events: input.events ?? [],
      referenceField: !!input.reference_type,
      record: {
        id: 0,
        created_at: "",
        updated_at: "",
        updated_by: null,
        valid_from: "2020-01-01",
        valid_to: "9999-12-31",
      },
    };
  });
  const subFieldsData = _subFields
    .filter((subField: ProfileSubField) => subField.pageIndex !== -1 || existPreviewTemplate)
    .reduce((prev: { [fieldName: string]: any }, current: ProfileSubField) => {
      if (!current.editable) return prev;
      return {
        ...prev,
        [current.id]: {
          type: current.type,
        },
      };
    }, {});

  return {
    originalProfileField: {
      fieldName: template.id,
      table: template.id,
      category: template.id,
      subFields: _subFields,
      labelMap: { ja: labelMap },
      account: { id: -1 },
    },
    validateProfileField: generateValidator(subFieldsData),
  };
};

const Module = {
  name: SLICE_NAME,
  reducer: slice.reducer,
};
export default Module;
