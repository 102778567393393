import { Fragment, useMemo, useEffect } from "react";
import { Row, Col, Dropdown, DropdownButton, Card, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/style.scss";
import {
  roleTargets,
  roleTargets7Default,
  operations,
  editMethods,
  uneditablePolicies,
  RoleApiBehavior,
  CRUDMethod,
  undisplayPolicies,
} from "./permissionValues";
import { useAppSelector, useAppDispatch } from "../../app/store";
import {
  selectActiveSectors,
  selectMasterActiveSectors,
  selectClientState,
  getProfileOptions,
} from "../client/clientSlice";
import { USER_TABLE_PREFIX } from "../../features/profile/profileValues";
import { MASTER_TABLE_PREFIX } from "../masterData/masterDataValues";

function App({
  roleApiBehaviors,
  onChange,
  editable = true,
}: {
  roleApiBehaviors: RoleApiBehavior[];
  onChange: (next: RoleApiBehavior[]) => any;
  editable?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { profileOptions } = useAppSelector(selectClientState);
  const activeSectors = useAppSelector(selectActiveSectors);
  const masterActiveSectors = useAppSelector(selectMasterActiveSectors);
  const userActiveSectorIds = useMemo(() => {
    if (!roleApiBehaviors || !Array.isArray(roleApiBehaviors)) return [];
    return activeSectors.map((sector) => sector.sector_id);
  }, [activeSectors]);
  const masterActiveSectorIds = useMemo(() => {
    return masterActiveSectors.map((sector) => sector.sector_id);
  }, [masterActiveSectors]);

  // 非アクティブのテーブルをフィルタリング
  const filteredRoleApiBehaviors = useMemo(() => {
    const result = roleApiBehaviors.map((content) => {
      const filteredConditions =
        content.id === "master_data_manager"
          ? content.conditions.filter((condition) =>
              masterActiveSectorIds.includes(`${MASTER_TABLE_PREFIX}${condition.resource.id}`)
            )
          : content.id === "user_data_manager"
          ? content.conditions.filter((condition) =>
              userActiveSectorIds.includes(`${USER_TABLE_PREFIX}${condition.resource.id}`)
            )
          : content.conditions;
      return {
        id: content.id,
        label: content.label,
        conditions: filteredConditions,
      };
    });
    return result;
  }, [roleApiBehaviors, masterActiveSectorIds, userActiveSectorIds]);

  const roleTargetOptions = useMemo(() => {
    const roleTargetOptions = [
      ...roleTargets.map((target) => ({
        label: target.label,
        value: String(target.value),
      })),
    ];
    let accessible_groups = roleTargets7Default;
    if (profileOptions && profileOptions["accessible_group_type"]) {
      accessible_groups = profileOptions["accessible_group_type"].filter((g) => !g.hidden).map((g) => g.label);
    }
    accessible_groups.forEach((g) =>
      roleTargetOptions.push({
        label: g,
        value: `7@@${g}`,
      })
    );
    return roleTargetOptions;
  }, [profileOptions]);
  const roleTargetValues = useMemo(() => {
    return roleTargetOptions.map((target) => target.value);
  }, [roleTargetOptions]);

  useEffect(() => {
    dispatch(getProfileOptions({ sector: "profile_u_accessible_section" }));
  }, []);

  return (
    <Row>
      {filteredRoleApiBehaviors.map((apiBehavior, i) => {
        // ラベルが空の場合=permissionValues の apiSummaries に定義がなければ表示しない
        if (apiBehavior.label === "") return null;
        return (
          <Col key={apiBehavior.id} md={12}>
            <Card className="mb-2">
              <Card.Header>{apiBehavior.label}</Card.Header>
              <Card.Body>
                {apiBehavior.conditions.every((c) => c.resource.id === "all") && (
                  <Row className="mb-2">
                    <Col xs={3}>範囲</Col>
                    <Col xs={6}>可能な操作</Col>
                  </Row>
                )}
                {!apiBehavior.conditions.every((c) => c.resource.id === "all") && (
                  <Fragment>
                    <Row className="mb-2">
                      <Col xs={3}>範囲</Col>
                      <Col xs={6}>可能な操作</Col>
                    </Row>
                    <Row className="mb-2 --flex --align-items-center">
                      <Col xs={3}>
                        <DropdownButton
                          variant="outline-primary"
                          disabled={!editable}
                          title={(() => {
                            let title = "項目ごとに設定";
                            roleTargetOptions.forEach(({ label, value }) => {
                              if (apiBehavior.conditions.every((c) => String(c.target) === value)) title = label;
                            });
                            return title;
                          })()}
                        >
                          {roleTargetOptions.map((target) => {
                            return (
                              <Dropdown.Item
                                key={`target_${target.value}_${i}`}
                                onClick={() => {
                                  const next = filteredRoleApiBehaviors.map((apiBehavior, _roleIndex) => {
                                    if (_roleIndex !== i) return apiBehavior;
                                    return {
                                      ...apiBehavior,
                                      conditions: apiBehavior.conditions.map((_condition) => {
                                        return {
                                          ..._condition,
                                          target: String(target.value),
                                        };
                                      }),
                                    };
                                  });
                                  onChange && onChange(next);
                                }}
                              >
                                {target.label}
                              </Dropdown.Item>
                            );
                          })}
                        </DropdownButton>
                        {apiBehavior.conditions.every((c) => {
                          return !roleTargetValues.includes(String(c.target));
                        }) && <div className="--font-s --text-annotation mt-1">一致する選択肢がありません。</div>}
                      </Col>
                      <Col xs={6}>
                        {operations.map((method) => {
                          const checked =
                            method.value === "GET"
                              ? apiBehavior.conditions.every((c) => c.methods.includes(method.value as CRUDMethod))
                              : apiBehavior.conditions.every((c) =>
                                  c.methods.some((_method) => editMethods.includes(_method))
                                );
                          return (
                            <Form.Check
                              checked={checked}
                              type="checkbox"
                              inline
                              disabled={!editable}
                              id={`method_all_${i}__${method.value}`}
                              label={method.label}
                              key={`method_all_${i}__${method.value}`}
                              onChange={() => {
                                const next = filteredRoleApiBehaviors.map((apiBehavior, _roleIndex) => {
                                  if (_roleIndex !== i) return apiBehavior;
                                  return {
                                    ...apiBehavior,
                                    conditions: apiBehavior.conditions.map((_condition) => {
                                      return {
                                        ..._condition,
                                        methods: (() => {
                                          const methodsToCheck = method.value === "GET" ? [method.value] : editMethods;
                                          const filteredMethods = _condition.methods.filter(
                                            (_method) => !methodsToCheck.includes(_method)
                                          );
                                          return checked ? filteredMethods : [...filteredMethods, ...methodsToCheck];
                                        })(),
                                      };
                                    }),
                                  };
                                });
                                onChange && onChange(next);
                              }}
                            />
                          );
                        })}
                      </Col>
                    </Row>
                    <Row className="mb-4 --font-s">
                      <Col>※ 変更すると「詳細設定」の内容は上書きされます。</Col>
                    </Row>
                    <Row className="mb-2">
                      <h3 className="Headline--section-sub">詳細設定</h3>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={3}>項目</Col>
                      <Col xs={3}>範囲</Col>
                      <Col xs={6}>可能な操作</Col>
                    </Row>
                  </Fragment>
                )}
                {apiBehavior.conditions.map((condition, _i) => {
                  return (
                    <Row key={`condition_${i}_${_i}`} className="--flex --align-items-center">
                      {condition.resource.id !== "all" && (
                        <Col md={3} className="my-1">
                          {condition.resource.label}
                        </Col>
                      )}
                      <Col md={3} className="my-1">
                        <DropdownButton
                          variant="outline-primary"
                          disabled={
                            !editable ||
                            uneditablePolicies.find((_) => _.id === apiBehavior.id)?.methods.length ===
                              operations.length
                          }
                          title={roleTargetOptions.find((_) => _.value === String(condition.target))?.label ?? "- - -"}
                        >
                          {roleTargetOptions.map((target) => {
                            return (
                              <Dropdown.Item
                                key={`target_${target.value}_${i}`}
                                onClick={() => {
                                  const nextCondition = { ...condition, target: String(target.value) };
                                  const next = filteredRoleApiBehaviors.map((apiBehavior, _roleIndex) => {
                                    if (_roleIndex !== i) return apiBehavior;
                                    return {
                                      ...apiBehavior,
                                      conditions: apiBehavior.conditions.map((_condition, _conditionIndex) => {
                                        if (_conditionIndex !== _i) return _condition;
                                        else return nextCondition;
                                      }),
                                    };
                                  });
                                  onChange && onChange(next);
                                }}
                              >
                                {target.label}
                              </Dropdown.Item>
                            );
                          })}
                        </DropdownButton>
                        {apiBehavior.conditions.every((c) => {
                          return !roleTargetValues.includes(String(c.target));
                        }) && <div className="--font-s --text-annotation mt-1">一致する選択肢がありません。</div>}
                      </Col>
                      <Col md={6} className="my-1">
                        {operations.map((method) => {
                          // undisplayPolicies に定義されている場合は表示しない
                          const undisplayMethods =
                            undisplayPolicies.find((_) => _.id === apiBehavior.id)?.methods ?? [];
                          if (undisplayMethods.includes(method.value)) return null;
                          // view と summary は "編集" を表示しない
                          if (method.value === "EDIT" && (condition.resource.isView || condition.resource.isSummary))
                            return null;
                          return (
                            <Form.Check
                              checked={
                                method.value === "GET"
                                  ? condition.methods.includes(method.value as CRUDMethod)
                                  : condition.methods.some((_method) => editMethods.includes(_method))
                              }
                              type="checkbox"
                              inline
                              disabled={
                                !editable ||
                                uneditablePolicies.find((_) => _.id === apiBehavior.id)?.methods.includes(method.value)
                              }
                              id={`method_${i}_${_i}_${method.value}`}
                              label={method.label}
                              key={`method_${i}_${_i}_${method.value}`}
                              onChange={() => {
                                const nextCondition = {
                                  ...condition,
                                  methods: (() => {
                                    const methodsToCheck =
                                      method.value === "GET" ? [method.value as CRUDMethod] : editMethods;
                                    const methodExists = condition.methods.some((_method) =>
                                      methodsToCheck.includes(_method)
                                    );
                                    return methodExists
                                      ? condition.methods.filter((_method) => !methodsToCheck.includes(_method))
                                      : [...condition.methods, ...methodsToCheck];
                                  })(),
                                };
                                const next = filteredRoleApiBehaviors.map((apiBehavior, _roleIndex) => {
                                  if (_roleIndex !== i) return apiBehavior;
                                  return {
                                    ...apiBehavior,
                                    conditions: apiBehavior.conditions.map((_condition, _conditionIndex) => {
                                      if (_conditionIndex !== _i) return _condition;
                                      else return nextCondition;
                                    }),
                                  };
                                });
                                onChange && onChange(next);
                              }}
                            />
                          );
                        })}
                      </Col>
                    </Row>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default App;
