import { useNavigate, Link } from "react-router-dom";
import classNames from "classnames";
import { store, useAppDispatch, useAppSelector } from "../app/store";
import "../css/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useMemo, MouseEventHandler } from "react";
import Icon from "./Icon";
import { selectUserState, selectUserRootRoles, PolicyMap, User } from "../features/login/userSlice";
import { selectLayoutState } from "../features/layout/layoutSlice";
import { handleFatalError } from "../features/error/fatalErrorSlice";
import { serviceLabels } from "../features/permission/permissionValues";

type ROW = {
  label: string;
  id: string;
  action?: MouseEventHandler;
  link: string;
  isExternalLink?: boolean;
  icon: {
    type: string;
    width: number;
    height: number;
  };
  isAvailable: (policies: PolicyMap, roles: string[], currentUser: User) => boolean;
};
type Props = {
  current: string;
};

const ROWS: ROW[] = [
  {
    label: "",
    id: "not_select", // 未選択状態にするためのダミー
    link: "",
    icon: { type: "", width: 0, height: 0 },
    isAvailable: () => true,
  },
  {
    label: "企業切替", // 権限チェックのために追加
    id: "switch_company",
    link: "/_/switch_company/",
    icon: {
      type: "",
      width: 28,
      height: 28,
    },
    isAvailable: () => true,
  },
  {
    label: "ダッシュボード",
    id: "dashboard",
    link: "/_/dashboard/",
    icon: {
      type: "card-checklist",
      width: 28,
      height: 28,
    },
    isAvailable: () => true,
  },
  {
    label: "申請書",
    id: "apply",
    link: "/_/apply/",
    icon: {
      type: "file-earmark-arrow-up",
      width: 32,
      height: 32,
    },
    isAvailable: () => true,
  },
  {
    label: "従業員情報（一覧）",
    id: "profiles",
    link: "/_/profiles/",
    icon: {
      type: "people-fill",
      width: 32,
      height: 32,
    },
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("user_data_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
  {
    label: "従業員情報（個別）",
    id: "profile",
    link: "/_/profile/",
    icon: {
      type: "person-fill",
      width: 28,
      height: 28,
    },
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("user_data_manager"))
        ?.some((api) => policies[api]?.includes("GET"));
    },
  },
  {
    label: "マイナンバー",
    id: "my_number",
    link: "/_/my_number/",
    icon: {
      type: "123",
      width: 28,
      height: 28,
    },
    isAvailable: (_, __, currentUser) => {
      // 10=マイナンバーのサービスid
      return currentUser.services.some((s) => s === 10);
    },
  },
  {
    label: "ファイル管理",
    id: "file",
    link: "/_/files/",
    icon: {
      type: "folder2",
      width: 24,
      height: 24,
    },
    isAvailable: (policies, _, __) => {
      return (
        policies.file_project_admin_manager?.some((_) => _ === "POST") ||
        policies.report_admin_manager?.some((_) => _ === "POST")
      );
    },
  },
  {
    label: "ファイル出力",
    id: "fileOutput",
    link: "/_/file/",
    icon: {
      type: "sticky",
      width: 28,
      height: 28,
    },
    isAvailable: (policies, _) => {
      const hasFileProjectManagerPermissions = (policies.file_project_manager ?? [])?.some((_: string) => _ === "GET");
      const hasReportManagerPermissions = (policies.report_manager ?? [])?.some((_: string) => _ === "GET");
      return hasFileProjectManagerPermissions || hasReportManagerPermissions;
    },
  },
  {
    label: "組織データ",
    id: "property",
    link: "/_/property/",
    icon: {
      type: "pie-chart",
      width: 24,
      height: 24,
    },
    isAvailable: (policies, _) => {
      return Object.keys(policies)
        .filter((key: string) => key.includes("master_data_manager"))
        ?.some((api) => policies[api]?.includes("POST"));
    },
  },

  {
    label: "データインポート",
    id: "import",
    link: "/_/import/",
    icon: {
      type: "upload",
      width: 28,
      height: 28,
    },
    isAvailable: (policies, _) => {
      return policies.data_loader?.some((_) => _ === "POST");
    },
  },
  {
    label: "アカウント",
    id: "account",
    link: "/_/account/",
    icon: {
      type: "person-lines-fill",
      width: 24,
      height: 24,
    },
    isAvailable: (policies, _, currentRole) => {
      return policies.account_admin_manager?.some((_) => _ === "POST");
    },
  },
  {
    label: "権限管理",
    id: "permission",
    link: "/_/permission/",
    icon: {
      type: "lock",
      width: 24,
      height: 24,
    },
    isAvailable: (policies, _) => {
      return policies.access_manager?.some((_) => _ === "POST");
    },
  },
  {
    label: "メール文面",
    id: "mail",
    link: "/_/mail/",
    icon: {
      type: "envelope-paper",
      width: 24,
      height: 24,
    },
    isAvailable: (policies, _, currentUser) => {
      return currentUser.role === "admin";
    },
  },
  {
    label: "メール送信設定",
    id: "mail_setting",
    link: "/_/mail_setting/",
    icon: {
      type: "envelope-check",
      width: 24,
      height: 24,
    },
    isAvailable: (policies, _, currentUser) => {
      return currentUser.role === "admin";
    },
  },
  {
    label: "システム設定",
    id: "masters",
    link: "/_/masters/",
    icon: {
      type: "list-stars",
      width: 28,
      height: 28,
    },
    isAvailable: (policies, _) => {
      return policies.template_manager?.some((_) => _ === "POST");
    },
  },
];

function App({ current }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sidebarStatus } = useAppSelector(selectLayoutState);
  const { policies, user } = useAppSelector(selectUserState);
  const { companyChoices, current_company } = user;
  const roles = useAppSelector(selectUserRootRoles);

  const stories = current.split("/").filter((_) => _);

  // action を設定
  const rows = useMemo(() => {
    const rows = ROWS.filter((row) => row.isAvailable(policies, roles, user));
    return rows;
  }, [roles, policies, dispatch, navigate]);

  const switchableComapanies = useMemo(() => {
    const _switchableComapanies = companyChoices
      .filter((c) => c.company_id !== current_company.id)
      .filter((c) => {
        return Object.values(c.services).some((services) => services.includes(serviceLabels["profile"].id));
      });
    return _switchableComapanies;
  }, [companyChoices]);

  useEffect(() => {
    if (Object.keys(policies).length > 0 && roles.length > 0 && !rows.some((row) => row.id === stories[0])) {
      store.dispatch(handleFatalError({ type: "error", message: "権限がありません。再ログインしてください。" }));
    }
  }, [rows]);

  return (
    <nav className="Sidebar">
      <div
        className={classNames({
          Sidebar__list: sidebarStatus === "open",
          "Sidebar__list--folded": sidebarStatus === "folded",
        })}
      >
        {switchableComapanies.length > 0
          ? rows
              .filter((r) => r.id === "switch_company")
              .map((row) => {
                return (
                  <Link to={row.link} className="Sidebar__company-switcher" key={row.id}>
                    <div className="--font-s">操作中の企業</div>
                    <div>{current_company.company_name}</div>
                  </Link>
                );
              })
          : null}
        {rows
          .filter((r) => r.id !== "switch_company")
          .filter((r) => r.id !== "not_select")
          .map((row) => {
            return (
              <div
                onClick={(e) => {
                  if (row.action) {
                    row.action(e);
                    return false;
                  }
                  navigate(row.link);
                }}
                key={row.id}
                className={classNames({
                  Sidebar__row: true,
                  "Sidebar__row--current": stories[0] === row.id,
                  "Sidebar__row--with-company-choices": companyChoices.length > 1,
                })}
              >
                {row.icon.type && (
                  <div className="Sidebar__row-icon">
                    <Icon type={row.icon.type} width={row.icon.width} height={row.icon.height} />
                  </div>
                )}
                {row.label}
                {row.isExternalLink === true ? (
                  <span className="--px-1">
                    <Icon type="external-tab" width={16} height={16} />
                  </span>
                ) : null}
              </div>
            );
          })}
      </div>
    </nav>
  );
}

export default App;
